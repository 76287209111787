import { Raleway_400Regular, Raleway_600SemiBold } from '@expo-google-fonts/raleway'
import {StyleSheet} from 'react-native'

export const qrStyle = StyleSheet.create({
    qrContainer:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "10%",
        // width: "414px",
        width:" 27.30vw",
        height: "21.90vh",
        backgroundColor: "#FFFFFF",
        borderRadius: "16px",
    },


    googlePlayImg:{
        width:"12.29vw",
        height: "6.397vh",
        top: "0.985vh",
        background: "#000000",
        borderRadius: "1.875vw",
        resizeMode:"contain",
    },

    qrTopText:{
        width: "18.594vw",
        // width:"17.42vw",
        height: "4.240vh",
        left: "1.875vw",
        textAlign:"left",
        // top: "30px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "1.093vw",
        lineHeight: "3.120vh",
        color: "#2C2E2F",
    },

    qrImage:{
        width:"100px",
        height:"100px",
        background: "#FFFFFF",
    },
    
})
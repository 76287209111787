import * as React from 'react'

import {
    View,
    Text,
    Image,
    ScrollView,
    TouchableOpacity,
    Dimensions,
    Button,
    ImageBackground,
    Linking,
    SafeAreaView, 
} from 'react-native'
import { useState,useRef } from 'react'
import { proudSellerStyle } from './proudSellerStyle'
import { useMediaQuery } from 'react-responsive';
//import {segmentStyle} from './segmentStyle'
import CustomButton from '../../commonComponents/SubmitButton';

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

let backwardArrow = require("../../../assets/common_assets/back_arrow.png")
let forwardArrow = require("../../../assets/common_assets/forward_arrow.png")
let doublequoteWhite = require("../../../assets/common_assets/double_quote.png")
let doublequote = require("../../../assets/common_assets/double_quote_green.png")

const sellerPlayStore = "https://play.google.com/store/apps/details?id=com.ishopatoz.android.vendor.release"

import { cardImages } from './data';

const deliveryPartner = () => {

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const scrollViewRef = useRef(null);
    const width = Dimensions.get("window")
    
    const [cardState,setCardState] = useState({currentCard:0})

    const {currentCard:pageIndex} = cardState

    const toNextView = () =>{
            scrollViewRef.current.scrollTo({
                // screenX:width*(pageIndex-4)*-1,
                x:1600,
                animated:true,
            })
    }

    const toBackView = () => {
        if(scrollViewRef.current !== null){
            scrollViewRef.current.scrollTo({
                x:width*(pageIndex+1),
                animated:true,
            })
        }
    }

    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:450
    })


    if(isMobileDisplay){
        return(
            <View style={proudSellerStyle.proudContainerMobile}>
                <Text style={proudSellerStyle.partnerTextMobile}>Lets hear from our{"\n"}proud sellers</Text>
                
                <View style={proudSellerStyle.cardContainerMobile}>
                    <ScrollView
                            horizontal={true}
                            showsHorizontalScrollIndicator={false}
                            ref={scrollViewRef}
                    >
                        {cardImages.map(card=>{
                            return<View style={proudSellerStyle.cardViewMobile}> 
                                <View style={proudSellerStyle.cardViewContainerMobile}>
                                    <TouchableOpacity onPress={()=>Linking.openURL(sellerPlayStore)}>
                                        <Image
                                            source={card.img}
                                            style={proudSellerStyle.cardImageMobile}
                                        />
                                    </TouchableOpacity>
                                </View>
                                <View style={proudSellerStyle.cardAllTextContainerMobile}>
                                    <Image
                                        source={doublequoteWhite}
                                        style={proudSellerStyle.doublequoteMobile}
                                    />
                                    {/* <TouchableOpacity onPress={()=>Linking.openURL(sellerPlayStore)}> */}
                                    <View style={proudSellerStyle.cardTextContainerMobile}>
                                        {/*
                                        <Text nativeID='serviceableCities' style={proudSellerStyle.cardTextMobile}>{card.text}</Text>
                                        */}  
                                        <SafeAreaView style={proudSellerStyle.safeAreaContainerMobile}>
                                            <ScrollView style={proudSellerStyle.scrollViewCardMobile}>
                                                <Text nativeID='serviceableCities' style={proudSellerStyle.cardTextMobile}>{card.text}</Text>
                                            </ScrollView>   
                                        </SafeAreaView>        
                                    </View>
                                    {/* </TouchableOpacity> */}
                                </View>
                                <View style={proudSellerStyle.cardTitleContainerMobile}>
                                    <Text style={proudSellerStyle.cardTitleMobile}>{card.name}</Text>
                                    <Text style={proudSellerStyle.cardTitleMobile}>{card.designation}</Text>
                                </View>
                            </View>
                            
                        })}
                        <TouchableOpacity style={proudSellerStyle.knowMore} onPress={()=>Linking.openURL(sellerPlayStore)}>
                            <Image style={proudSellerStyle.cardImageMobileKnowMore} source={require("../../../assets/common_assets/dottedCard.png")}/>
                        </TouchableOpacity>
                        {/* <TouchableOpacity style={proudSellerStyle.knowMore} onPress={()=>Linking.openURL(sellerPlayStore)}>
                            <Image style={proudSellerStyle.cardImageMobile} source={require("../../../assets/common_assets/dottedCard.png")}/>
                         </TouchableOpacity> */}
                    </ScrollView>
                </View>
                <View style={proudSellerStyle.buttonRootContainerMobile}>
                    <CustomButton
                                buttonLink="https://docs.google.com/forms/d/1z2XWXEtGXfDespruz-4jflWn9-2eQPkosRWFTHJadHo/edit?usp=sharing"
                                buttonText="Submit Feedback"
                                />
                </View>
            </View>
        )
    }

    return (
        <View style={proudSellerStyle.proudContainer}>
           <View style={proudSellerStyle.topContainer}>
                <Text style={proudSellerStyle.theSegmentText}>Lets hear from our proud sellers</Text>
                
                <View style={proudSellerStyle.navigatorContainer}>
                    <TouchableOpacity
                        onPress={()=>toBackView()}
                        style={proudSellerStyle.opacityElement}
                    >
                        <Image 
                            source={require("../../../assets/common_assets/backward.png")}
                            style={proudSellerStyle.greyArrowImage}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={()=>toNextView()}
                        style={proudSellerStyle.opacityElement}
                    >
                        <Image
                            source={require("../../../assets/common_assets/forward.png")}
                            onPress={() => source={backwardArrow}}
                            style={proudSellerStyle.redArrowImage}
                            // onProgress={()=>{this.scrollview.scrollToEnd({x:screenWidth})}}
                        />
                     </TouchableOpacity>
                </View>
           </View>
           <View style={proudSellerStyle.cardContainer}>
               <ScrollView
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    ref={scrollViewRef}
               >
                {cardImages.map(card=>{
                    return<View style={proudSellerStyle.cardView}> 
                            <View style={proudSellerStyle.cardViewContainer}>
                                <TouchableOpacity onPress={()=>Linking.openURL(sellerPlayStore)}>
                                    <Image
                                    source={card.img}
                                    style={proudSellerStyle.cardImage}
                                    />
                                </TouchableOpacity>
                            </View>
                            <View style={proudSellerStyle.cardAllTextContainer}>
                                <Image
                                    source={doublequoteWhite}
                                    style={proudSellerStyle.doublequote}
                                />
                                <TouchableOpacity onPress={()=>Linking.openURL(sellerPlayStore)}>
                                    <View style={proudSellerStyle.cardTextContainer}>
                                        {/*
                                        <Text nativeID='serviceableCities' style={proudSellerStyle.cardText}>{card.text}</Text>
                                        */}
                                        <SafeAreaView style={proudSellerStyle.safeAreaContainer}>
                                            <ScrollView style={proudSellerStyle.scrollViewCard}>
                                                <Text nativeID='serviceableCities' style={proudSellerStyle.cardText}>{card.text}</Text>
                                            </ScrollView>   
                                        </SafeAreaView>
                                    </View>
                                </TouchableOpacity>
                            </View> 
                            <View style={proudSellerStyle.cardTitleContainer}>
                                <Text style={proudSellerStyle.cardTitle}>{card.name}</Text>
                                <Text style={proudSellerStyle.designationText}>{card.designation}</Text>
                            </View>
                    </View>
                     
                })}
                    <TouchableOpacity style={proudSellerStyle.knowMore} onPress={()=>Linking.openURL(sellerPlayStore)}>
                        <Image style={proudSellerStyle.cardImageMore} source={require("../../../assets/common_assets/dottedCard.png")}/>
                    </TouchableOpacity>
                 </ScrollView>
           </View>
           <View nativeID='sellingAtoZ'></View>
           <View style={proudSellerStyle.buttonRootContainer}>
                <CustomButton
                            buttonLink="https://docs.google.com/forms/d/1z2XWXEtGXfDespruz-4jflWn9-2eQPkosRWFTHJadHo/edit?usp=sharing"
                            buttonText="Submit Feedback"
                            />
            </View>
        </View>
    )

}

export default deliveryPartner
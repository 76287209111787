import {StyleSheet} from 'react-native'
import {Raleway, Raleway_400Regular, Raleway_500Medium, Raleway_600SemiBold} from '@expo-google-fonts/raleway'


 export const journeyStyle = StyleSheet.create({
    journeyContainer:{
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        height:"91vh",
        backgroundColor: "linear-gradient(180deg, #BFE0E1 0.78%, #F2F9F9 83.98%, #FFFFFF 100%)",
        mixBlendMode: "normal",
        opacity: "0.8",
        width:"100%",
        padding:"10%",
    },
    topBoldText:{
        // width: "1184px",
        width:"86.67vw",
        height: "75px",
        // left: "304px",
        // top: "2064.97px"
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "64px",
        lineHeight: "75px",
        textAlign: "center",
        color: "#2C2E2F"

    },

    cardContainer:{
        display:"flex",
        flexDirection:"row",
        // maxWidth:"100%",
        // width:"100%",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        alignItems:"center",
        justifyContent:"center"
    },

    textContainer:{
        justifyContent:"center",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        width:"25vw",
        height:"40vh"
    },

    card:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width:"20.93vw",
        height:" 33.33vh",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        textAlign:"center",
        bottom:"0",
    },

    cardText:{
        position: "static",
        width: "238px",
        height: "76px",
        // left: 24px;
        // top: 24px;
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "19px",
        color: "#2C2E2F",
        /* Inside Auto Layout */
        flex: "none",
        order: 0,
        flexGrow: 0,
        margin: "0px 96px",
        textAlign:"left"
    },

    cardMiddleText: {
        position: "static",
        width: "238px",
        height: "76px",
        // left: 24px;
        // top: 24px;
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "19px",
        color: "#2C2E2F",
        /* Inside Auto Layout */
        flex: "none",
        order: 0,
        flexGrow: 0,
        margin: "0px 96px",
        textAlign:"left",
        marginTop:"-100px"
    },
    dashedLine:{
        // width: 1184px
        width:"60.67vw",
        height:"4vh",
        // border: "2px dashed #008489",
        resizeMode:"contain",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"10vh"
    },

    yearTextContainer:{
        // display: flex;
        // flex-direction: row;
        justifyContent: "center",
        alignItems: "center",
        padding: "8px",
        // position: absolute;
        width: "53px",
        height: "35px",
        // left: 870px
        // top: 2445px;
        backgroundColor: "#008489",
        borderRadius: "8px"
    },
    
    yearText:{
        width: "36px",
        height: "19px",
        fontFamily: 'Raleway_500Medium',
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "19px",
        /* identical to box height */
        color: "#FFFFFF",
        /* Inside Auto Layout */
        flex: "none",
        order: "0",
        flexGrow: "0",
        margin: "0px 0px",
    }

    
})
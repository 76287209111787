import {StyleSheet} from 'react-native'
import {Raleway, Raleway_400Regular, Raleway_600SemiBold} from '@expo-google-fonts/raleway'

export const playStyle = StyleSheet.create({
    container:{
        display: "flex",
        flexDirection: "colum",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px",
        // position: "absolute",
        width: "202.76px",
        height: "237px",
        marginLeft:"auto",
        marginRight:"auto",
        // top: "376px",
        // backgroundColor: "#F2F9F9",
        //backgroundColor:"#FFFFFF",
        backgroundColor:"#F5FAFA",
        borderRadius: "25px",
        marginLeft:"auto",
        // opacity:"0.6",
        marginRight:"auto",
        //marginTop:"60px"
        marginTop:"30px"
    },
    textELement:{
        width: "150px",
        height: "50px",
        // left: 1px;
        // top: 27px;

        /* Small */
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "180%",
        /* or 25px */
        textAlign: "center",
        /* Charcoal-Text */
        color: "#2C2E2F",
        //backgroundColor:"#CCCCCC"

    },
    playImage:{
        width: "168px",
        height: "42px",
        // left: "36px"
        top: "11px",
        background: "#000000",
        borderRadius: "24px",
        resizeMode:"contain"
    },
    qrImage:{
        width: "102.84px",
        height: "102.84px",
        marginTop: "20px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 0.803417px 0.803417px rgba(0, 0, 0, 0.25)",
        resizeMode:"contain"
    }
})
import * as React from 'react'
import {View, Text} from 'react-native'
import * as Linking from 'expo-linking'
import {useEffect} from 'react'
import {NavigationContainer} from '@react-navigation/native'
import {createNativeStackNavigator} from '@react-navigation/native-stack'

import SellerPage  from './pages/sellerPage'
import BuyerPage from './pages/buyerPage'
import DeliveryPage from './pages/deliveryPage'
import AboutUsPage from './pages/aboutUsPage'
import { deliveryLinks } from './components/commonComponents/BottomNav/data';
import { useActive } from 'react-native-web-hooks';


const Stack = createNativeStackNavigator();
const prefix = Linking.createURL('/');


const config = {
  screens:{
   buyer:"/shopping",
   seller:"/seller",
   delivery:"/delivery",
   aboutUs:"/aboutus"
  }
}


const linking = {
  prefixes:['/'],
  config,
}


function App() {

  useEffect(()=>{
    if(window.location.pathname==='/shopping'){
      window.location.pathname = '/shopping/'
    }else{
      
    }
  })

 
  return (
    <NavigationContainer linking={{linking}}> 
      <Stack.Navigator  screenOptions={{headerShown:false}}>
        <Stack.Screen name="shopping" component={BuyerPage}/>
        <Stack.Screen name="seller" component={SellerPage}/>
        <Stack.Screen name="delivery" component={DeliveryPage}/>
        <Stack.Screen name="aboutus" component={AboutUsPage}/>
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default App;
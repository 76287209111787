import * as React from 'react'
import {View,Image,Text} from 'react-native'
import {missionStyle} from './missionStyles'
import { useMediaQuery } from 'react-responsive';

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

const OurMission = () => {

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:450
    })

    if(isMobileDisplay){
        return(
            <View style={missionStyle.missionContainerMobile}>
                <View Style={missionStyle.textContainerMobile}>
                    <Text style={missionStyle.topBoldTextMobile}>Our Mission</Text>
                    <Text style={missionStyle.lightTextMobile}>
                        We want to empower people and onboard them to the Indian e-commerce ecosystem. People from all over India can join our 
                        platform. We make sure that the technological advancements won't remain limited only to the tier 1 cities, instead people from 
                        even smaller towns shall be able to use our offering so that they won't be left behind. iShopAtoZ is also committed to creating a lot 
                        of job opportunities to better the life of each one of us. We aim at becoming India’s the most affordable e-commerce platform 
                        powering an impressive consumer experience.
                    </Text>
                    <Text style={missionStyle.coloredTextMobile}>
                        We provide an end-to-end e-commerce platform in a very user-friendly secure and efficient channel to 
                        deliver the most satisfying online shopping experience to the 1.2+ billion Indian consumers, together we 
                        eventually contributing to the nation’s growth.
                    </Text>
                </View>
                <View style={missionStyle.imageContainerMobile}  nativeID='socialConnect'>
                    {/*
                    <Image
                        source={require("../../../assets/aboutUs/man_with_home.png")}
                        style={missionStyle.imageStyleMobile}
                    />
                    <Image
                        source={require("../../../assets/aboutUs/arrow.png")}
                        style={missionStyle.arrowStyleMobile}
                    />
                    <Image
                        source={require("../../../assets/aboutUs/delivery_girl.png")}
                        style={missionStyle.imageStyleMobile}
                    />
                    <Image
                        source={require("../../../assets/aboutUs/arrow.png")}
                        style={missionStyle.arrowStyleMobile}
                    />
                    <Image
                    
                        source={require("../../../assets/aboutUs/customer.png")}
                        style={missionStyle.imageStyleMobile}
                    />
                    */}
                    <Image
                        source={require("../../../assets/aboutUs/mission_journey_mobile.png")}
                        style={missionStyle.imageStyleMobile}
                    />
                </View>
            </View>
        )
    }

    return (
        <View style={missionStyle.missionContainer}>
            <View Style={missionStyle.textContainer}>
                <Text style={missionStyle.topBoldText}>Our Mission</Text>
                <Text style={missionStyle.lightText}>
                    We want to empower people and onboard them to the Indian e-commerce ecosystem. People from all over India can join our 
                    platform. We make sure that the technological advancements won't remain limited only to the tier 1 cities, instead people from 
                    even smaller towns shall be able to use our offering so that they won't be left behind. iShopAtoZ is also committed to creating a lot 
                    of job opportunities to better the life of each one of us. We aim at becoming India’s the most affordable e-commerce platform 
                    powering an impressive consumer experience.
                </Text >
                <Text style={missionStyle.coloredText}>
                    We provide an end-to-end e-commerce platform in a very user-friendly secure and efficient channel to 
                    deliver the most satisfying online shopping experience to the 1.2+ billion Indian consumers, together we 
                    eventually contributing to the nation’s growth.
                </Text>
            </View>
            <View style={missionStyle.imageContainer}  >
                <Image
                    source={require("../../../assets/aboutUs/man_with_home.png")}
                    style={missionStyle.imageStyle}
                />
                {/*
                <Image
                    source={require("../../../assets/aboutUs/arrow.png")}
                    style={missionStyle.arrowStyle}
                />
                */}
                <Image
                    source={require("../../../assets/aboutUs/delivery_girl.png")}
                    style={missionStyle.imageStyle}
                />
                {/*
                <Image
                    source={require("../../../assets/aboutUs/arrow.png")}
                    style={missionStyle.arrowStyle}
                />
                */}
                <Image
                   
                    source={require("../../../assets/aboutUs/customer.png")}
                    style={missionStyle.imageStyle}
                />
                <View nativeID='theteam'></View>

            </View>
        </View>
    )
}

export default OurMission
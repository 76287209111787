import { StyleSheet } from "react-native";
import {Raleway, Raleway_400Regular, Raleway_600SemiBold} from '@expo-google-fonts/raleway'

export const buttonStyle  = StyleSheet.create({
    buttonContainer:{
        width: "11.71875vw",
        height: "6.568vh",
        backgroundColor: "#008489",
        borderRadius: "8px",
        alignItems:"center",
        justifyContent:"center",
        marginTop:"1.642vh",
        //marginLeft:"auto",
        //marginRight:"auto",
        marginBottom:"0vh"
    },
    buttonContainerMobile:{
        width: "36vw",
        height: "5.568vh",
        backgroundColor: "#008489",
        borderRadius: "8px",
        alignItems:"center",
        justifyContent:"center",
        // marginTop:"6vh",
        //marginLeft:"auto",
        //marginRight:"auto",
        marginBottom:"0vh"
    },
    buttonText:{
        width: "11.797vw",
        height: "3.12vh",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "1.25vw",
        lineHeight: "3.12vh",
        /* identical to box height */
        color: "#FFFFFF",
        textAlign:"center"

    },
    buttonTextMobile:{
        width: "151px",
        height: "19px",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "14px",
        /* identical to box height */
        color: "#FFFFFF",
        textAlign:"center"

    },
})
import {StyleSheet} from 'react-native'
import {Raleway} from '@expo-google-fonts/raleway'


export const bannerStyle = StyleSheet.create({
    bannerContainer:{
        width:"100%",
        height:"74.2vh",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        backgroundColor:"#E6F3F4",
        //backgroundColor:"#CCCCCC",
    },
    bannerImage:{
        width:"80%",
        // width:"1458px",
        // width:"90vw",
        height:"80vh",
        marginLeft:"auto",
        marginRight:"auto",
        //marginTop:"2vh",
        resizeMode:"contain"
    },

    bannerContainerMobile:{
        width:"100%",
        //height:"190px",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        //backgroundColor:"#CCCCCC",
        //backgroundColor:"#E6F3F4",
        marginBottom:"20px",
    },
    
    bannerImageMobile:{
        width:"100%",
        // width:"1458px",
        // width:"90vw",
        //height:"92.7vh",
        height:"160px",
        marginLeft:"auto",
        marginRight:"auto",
        //marginTop:"-60vh",
        //marginTop:"10px",
        resizeMode:"contain",
        //backgroundColor:"#AAAAAA"
    }
})
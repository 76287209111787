import {StyleSheet} from 'react-native'
import {Raleway, Raleway_400Regular, Raleway_600SemiBold} from '@expo-google-fonts/raleway'

export const partnerStyle = StyleSheet.create({
    proudContainer:{
        //width:"100%",
        width:"93.75vw",
        marginTop: "8.210vh",
        //backgroundColor: "#BFE0E1",
    },

    proudContainerMobile:{
        width:"100%",
        marginTop: "20px",
        marginBottom:"25px",
        //height:"600px"
    },

    topTextContainer:{
        display:"flex",
        flexDirection:"row",
        marginLeft:"auto",
        marginRight:"auto",
    },

    theSegmentText:{
        width: "46.875vw",
        height: "7.718vh",
        // left: "304.95px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "3.125vw",
        lineHeight: "7.718vh",
        color: "#2C2E2F",
        marginTop:"6.568vh"
    },

     middleLightText:{
        width: "37.5vw",
        height: "7.553vh",
        // left: 638px;
        // top: 2442px;
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "1.25vw",
        lineHeight: "3.777vh",
        color: "#2C2E2F"

    },

    arrowImage:{
        height:40,
        width:40,
        background: "#2C2E2F",
        // opacity: "0.25",
        transform: "matrix(-1, 0, 0, 1, 0, 0)"
    },

    gridContainer:{
        display:"grid",
        width:"86%",
        marginLeft:"auto",
        marginRight:"auto",
        gridGap:"10px"
    },

    groceryStyle:{
        width:"27.52vw",
        height:"38.96vh",
        resizeMode:"contain",
        background: "#000000",
        opacity: "0.5",
        
    },

    sellingContainerMobile:{
        width:"100%",
    },

    proudText:{
        // position: absolute;
        width: "100%",
        height: "6.568",
        //left: "304px",
        marginLeft: "25px",
        marginRight: "25px",
        // top: 2442.12px;

        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "1.953vw",
        lineHeight: "6.568vh",
        /* identical to box height */
        color: "#2C2E2F",
    },

    cardContainer:{
        display:"flex",
        flexDirection:"row",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        objectFit:"contain",
        // padding:"1%",
        justifyContent:"flex-start",
        width:"90%",
        marginTop:"1.642vh",
        // alignItems:"center",
        // width:"80vw",
        marginLeft:"6%"
      
    },

    cardContainerMobile:{
        display:"flex",
        flexDirection:"row",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        objectFit:"contain",
        // padding:"2%",
        justifyContent:"flex-start",
        width:"98%",
        marginTop:"30px",
        // alignItems:"center",
        // width:"80vw",
        //backgroundColor:"#CCCCCC"
      
    },

    cardImage:{
        // width: "250.63px",
        // height: "300px",
        width:" 23.42vw",
        height:"40vh",
        // width:"320px",
        height:"62.890vh",
        marginLeft:"5%",
        // marginLeft:"auto",
        // marginRight:"auto",
        textAlign:"left",
        //borderRadius:"8px",
        resizeMode:"cover",
        borderTopLeftRadius:"8px",
        borderTopRightRadius:"8px"

        // flex:1
    },
    cardImageMore:{
        width:" 23.42vw",
        height:"40vh",
        // width:"320px",
        height:"40.8vh",
        marginLeft:"5%",
        // marginLeft:"auto",
        // marginRight:"auto",
        textAlign:"left",
        //borderRadius:"8px",
        resizeMode:"cover",
        borderTopLeftRadius:"8px",
        borderTopRightRadius:"8px" 
    },

    knowMore:{
        marginLeft:"1%",
        height:"30vh"
    },

    buttonRootContainer:{
        marginLeft:"9vw",
        marginTop:"-4vh"
    },
    
    buttonRootContainerMobile:{
        marginLeft:"9vw",
        marginTop:"4vh"
    },


    /*
    cardContainerMobile:{
        width: "250px",
        height: "350px",
        //width:"14.62vw",
        //height:"10.62vh",
        backgroundColor: "#008489",
        borderRadius: "50px",
        alignItems:"center",
        marginRight:"auto",
        marginLeft:"auto",
        textAlign:"center",
        marginTop:"25px"
    },
    */
    cardViewContainerMobile:{
        width:"225px",
        height:"225px",
        //marginLeft:"10px",
        backgroundColor:"#FFFFFF"
    },

    cardViewContainer:{
        width:"23.90625vw",
        height:"41.0509vh", 
    },

    cardTitleContainerMobile:{
        //marginLeft:"35px",
        //marginRight:"20px",
        width:"225px",
        height:"45px",
        //backgroundColor:"#008489",
        backgroundColor:"#FFFFFF",
        marginTop:"-20px"
    },

    cardTextContainerMobile:{
        //marginLeft:"35px",
        //marginRight:"20px",
        width:"200px",
        height:"160px",
        //backgroundColor:"#5144D3",
        //backgroundColor:"#AAAAAA"
    },

    cardAllTextContainerMobile:{
        display:"flex",
        flexDirection:"row",
        width:"225px",
        height:"170px",
        //textAlign:"left",
        //alignItems:"left",
        //marginLeft:"auto",
        //marginRight:"auto",
        //backgroundColor:"#5144D3",
        //backgroundColor:"#CCCCCC",
    },

    cardTitleContainer:{
        //marginLeft:"9.172vw",
        //marginRight:"20px",
        width:"23.5vw",
        height:"7.852vh",
        //backgroundColor:"#008489",
        backgroundColor:"#FFFFFF",
        //borderTopLeftRadius:"5vw",
        //borderTopRightRadius: "48px",
        //borderBottomLeftRadius: "5vw",
        marginTop:"-10vh",
        // marginBottom:"10px",
        marginLeft:"5%",
        //alignItems:"right"
    },

    cardTextContainer:{
        //marginLeft:"1.172vw",
        //marginRight:"20px",
        width:"21.55vw",
        height:"14.0625vw",
        backgroundColor:"#FFFFFF",
    },

    cardAllTextContainer:{
        display:"flex",
        flexDirection:"row",
        width:"23.906vw",
        height:"14.0625vw",
        marginLeft:"5%",
        backgroundColor:"#FFFFFF",
    },

    cardImageMobile:{
        // width: "250.63px",
        // height: "300px",
        // width:" 23.42vw",
        // height:"58.29vh",
        // width:"320px",
        // height:"383px",
        width:"225px",
        height:"225px",
        //marginLeft:"5%",
        // marginLeft:"auto",
        // marginRight:"auto",
        textAlign:"left",
        //borderRadius:"8px",
        resizeMode:"cover",
        borderBottomLeftRadius:"8px",
        borderBottomRightRadius:"8px",
        borderTopLeftRadius:"8px",
        borderTopRightRadius:"8px"
        // flex:1
    },

    doublequoteMobile:{
        width:"15px",
        height:"20px",
        marginLeft:"10px",
        marginTop:"5px",
        // marginLeft:"auto",
        // marginRight:"auto",
        textAlign:"right",
        //borderRadius:"8px",
        resizeMode:"contain",
        //marginTop:"0"
        // flex:1
    },

    doublequote:{
        width:"1.172vw",
        height:"3.284vh",
        marginLeft:"0.781vw",
        marginTop:"0.821vh",
        // marginLeft:"auto",
        // marginRight:"auto",
        textAlign:"right",
        //borderRadius:"8px",
        resizeMode:"contain",
        //marginTop:"0"
        // flex:1
    },

    cardView:{
        height:"80vh",
        marginLeft:"1%",
        //backgroundColor:"#5144D3",
        width:"23.906vw"
    },

    
    cardViewMobile:{
        //height:"55vh",
        //marginLeft:"2%",
        marginLeft:"20px",
        //backgroundColor:"#5144D3",
        width:"225px",
        //backgroundColor:"#FF0000"
    },
    

    /*
    cardViewMobile:{
        height:"100%",
        marginLeft:"1%",
        backgroundColor:"#FF0000"
    },
    */

    mobileContainer:{
        marginTop:"50px"
    },

    topContainer:{
        display:"flex",
        flexDirection:"row",
        width:"80vw",
        textAlign:"left",
        alignItems:"left",
        marginLeft:"auto",
        marginRight:"auto",
        marginTop:"3.284vh",
        //backgroundColor:"#AAAAAA",
        height:"12.315vh",
        marginBottom:"4vh"
    },

    theSegmentText:{
        width: "46.875vw",
        height: "7.718vh",
        // left: "304.95px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "3.125vw",
        lineHeight: "7.718vh",
        color: "#2C2E2F",
        marginTop:"6.568vh"
    },

    centerText:{
        width: "37.5vw",
        height: "7.553vh",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "1.5625vw",
        lineHeight: "3.777vh",
        color: "#2C2E2F",
        textAlign:"left",
        marginLeft:"4vw"

    },

    navigatorContainer:{
        display:"flex",
        flexDirection:"row",
        float:"right",
        marginLeft:"inherit",
        // marginLeft:"17vw",
        alignItems:"center",
        justifyContent:"space-between",
        width:"6vw",
        //backgroundColor:"#CCCCCC",
        height:"3.284vh",
        marginTop:"9.031vh"
    },

    opacityElement:{
        width:16,
        height:16
    },

    redArrowImage:{
        transform:"matrix(1,0,0,-1,0,0)",
        width:10,
        alignSelf:"center",
        alignItems:"center",
        height:15,
        resizeMode:"contain",
        // marginLeft:"30px",
        float:"right",
        // display:"none"
    },

    greyArrowImage:{
        transform:"matrix(1,0,0,-1,0,0)",
        width:15,
        alignSelf:"center",
        alignItems:"center",
        height:15,
        resizeMode:"contain",
        // marginLeft:"30px",
        float:"right",
    },

    cardTitleMobile:{
        // width: "91px",
        height: "18px",
        // left: 418px;
        // top: 2060px;
        /* Large-Styles/Large-Semi Bold */
        textAlign:"left",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight: "18px",
        //color: "#FFFFFF",
        color:"#008489",
        marginTop:"5px",
        marginLeft:"40px",
        //marginLeft:"15px",
        width:"225px"

    },

    cardTextMobile:{
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "24px",
        textAlign: "left",
        color: "#2C2E2F",
        marginTop:"16px",
        marginLeft:"15px",
        width:"180px",
        marginBottom:"10px",
        

    },

    cardTitle:{
        width: "15vw",
        height: "4.105vh",
        // left: 418px;
        // top: 2060px;

        /* Large-Styles/Large-Semi Bold */
        textAlign:"left",
        marginLeft:"2.7625vw",
        //marginRight:"1.5625vw",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "1.09375vw",
        lineHeight: "4.105vh",

        /* Charcoal */
        //color: "#2C2E2F",
        //color: "#FFFFFF",
        color:"#008489",
        //backgroundColor:"#000000",
        marginTop:"0.3vh",
        marginBottom:"0.821vh",
    },

    cardText:{
        width: "19vw",
        height: "8.210vh",
        marginLeft:"1vw",
        marginRight:"1.5625vw",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",   
        fontSize: "1.09375vw",
        lineHeight: " 3.941vh",
        textAlign: "left",
        color: "#2C2E2F",
        //color: "#FFFFFF",
        marginTop:"1.642vh",
        marginBottom:"1.642vh",
        //backgroundColor:"#CCCCCC"

    },

    partnerTextMobile:{
        // position: absolute;
        width: "100%",
        height: "30px",
        //left: "304px",
        marginLeft: "auto",
        marginRight: "auto",
        // top: 2442.12px;

        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "30px",
        /* identical to box height */
        color: "#2C2E2F",
        textAlign:"center",
        marginBottom:"30px",
        marginTop:"20px"

    },

     middleLightTextMobile:{
        //width: "370px",
        width: "85%",
        height: "100px",
        marginLeft: "20px",
        marginRight: "20px",
        // left: 638px;
        // top: 2442px;
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "15px",
        lineHeight: "23px",
        color: "#2C2E2F"

    },

    middleLightTextRedMobile:{
        color: "#FF0000",
        fontWeight:"bold"

    },

    middleLightTextUnderLine:{
        color: "#000000",
        textDecorationLine:"underline",
        fontSize: "1.09375vw",

    },

    safeAreaContainer:{
        flex: 1,
        //paddingTop: StatusBar.currentHeight,

    },

    scrollViewCard:{
        //backgroundColor: 'pink',
        //marginHorizontal:"10px",
        //marginBottom:"3.284vh",
    },

    safeAreaContainerMobile:{
        flex: 1,
        //paddingTop: StatusBar.currentHeight,

    },

    scrollViewCardMobile:{
        //backgroundColor: 'pink',
        //marginHorizontal:"10px",
        //marginBottom:"3.284vh",
    },

})
import {StyleSheet} from 'react-native'
import {Raleway, Raleway_400Regular, Raleway_600SemiBold} from '@expo-google-fonts/raleway'

export const sellingStyle = StyleSheet.create({
    sellingContainer:{
        width:"100%",
        marginTop: "6.568vh"
    },

    topTextContainer:{
        display:"flex",
        flexDirection:"row",
        marginLeft:"auto",
        marginRight:"auto",
    },

    sellingAtoZText:{
        // position: absolute;
        width: "17.891vw",
        height: "7.718vh",
        left: "23.75vw",
        // top: 2442.12px;

        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "3.125vw",
        lineHeight: "7.718vh",
        /* identical to box height */
        color: "#2C2E2F",

    },

     middleLightText:{
        width: "37.578vw",
        height: "7.553vh",
        // left: 638px;
        // top: 2442px;
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "1.5625vw",
        lineHeight: "3.777vh",
        color: "#2C2E2F"

    },

    arrowImage:{
        height:40,
        width:40,
        background: "#2C2E2F",
        // opacity: "0.25",
        transform: "matrix(-1, 0, 0, 1, 0, 0)"
    },

    gridContainer:{
        display:"grid",
        width:"86%",
        marginLeft:"auto",
        marginRight:"auto",
        gridGap:"10px"
    },

    groceryStyle:{
        width:"27.52vw",
        height:"38.96vh",
        resizeMode:"contain",
        background: "#000000",
        opacity: "0.5",
        
    },

    sellingContainerMobile:{
        width:"100%",
    },

    sellingAtoZTextMobile:{
        // position: absolute;
        /* XL-Styles/XL-Semi Bold */
        marginLeft:"30px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "32px",
        lineHeight: "180%",
        /* identical to box height, or 58px */
        /* Charcoal-Text */
        color: "#2C2E2F",

    },

     middleLightTextMobile:{
        // position: absolute;
        // left: 7.5%;
        // right: 11.67%;
        // top: "30.24%",
        // bottom: "68.83%",
        /* Medium */
        marginLeft:"30px",
        width:"80%",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "180%",
        /* or 29px */
        /* Charcoal-Text */
        color: "#2C2E2F"

    },

    cardContainer:{
        display:"flex",
        flexDirection:"row",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        objectFit:"contain",
        padding:"2%",
        justifyContent:"flex-start",
        width:"90%",
        marginTop:" 4.926vh"
        // alignItems:"center",
        // width:"80vw",
      
    },

    cardContainerMobile:{
        display:"flex",
        flexDirection:"row",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        objectFit:"contain",
        // padding:"2%",
        justifyContent:"flex-start",
        width:"98%",
        marginTop:"30px"
        // alignItems:"center",
        // width:"80vw",
      
    },

    cardImage:{
        // width: "250.63px",
        // height: "300px",
        width:"23.42vw",
        height:"58.29vh",
        // width:"320px",
        height:"62.890vh",
        marginLeft:"5%",
        // marginLeft:"auto",
        // marginRight:"auto",
        textAlign:"left",
        borderRadius:"0.625vw",
        resizeMode:"stretch",
        // flex:1
    },

    cardImageMobile:{
        // width: "250.63px",
        // height: "300px",
        // width:" 23.42vw",
        // height:"58.29vh",
        // width:"320px",
        // height:"383px",
        width:"217px",
        height:"250px",
        marginLeft:"5%",
        // marginLeft:"auto",
        // marginRight:"auto",
        textAlign:"left",
        borderRadius:"8px",
        resizeMode:"stretch",
        // flex:1
    },

    cardView:{
        height:"100%",
        marginLeft:"1%"
    },

    cardViewMobile:{
        height:"100%",
        marginLeft:"1%"
    },

    cardTitle:{
        // width: "91px",
        height: "4.598vh",
        // left: 418px;
        // top: 2060px;

        /* Large-Styles/Large-Semi Bold */
        textAlign:"center",

        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "1.25vw",
        lineHeight: "4.598vh",

        /* Charcoal */
        color: "#008489",
        marginTop:"2.956vh",
        //backgroundColor:"#AAAAAA",
        marginLeft:"1.5625vw",

    },

    cardText:{
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "1.093vw",
        lineHeight: "3.941vh",
        textAlign: "center",
        color: "#2C2E2F",
        marginTop:"1vh",
        //backgroundColor:"#CCCCCC",
        marginLeft:"1.5625vw",
        width:"22vw"

    },

    cardTitleMobile:{
        // width: "91px",
        height: "28px",
        // left: 418px;
        // top: 2060px;

        /* Large-Styles/Large-Semi Bold */
        textAlign:"center",

        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "28px",

        /* Charcoal */
        color: "#008489",
        marginTop:"18px",
        marginLeft:"15px",
        width:"210px",
        whiteSpace:"nowrap"

    },

    cardTextMobile:{
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        textAlign: "center",
        color: "#2C2E2F",
        marginTop:"5px",
        marginLeft:"15px",
        width:"210px"
        

    },

    mobileContainer:{
        marginTop:"10px"
    },

    topContainer:{
        display:"flex",
        flexDirection:"row",
        width:"80vw",
        textAlign:"left",
        alignItems:"left",
        marginLeft:"auto",
        marginRight:"auto",
    },

    theSegmentText:{
        width: "21.484vw",
        height: "7.718vh",
        // left: "304.95px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "3.125vw",
        lineHeight: "7.718vh",
        color: "#2C2E2F",
    },

    centerText:{
        width: "32vw",
        height: "7.553vh",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "1.25vw",
        lineHeight: "3.777vh",
        color: "#2C2E2F",
        textAlign:"left",
        marginLeft:"4vw"

    },

    navigatorContainer:{
        display:"flex",
        flexDirection:"row",
        float:"right",
        marginLeft:"inherit",
        // marginLeft:"17vw",
        alignItems:"center",
        justifyContent:"space-between",
        width:"6vw"
    },

    opacityElement:{
        width:16,
        height:16
    },

    redArrowImage:{
        transform:"matrix(1,0,0,-1,0,0)",
        width:10,
        alignSelf:"center",
        alignItems:"center",
        height:15,
        resizeMode:"contain",
        // marginLeft:"30px",
        float:"right",
        // display:"none"
    },

    greyArrowImage:{
        transform:"matrix(1,0,0,-1,0,0)",
        width:15,
        alignSelf:"center",
        alignItems:"center",
        height:15,
        resizeMode:"contain",
        // marginLeft:"30px",
        float:"right",
    },
        knowMore:{
            marginLeft:"1%",
            height:"30vh"
        },
        knowMoreMobile:{
            marginLeft:"1.5%",
            height:"30vh"
        }

})
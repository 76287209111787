import {StyleSheet} from 'react-native'
import {Raleway, Raleway_400Regular, Raleway_600SemiBold} from '@expo-google-fonts/raleway'


export const callBackStyle = StyleSheet.create({
    callBackContainer:{
        background: "linear-gradient(180deg, #F2F9F9 0.78%, #FFFFFF 70.88%)",
        mixBlendMode: "normal",
        opacity: "0.8",
        borderRadius: "48px",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        display:"flex",
        flexDirection:"row",
        // padding: "10%",
        alignItems:"center",
        marginTop:"10vh"
        
    },

    topText:{
        // position: absolute;
        width: "444px",
        height: "56px",
        // left: "472px",
        // top: 1339px;
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "48px",
        lineHeight: "56px",
        color: "#2C2E2F",
        textAlign:"left"
    },
    bottomText:{
        // position: absolute
        width: "441px",
        height: "78px",
        // left: "472px",
        // top: "1415px",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "22px",
        lineHeight: "26px",
        color: "#2C2E2F",
        opacity: "0.75",
        textAlign:"left",
        marginTop:"2vh"
    },

    rightContainer:{
        display:"flex",
        flexDirection:"row",
        width:"50%",
        // height:'45px',
        alignItems:"center",
        height:"38.96vh",
        marginLeft:"4vw"
    },

    leftContainer:{
        width:"50%",
        // display:"block"
        // height:"38.96vh",
    },
    inputBox:{
        width: "256px",
        height: "45px",
        backgroundColor: "#2C2E2F",
        opacity: "0.3",
        border: "1px solid #2C2E2F",
        boxSizing: "border-box",
        borderRadius:"48px"
    },
    // submitButton:{
    //     textTransform:"lowercase",
    //     height:"45px",
    //     marginLeft:"4%"
    // },
    submitButton:{
        // position: "absolute",
        width: "86px",
        height: "45px",
        backgroundColor: "#FF002A",
        borderRadius: "48px",
        textAlign:"center",
        alignItem:"center"
    }
    
})
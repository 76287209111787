export const buyerFlexData = [
    {
        img:require("../../../assets/buyers/best_discount.png"),
        title:"Best Discounts",
        text:"We ensure that you get the products with\nmaximum discount",
        textMobile:"We ensure that you get the\nproducts with maximum discount",
    },
    {
        img:require("../../../assets/buyers/assured_quality.png"),
        title:"Assured Quality",
        text:"Delivering quality service and product is\nalways our priority",
        textMobile:"Delivering quality service and\nproduct is always our priority"
    },
    {
        img:require("../../../assets/buyers/quick_delivery.png"),
        title:"Quick Delivery",
        text:"Our efficient delivery network provides you\nsafe and quick delivery",
        textMobile:"Our efficient delivery network provides you safe and quick delivery"
    },
    // {
    //     img:require("../../../assets/buyers/painless_return.png"),
    //     title:"Painless Return",
    //     text:"You may return any product at the time of\ndelivery, if not satisfied",
    //     textMobile:"You may return any product at the\ntime of delivery, if not satisfied"
    // },
    // {
    //     img:require("../../../assets/buyers/easy_checkout.png"),
    //     title:"Easy Checkout",
    //     text:"Wallet for easy checkout",
    //     textMobile:"Wallet for easy checkout"
    // },
    // {
    //     img:require("../../../assets/buyers/cash_on_delivery.png"),
    //     title:"Cash on Delivery",
    //     text:"Pay at your doorstep",
    //     textMobile:"Pay at your doorstep"
    // },
    // {
    //     img:require("../../../assets/buyers/secure_payment.png"),
    //     title:"Secure Online Payment",
    //     text:"Experience the safest and secure online\npayment",
    //     textMobile:"Experience the safest and secure\nonline payment"
    // },
    // {
    //     img:require("../../../assets/buyers/efficient_tracking.png"),
    //     title:"Efficient Order Tracking",
    //     text:"Know every step in your order journey",
    //     textMobile:"Know every step in your order\njourney"
    // },
    // {
    //     img:require("../../../assets/buyers/delivery_tracking.png"),
    //     title:"Live Delivery Tracking",
    //     text:"Track the delivery partner location at real-\ntime",
    //     textMobile:"Track the delivery partner location at\nreal-time"
    // },
    // {
    //     img:require("../../../assets/buyers/secure_payment.png"),
    //     title:"Customer Privacy",
    //     text:"We ensure privacy of our customers",
    //     textMobile:"We ensure privacy of our customer"
    // },
    // {
    //     img:require("../../../assets/buyers/instant_messaging.png"),
    //     title:"Instant messaging service",
    //     text:"Connect to the seller over chat",
    //     textMobile:"Connect to the seller over chat"
    // },
    // {
    //     img:require("../../../assets/buyers/notifications.png"),
    //     title:"Notification",
    //     text:"Receive real-time order status through\nnotifications",
    //     textMobile:"Receive real-time order status\nthrough notifications"
    // },
    // {
    //     img:require("../../../assets/buyers/additional_discount.png"),
    //     title:"Get Additional Discounts",
    //     text:"Earn additional discounts through referrals",
    //     textMobile:"Earn additional discounts through\nreferrals"
    // },
]

export const sellerFlexData = [
    {
        img:require("../../../assets/sellers/commission.png"),
        title:"0% Commission",
        text:"Our sellers are 100% freed from paying\ncommission",
        textMobile:"Our sellers are 100% freed from\npaying commission"
    },
    {
        img:require("../../../assets/sellers/more.png"),
        title:"Pay Less & Get More",
        text:"Enjoy the most affordable service with great\nfeatures",
        textMobile:"Enjoy the most affordable service\nwith great features"
    },
    // {
    //     img:require("../../../assets/sellers/free_delivery.png"),
    //     title:"Free Delivery Network",
    //     text:"Expand your service with our quality delivery\nnetwork",
    //     textMobile:"Expand your service with our quality\ndelivery network"
    // },
    // {
    //     img:require("../../../assets/sellers/growth_driver.png"),
    //     title:"Growth Driver",
    //     text:"We provide expert guidance to scale & grow\nyour business",
    //     textMobile:"We provide expert guidance to\nscale & grow your business"
    // },
    // {
    //     img:require("../../../assets/sellers/sales_booster.png"),
    //     title:"Sales Booster",
    //     text:"Dedicated iShopAtoZ associate to help\nincrease your sales",
    //     textMobile:"Dedicated iShopAtoZ associate to\nhelp increase your sales"
    // },
    // {
    //     img:require("../../../assets/sellers/free_branding.png"),
    //     title:"Free Branding & Promotion",
    //     text:"Done by iShopAtoZ",
    //     textMobile:"Done by iShopAtoZ"
    // },
    // {
    //     img:require("../../../assets/sellers/minimize_spending.png"),
    //     title:"Minimize Your Maketing Spend",
    //     text:"We will do the marketing for you to\nincrease your profit",
    //     textMobile:"We will do the marketing for you to\nincrease your profit"
    // },
    // {
    //     img:require("../../../assets/sellers/faster_payment.png"),
    //     title:"Faster Payment Settlement",
    //     text:"Get your payment on-time",
    //     textMobile:"Get your payment on-time"
    // },
    {
        img:require("../../../assets/sellers/24x7_help.png"),
        title:"24X7 Help",
        text:"We are always there to help you,\ncontact@ishopatoz.com",
        textMobile:"We are always there to help you,\ncontact@ishopatoz.com"
    },
]

export const deliveryFlexData = [
    {
        img:require("../../../assets/delivery/location_icon.png"),
        //img:require("../../../assets/sellers/24x7_help.png"),
        title:"Pick a Location",
        text:"Be at your preferred location",
        textMobile:"Be at your preferred location"
    },
    {
        img:require("../../../assets/delivery/time_icon.png"),
        title:"Flexi Hours",
        text:"You decide your working hours",
        textMobile:"You decide your working hours"
    },
    {
        img:require("../../../assets/delivery/money_icon.png"),
        title:"Grow Together",
        text:"Contribute to the community & earn",
        textMobile:"Contribute to the community & earn"
    }
]
import * as React from 'react'

import {
    View,
    Text,
    Image,
    ScrollView,
    TouchableOpacity,
    Dimensions,
    Button,
    ImageBackground,
    Linking,
    SafeAreaView, 
} from 'react-native'
import { useState,useRef } from 'react'
import { testimonialStyle } from './TestimonialStyle'
import { useMediaQuery } from 'react-responsive';
//import {segmentStyle} from './segmentStyle'
import CustomButton from '../../commonComponents/SubmitButton';

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

let backwardArrow = require("../../../assets/common_assets/back_arrow.png")
let forwardArrow = require("../../../assets/common_assets/forward_arrow.png")
let doublequote = require("../../../assets/common_assets/double_quote_green.png")

const customerAppPlayStore = "https://play.google.com/store/apps/details?id=com.ishopatoz.android.customer.release"

const cardImages = [
                    {
                        id:6,
                        img:require("../../../assets/testimonials/buyer/nancy.png"),
                        title:"Nancy Jacob\nEngineer",
                        text:"I love that iShopAtoZ has a very smooth and interactive user interface - it is simple and easy to use."
                    },
                    {
                        id:1,
                        img:require("../../../assets/testimonials/buyer/sai.jpeg"),
                        title:"Dasari Saikumar\nDeputy Manager, ICICI Bank",
                        text:"iShopAtoZ always gives the best discounts and offers which ensures the best value for money while shopping."
                    },
                    {
                        id:7,
                        img:require("../../../assets/testimonials/buyer/amaya.png"),
                        title:"Amaya N\nStudent",
                        text:"iShopAtoZ ensures quick and safe delivery service and this way I can always rely on them for placing last minute orders too."
                    },
                    // {
                    //     id:2,
                    //     img:require("../../../assets/testimonials/buyer/krishna.jpeg"),
                    //     title:"Gande Krishna\nVerification Engineer",
                    //     text:"iShopAtoZ helps me buy products and services from my locality. This way I get to access the nearest best available options."
                    // },
                    // {
                    //     id:3,
                    //     img:require("../../../assets/testimonials/buyer/kamalamma.png"),
                    //     title:"Kamalamma\nHousewife",
                    //     text:"At iShopAtoZ, I can order from my trusted sellers in my locality just from the comfort of my phone."
                    // },
                    {
                        id:4,
                        img:require("../../../assets/testimonials/buyer/venkatesh.png"),
                        title:"Venkatesh\nBusiness",
                        text:"iShopAtoZ is a one stop shop. It has a wider range of products and services compared to other available platforms in the market."
                    },
                    {
                        id:5,
                        img:require("../../../assets/testimonials/buyer/vijay.png"),
                        title:"Vijay\nContractor",
                        text:"One of the best things about iShopAtoZ is that there is no minimum order value requirement to place the order."
                    },
                    {
                        id:8,
                        img:require("../../../assets/testimonials/buyer/keerthi.png"),
                        title:"Keerthi Suresh\nStudent",
                        text:"At iShopAtoZ, I can get quality products from verified sellers."
                    },
]

const Testimonials = () => {

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const scrollViewRef = useRef(null);
    const width = Dimensions.get("window")
    
    const [cardState,setCardState] = useState({currentCard:0})

    const {currentCard:pageIndex} = cardState

    const toNextView = () =>{
            scrollViewRef.current.scrollTo({
                // screenX:width*(pageIndex-4)*-1,
                x:1600,
                animated:true,
            })
    }

    const toBackView = () => {
        if(scrollViewRef.current !== null){
            scrollViewRef.current.scrollTo({
                x:width*(pageIndex+1),
                animated:true,
            })
        }
    }

    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:450
    })


    if(isMobileDisplay){
        return(
            <View style={testimonialStyle.proudContainerMobile}>
                <Text style={testimonialStyle.partnerTextMobile}>They talk about it{"\n"}better than us</Text>
                
                <View style={testimonialStyle.cardContainerMobile}>
                    <ScrollView
                            horizontal={true}
                            showsHorizontalScrollIndicator={false}
                            ref={scrollViewRef}
                    >
                        {cardImages.map(card=>{
                            return<View style={testimonialStyle.cardViewMobile}> 
                                <View style={testimonialStyle.cardViewContainerMobile}>
                                   <TouchableOpacity style={testimonialStyle.knowMore} onPress={()=>Linking.openURL(customerAppPlayStore)}>
                                    <Image
                                        source={card.img}
                                        style={testimonialStyle.cardImageMobile}
                                    />
                              </TouchableOpacity>

                                </View>
                                <View style={testimonialStyle.cardTitleContainerMobile}>
                                    <Text style={testimonialStyle.cardTitleMobile}>{card.title}</Text>
                                </View>
                                <View style={testimonialStyle.cardAllTextContainerMobile}>
                                    <Image
                                        source={doublequote}
                                        style={testimonialStyle.doublequoteMobile}
                                    />
                                    <View style={testimonialStyle.cardTextContainerMobile}>
                                        {/*
                                        <Text nativeID='serviceableCities' style={testimonialStyle.cardTextMobile}>{card.text}</Text>
                                        */}  
                                        <SafeAreaView style={testimonialStyle.safeAreaContainerMobile}>
                                            <ScrollView style={testimonialStyle.scrollViewCardMobile}>
                                                <Text nativeID='serviceableCities' style={testimonialStyle.cardTextMobile}>{card.text}</Text>
                                            </ScrollView>   
                                        </SafeAreaView>        
                                    </View>
                                </View>
                            </View>           
                        })}
                         <TouchableOpacity style={testimonialStyle.knowMoreMobile} onPress={()=>Linking.openURL(customerAppPlayStore)}>
                        <Image style={testimonialStyle.cardImageMoreMobile} source={require("../../../assets/common_assets/dottedCard.png")}/>
                    </TouchableOpacity>
                    </ScrollView>
                </View>
                <View style={testimonialStyle.buttonRootContainerMobile}>
                    <CustomButton
                                buttonLink="https://docs.google.com/forms/d/1s5-P0PuAylPVzLQEA4KaC5GBW79EOsrmuJ6Aly-V9ws/edit?usp=sharing"
                                buttonText="Submit Feedback"
                                />
                </View>
            </View>
        )
    }

    return (
        <View style={testimonialStyle.proudContainer}>
           <View style={testimonialStyle.topContainer}>
                <Text style={testimonialStyle.theSegmentText}>They talk about it better than us</Text>
                
                <View style={testimonialStyle.navigatorContainer}>
                    <TouchableOpacity
                        onPress={()=>toBackView()}
                        style={testimonialStyle.opacityElement}
                    >
                        <Image 
                            source={require("../../../assets/common_assets/backward.png")}
                            style={testimonialStyle.greyArrowImage}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={()=>toNextView()}
                        style={testimonialStyle.opacityElement}
                    >
                        <Image
                            source={require("../../../assets/common_assets/forward.png")}
                            onPress={() => source={backwardArrow}}
                            style={testimonialStyle.redArrowImage}
                            // onProgress={()=>{this.scrollview.scrollToEnd({x:screenWidth})}}
                        />
                     </TouchableOpacity>
                </View>
           </View>
           <View style={testimonialStyle.cardContainer}>
               <ScrollView
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    ref={scrollViewRef}
               >
                {cardImages.map(card=>{
                    return<View style={testimonialStyle.cardView}> 
                        <View style={testimonialStyle.cardViewContainer}>
                         <TouchableOpacity style={testimonialStyle.knowMore} onPress={()=>Linking.openURL(customerAppPlayStore)}>
                            <Image
                            source={card.img}
                            style={testimonialStyle.cardImage}
                            />
                     </TouchableOpacity>
                        </View>
                        <View style={testimonialStyle.cardAllTextContainer}>
                            <Image
                                source={doublequote}
                                style={testimonialStyle.doublequote}
                            />
                            <View style={testimonialStyle.cardTextContainer}>
                                {/*
                                <Text nativeID='serviceableCities' style={testimonialStyle.cardText}>{card.text}</Text>
                                */}
                                <SafeAreaView style={testimonialStyle.safeAreaContainer}>
                                    <ScrollView style={testimonialStyle.scrollViewCard}>
                                        <Text  style={testimonialStyle.cardText}>{card.text}</Text>
                                    </ScrollView>   
                                </SafeAreaView>
                            </View>
                        </View> 
                        <View nativeID='serviceableCities'/>
                        <View style={testimonialStyle.cardTitleContainer}>
                            <Text style={testimonialStyle.cardTitle}>{card.title}</Text>
                        </View>
                      
                    </View>
                     
                })}
                    <TouchableOpacity style={testimonialStyle.knowMore} onPress={()=>Linking.openURL(customerAppPlayStore)}>
                        <Image style={testimonialStyle.cardImageMore} source={require("../../../assets/common_assets/dottedCard.png")}/>
                    </TouchableOpacity>
                 </ScrollView>
                 {"\n"}
           </View>
           <View style={testimonialStyle.buttonRootContainer}>
                <CustomButton
                            buttonLink="https://docs.google.com/forms/d/1s5-P0PuAylPVzLQEA4KaC5GBW79EOsrmuJ6Aly-V9ws/edit?usp=sharing"
                            buttonText="Submit Feedback"
                            />
            </View>
        </View>
    )

}

export default Testimonials
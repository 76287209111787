import { Raleway_400Regular, Raleway_500Medium, Raleway_600SemiBold } from '@expo-google-fonts/raleway'
import {StyleSheet} from 'react-native'
//import {Raleway} from '@expo-google-fonts/raleway'

export const servicableStyle = StyleSheet.create({
    citiesContainer:{
        display:"block",
        flexDirection:"row",
        width:"100%",
        height:"100%",
        // marginTop:"180px",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"4.926vh",

    },
    
    topText:{
        // width: "60.13909224011713vw",
        width:"26.484vw",
        height: "7.718vh",
        // height:"7.153729071537291vh",
        fontFamily: 'Raleway_600SemiBold' ,
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "3.125vw",
        // lineHeight: "47px",
        lineHeight:"7.154vh",
        /* identical to box height */
        color: "#2C2E2F",
        float:"left",
        textAlign:"left",
        marginLeft:"10%",

    },

     cityCardContainer:{
        width:"82vw",
        // margin:"1%",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"4.926vh",
        // flexWrap:"wrap",
        // display:"flex",
        // flexDirection:"row",
    },

    cityCard:{
        // display:"flex",
        width:"10",
        // flexDirection:"row",
        alignItems:"center",
        marginLeft:"auto",
        marginRight:"auto",
        marginLeft:"1%",
        backgroundColor:"#E6F3F4",
        borderRadius:"0.625vw"
    },

    cityImg:{
        // width: "100px",
        // height: "79px",
        width:"23.42vw",
        height:"44.11vh",
        resizeMode:"stretch",
        borderTopLeftRadius:"0.625vw",
        borderTopRightRadius:"0.625vw"
    },

    locationView:{
        height:"14.361vh",
        // alignItems:"center",
        textAlign:"left",
        width:"100%"
    },

    locationViewMobile:{
        height:"124px",
        // alignItems:"center",
        textAlign:"left",
        width:"100%"
    },

    cityText:{
        width: "9.375vw",
        height: "3.440vh",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "1.443vw",
        lineHeight: "3.105vh",
        color: "#008489",
        opacity: "0.75",
        marginTop:"3.284vh",
        marginLeft:"3.284vh"
    },

    statusText:{
        marginLeft:"1.5625vw",
        width: "9.203vw",
        height: "2.956vh",
        fontFamily: 'Raleway_500Medium',
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "0.972vw",
        lineHeight: "2.791vh",
        color: "#2C2E2F",
        marginTop:"1vh",
        textAlign:"left",
    },
    
    topContainer:{
        display:"flex",
        flexDirection:"row",
        // float:"right",
        marginLeft:"inherit",
        alignItems:"center",
        // width:"90vw"
    },
    navigatorContainer:{
        width:"6vw",
        display:"flex",
        flexDirection:"row",
        marginLeft:"48vw",
        alignItems:"center",
        justifyContent:"space-between"
    },

    //responsive UI, Mobile
    topTextMobile:{
        // width: "60.13909224011713vw",
        width:"280px",
        height: "47px",
        // height:"7.153729071537291vh",
        fontFamily: 'Raleway_600SemiBold' ,
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "32px",
        // lineHeight: "47px",
        lineHeight:"180%",
        /* identical to box height */
        color: "#2C2E2F",
        //float:"left",
        textAlign:"left",
        marginLeft:"20px",
        //backgroundColor:"#CCCCCC"

    },

    citiesContainerMobile:{
        //width:"100%",
        //marginLeft:"auto",
        //marginRight:"auto",
        //textAlign:"center",
        marginTop:"30px",

    },

    cityCardContainerMobile:{
        width:"96%",
        // margin:"1%",
        // marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"15px",
        marginLeft:"5px"
        // flexWrap:"wrap",
        // display:"flex",
        // flexDirection:"row",
    },

    cityCardMobile:{
        // display:"flex",
        // width:"10",
        width:"190px",
        height:"245px",
        // flexDirection:"row",
        alignItems:"center",
        marginLeft:"auto",
        marginRight:"auto",
        marginLeft:"1%",
        backgroundColor:"#E6F3F4",
        borderRadius:"8px"
    },
    cityTextMobile:{
        width: "120px",
        height: "29px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "180%",
        color: "#008489",
        // opacity: "0.75",
        marginTop:"20px",
        marginLeft:"20px"
    },

    cityImgMobile:{
        width: "190px",
        // height: "176px",
        height:"146px",
        // width:"23.42vw",
        // height:"46.11vh",
        resizeMode:"stretch",
        borderTopLeftRadius:"8px",
        borderTopRightRadius:"8px"
    },
    statusTextMobile:{
        marginLeft:"20px",
        width: "120px",
        height: "29px",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "180%",
        color: "#2C2E2F",
        // marginTop:"20px",
        textAlign:"left",
    },
    knowMore:{
        marginLeft:"1%",
        height:"30vh"
    },
        cityImgMobileKnowMore:{
        width:"190px",
        height:"245px",
            // width:"23.42vw",
            // height:"46.11vh",
            resizeMode:"stretch",
            borderTopLeftRadius:"8px",
            borderTopRightRadius:"8px"
        },
            cityImgKnowMore:{
                // width: "100px",
                // height: "79px",
                width:"23.42vw",
                height:"59.0vh",
                resizeMode:"stretch",
                borderTopLeftRadius:"0.625vw",
                borderTopRightRadius:"0.625vw"
            }
})
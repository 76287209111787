import * as React from 'react'
import {View, Image,Text} from 'react-native'
import {easyStyle} from './easyStyles'
import QrElement from '../../commonComponents/QrComponent'

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

const deliveryQr = require("../../../assets/delivery/delivery_qr.png")

const stepData = [
    {
        img:require("../../../assets/delivery/download_icon.png"),
        text:"Download the app",
    },
    {
        img:require("../../../assets/delivery/signup_icon.png"),
        text:"Complete Sign up",
    },
    {
        img:require("../../../assets/delivery/earning_icon.png"),
        text:"Start Earning"
    }
]

const EasySteps = () => {

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    return  (
        <View style={easyStyle.easyContainer}>
            <Text style={easyStyle.topBoldText}>Ready to drive your dreams!</Text>
            <Text style={easyStyle.topLightText}>Be at your place always. We respect your time and efforts. We know you deserve the best, so we strive to give you the best.We{"\n"} respect your time and efforts. We know you deserve the best,
                 so we strive to give you the best. 
                </Text>
            <View style={easyStyle.subContainer}>
                <View style={easyStyle.leftContainer}>
                    <Text style={easyStyle.joinText}>Join us with 3 easy steps</Text>
                    <View style={easyStyle.itemContainer}>
                        {stepData.map(item=>{
                            return <View style={easyStyle.stepContainer}>
                                        <Image
                                            source={item.img}
                                            style={easyStyle.stepIcons}
                                        />
                                        <Text style={easyStyle.stepText}>{item.text}</Text>
                                </View>
                        })}
                    </View>
                </View>
                <View style={easyStyle.rightContainer}>
                    <QrElement qrImage={deliveryQr}/>
                </View>
            </View>
        </View>
    )
}

export default EasySteps
export const cardImages = [
    {
        id:1,
        img:require("../../../assets/testimonials/seller/SK_Marketing_and_Distributor.jpg"),
        name:"SK Marketing and Distributor",
        designation:"Beauty and Salon",
        text:"I always wanted to take my business online, thanks to iShopAtoZ for making my dream come true"
    },
    // {
    //     id:2,
    //     img:require("../../../assets/testimonials/seller/Shiv_shakthi_Home_Appliances.jpg"),
    //     name:"Shiv Shakthi Home Appliances",
    //     designation:"Home and Kitchen Appliances",
    //     text:"I never knew that I could sell my products online. Thanks to iShopAtoZ, I'm now an online seller"
    // },
    {
        id:4,
        img:require("../../../assets/testimonials/seller/Sri_Manjunatha_Wholesale_Traders.jpg"),
        name:"Sri Manjunatha Wholesale Traders",
        designation:"Grocery",
        text:"After becoming a seller on iShopAtoZ, I'm now confident of getting more customers"
    },
    {
        id:5,
        img:require("../../../assets/testimonials/seller/Sahitya_Fruits_And_Vegetable_Shop.jpg"),
        name:"Sahitya Fruits and Vegetable Shop",
        designation:"Grocery",
        text:"I do quick doorstep delivery to my customers, after they purchase products from me through iShopAtoZ app"
    },
    {
        id:3,
        img:require("../../../assets/testimonials/seller/AR_Infotech.jpg"),
        name:"AR Infotech",
        designation:"Electronics",
        text:"I'm happy to be a seller on iShopAtoZ, now I can sell my products online" 
    },
    // {
    //     id:6,
    //     img:require("../../../assets/common_assets/more_dots.png"),
    //     name:"More",
    //     designation:"",
    //     text:""
    // },
    // {
    //     id:7,
    //     img:require("../../../assets/testimonials/seller/Hiba_Telecom.jpg"),
    //     name:"Hiba Telecom",
    //     designation:"Electronics",
    //     text:"iShopAtoZ is a great concept and online platform for store owners like me"
    // },
]

import * as React from 'react'
import {View,Image,Text,TouchableOpacity,Linking} from 'react-native'
import { Link } from 'react-router-native'
import {socialConnectStyle} from './socialConnectStyles'
import { useMediaQuery } from 'react-responsive';

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

const socialData = [
    {
        id:1,
        img:require("../../../assets/aboutUs/in.png"),
        followers:30,
        socialId:"@ishopatoz",
        socialUrl:"https://www.linkedin.com/company/ishopatoz/"
    },
    {
        id:2,
        img:require("../../../assets/aboutUs/fb.png"),
        followers:10,
        socialId:"@ishopatoz",
        socialUrl:"https://www.facebook.com/iShopAtoZ/"
    },
    {
        id:3,
        img:require("../../../assets/aboutUs/insta.png"),
        followers:20,
        socialId:"@ishopatoz_official",
        socialUrl:"https://instagram.com/ishopatoz_official"
    },

]

const SocialConnect = () => {

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:450
    })

    if(isMobileDisplay){
        return(
            <View style={socialConnectStyle.socialContainer}>
                <Text style={socialConnectStyle.topBoldTextMobile}>Social Connect</Text>
                <View style={socialConnectStyle.socialCardContainerMobile}>
                    <View style={socialConnectStyle.socialSubContainerMobile}>
                        {socialData.map(item=>{
                            return<View style={socialConnectStyle.socialCardMobile}>
                                    <TouchableOpacity
                                        onPress={() =>Linking.openURL(item.socialUrl) }
                                        >
                                        <Image 
                                            source={item.img}
                                            style={socialConnectStyle.socialImgMobile}
                                            />
                                    </TouchableOpacity>
                                    {/*<Text style={socialConnectStyle.followerTextMobile}>{item.followers}k</Text> */}
                                    <Text style={socialConnectStyle.idTextMobile}>{item.socialId}</Text>
                            </View>
                        })}
                    </View>
                </View>
            </View>
        )
    }

    return(
        <View style={socialConnectStyle.socialContainer}>
            <Text style={socialConnectStyle.topBoldText}>Social Connect</Text>
            <View style={socialConnectStyle.socialCardContainer}>
                <View style={socialConnectStyle.socialSubContainer}>
                    {socialData.map(item=>{
                        return<View style={socialConnectStyle.socialCard}>
                                <TouchableOpacity
                                    onPress={() =>Linking.openURL(item.socialUrl) }
                                    >
                                    <Image 
                                        source={item.img}
                                        style={socialConnectStyle.socialImg}
                                        />
                                </TouchableOpacity>
                                <Text style={socialConnectStyle.followerText}>{item.followers}k</Text>
                                <Text style={socialConnectStyle.idText}>{item.socialId}</Text>
                        </View>
                    })}
                </View>
            </View>
        </View>
    )
}

export default SocialConnect

import * as React from 'react'
import {View} from 'react-native'


// import TopNavbar from '../components/commonComponents/TopNav'
import TopNavbar from '../components/commonComponents/TopNav/index';
import BottomNavbar from '../components/commonComponents/BottomNav/index'
import {deliveryLinks} from "../components/commonComponents/BottomNav/data"
import DeliveryHome from "../components/deliveryComponents/deliveryHome/index"
import RequestCall from '../components/commonComponents/RequestCallBack/index'
import WhyUs from '../components/deliveryComponents/whyUsComponent/index';
import EasySteps from '../components/deliveryComponents/easySteps/index';
import AskedQuestion from '../components/commonComponents/AskedQuestion/index'
import {deliveryQuestion} from "../components/commonComponents/AskedQuestion/data"
import Footer from "../components/commonComponents/Footer/index"
import DeliveryPartner from '../components/deliveryComponents/deliveryPartner/index'
import OrderInSecond from '../components/commonComponents/OrderInSecond/index';
import { deliveryCardData } from '../components/commonComponents/OrderInSecond/data';

const deliveryQr = require("../assets/delivery/delivery_qr.png")
const playStoreApp = "https://play.google.com/store/apps/details?id=com.ishopatoz.android.delivery.release"
import GoToTop from '../components/commonComponents/GoToTop/index';

const DeliveryPage = () => {
    return(
    <View>
        <TopNavbar/>
        <BottomNavbar links={deliveryLinks}/>
        <DeliveryHome/>
        <GoToTop/>
        {/* <RequestCall/> */}
        <WhyUs/>
        <DeliveryPartner/>
        {/* <EasySteps/> */}
        <OrderInSecond
            text="Ready to drive your dreams!" 
            cardData={deliveryCardData}
            qrImage={deliveryQr}
            appStoreUrl={playStoreApp}
            appText="Download the delivery app now"
        />

        <AskedQuestion data={deliveryQuestion}/>
        <Footer
            cotactMailLink="delivery@ishopatoz.com"
            contactMailText="delivery@ishopatoz.com"
            appUrl={playStoreApp}
            downloadText="Download the delivery app now"
        />
    </View>
    )
}
export default DeliveryPage
import * as React from 'react'
import  {View, Image,Text, TouchableOpacity,Linking} from 'react-native'
import {hiringStyle} from './hiringStyles'
import { useMediaQuery } from 'react-responsive';
const applyNowLink = "https://docs.google.com/forms/d/1br46Sw9L42hhzO3NmKP9gS-r93XV4V93XJiIX9FGGIU/edit?ts=61a4762c"

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

const cardData = [
    {
        img:require("../../../assets/delivery/location_icon.png"),
        boldText:"Pick a Location",
        lightText:"Be at your preferred\nlocation",
        lightTextMobile:"Be at your preferred location"
    },
    {
        img:require("../../../assets/delivery/time_icon.png"),
        boldText:"Flexi Hours",
        lightText:"You decide your working\nhours",
        lightTextMobile:"You decide your working hours"
    },
    {
        img:require("../../../assets/delivery/money_icon.png"),
        boldText:"Grow Together",
        lightText:"Contribute to the\ncommunity & earn",
        lightTextMobile:"Contribute to the community & earn"
    }
]

const Hiring = () => {

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:450
    })

    if(isMobileDisplay){
        return (
            <View style={hiringStyle.hiringContainerMobile}>
                <Text style={hiringStyle.topBoldTextMobile}>We're Hiring</Text>
                <TouchableOpacity
                    style={hiringStyle.applyNowButtonMobile}
                    onPress={()=>Linking.openURL(applyNowLink)}
                    >
                    <Text style={hiringStyle.applyNowTextMobile}>Apply Now</Text>
                </TouchableOpacity>
                <Text style={hiringStyle.topLightTextMobile}>Our team is growing fast. Looking for passionate candidates to join the team</Text>
            </View>
        )
    }

    return (
        <View style={hiringStyle.hiringContainer}>
            <View style={hiringStyle.topContainer}>
                <Text style={hiringStyle.topBoldText}>We're Hiring</Text>
                <TouchableOpacity
                    style={hiringStyle.applyNowButton}
                    onPress={()=>Linking.openURL(applyNowLink)}
                    >
                    <Text  nativeID='socialConnect' style={hiringStyle.applyNowText}>Apply Now</Text>
                </TouchableOpacity>
            </View>
            <View
                style={hiringStyle.blockSpace}
            />
            <Text style={hiringStyle.topLightText}>Our team is growing fast. Looking for passionate candidates to join the team</Text>
        </View>
    )
}

export default Hiring
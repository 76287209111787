import * as React from 'react'
import { useRef,useState } from 'react'
import {
    View,
    Text,
    Image,
    ScrollView,
    Dimensions,
    TouchableOpacity,
    Linking
} from 'react-native'
import { useMediaQuery } from 'react-responsive'
import {servicableStyle} from "./servicableStyle"
import { segmentStyle } from '../Categories/segmentStyle'

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

const customerPlayStore = "https://play.google.com/store/apps/details?id=com.ishopatoz.android.customer.release"

const cities = [
    {
        id:1,
        img:require("../../../assets/buyers/bengaluru.png"),
        city:"Bengaluru",
        serviceStatus:"Serviceable city"
    },
    {
        id:2,
        img:require("../../../assets/buyers/delhi.png"),
        city:"Delhi",
        serviceStatus:"Coming soon"
    },
    {
        img:require("../../../assets/buyers/mumbai.png"),
        city:"Mumbai",
        serviceStatus:"Coming soon"
    },
    {
        id:3,
        img:require("../../../assets/buyers/chennai.png"),
        city:"Chennai",
        serviceStatus:"Coming soon"
    },
    {
        id:4,
        img:require("../../../assets/buyers/kolkata.png"),
        city:"Kolkata",
        serviceStatus:"Coming soon"
    },
    {
        id:5,
        img:require("../../../assets/buyers/hyderabad.png"),
        city:"Hyderabad",
        serviceStatus:"Coming soon"
    },
    {
        id:6,
        img:require("../../../assets/buyers/pune.png"),
        city:"Pune",
        serviceStatus:"Coming soon"
    },
    {
        id:7,
        img:require("../../../assets/buyers/ahmedabad.png"),
        city:"Ahmedabad",
        serviceStatus:"Coming soon"
    }
]

const ServicableCity = () => {

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const scrollViewRef = useRef(null);
    const width = Dimensions.get("window")
    
    const [cardState,setCardState] = useState({currentCard:0})

    const {currentCard:pageIndex} = cardState

    const toNextView = () =>{
            scrollViewRef.current.scrollTo({
                // screenX:width*(pageIndex-4)*-1,
                x:1700,
                animated:true,
            })
    }

    const toBackView = () => {
        if(scrollViewRef.current !== null){
            scrollViewRef.current.scrollTo({
                x:width*(pageIndex+1),
                animated:true,
            })
        }
    }


    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:450
    })

    if(isMobileDisplay){
        return(
            <View style={servicableStyle.citiesContainerMobile}>
                <Text style={servicableStyle.topTextMobile}>Serviceable Cities</Text>
                <View style={servicableStyle.cityCardContainerMobile}>
                <ScrollView
                    horizontal={true}
                    showsVerticalScrollIndicator={false}
                    style={servicableStyle.cityCardContainerMobile}
                    ref={scrollViewRef}
                >
                    {cities.map(city=> {
                        return <View style={servicableStyle.cityCardMobile}>
                                <TouchableOpacity onPress={()=>Linking.openURL(customerPlayStore)}>
                                    <Image
                                        source={city.img}
                                        style={servicableStyle.cityImgMobile}
                                    />
                                </TouchableOpacity>
                                    <View style={servicableStyle.locationViewMobile}>
                                        <Text style={servicableStyle.cityTextMobile}>{city.city}</Text>
                                        {"\n"}
                                        <Text nativeID='orderInSeconds' style={servicableStyle.statusTextMobile}>{city.serviceStatus}</Text>
                                    </View>
                            </View>
                    })}

                        <TouchableOpacity style={servicableStyle.knowMore} onPress={()=>Linking.openURL(customerPlayStore)}>
                            <Image style={servicableStyle.cityImgMobileKnowMore} source={require("../../../assets/common_assets/dottedCard.png")}/>
                        </TouchableOpacity>
                </ScrollView>
            </View>
            </View>
        )
    }

///Desktop View
    return (
        <View>
            <View style={servicableStyle.citiesContainer}>
                <View style={servicableStyle.topContainer}>
                <Text style={servicableStyle.topText}>Serviceable Cities</Text>
                    <View style={servicableStyle.navigatorContainer}>
                        <TouchableOpacity
                            onPress={()=>toBackView()}
                            style={segmentStyle.opacityElement}
                        >
                            <Image 
                                source={require("../../../assets/common_assets/backward.png")}
                                style={segmentStyle.greyArrowImage}
                            />
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={()=>toNextView()}
                            style={segmentStyle.opacityElement}
                        >
                            <Image
                                source={require("../../../assets/common_assets/forward.png")}
                                onPress={() => source={backwardArrow}}
                                style={segmentStyle.redArrowImage}
                                // onProgress={()=>{this.scrollview.scrollToEnd({x:screenWidth})}}
                            />
                        </TouchableOpacity>
                     </View>
                </View>

            <View style={servicableStyle.cityCardContainer}>
                <ScrollView
                    horizontal={true}
                    showsVerticalScrollIndicator={false}
                    style={servicableStyle.cityCardContainer}
                    ref={scrollViewRef}
                >
                    {cities.map(city=> {
                        return <View style={servicableStyle.cityCard}>
                                    <TouchableOpacity onPress={()=>Linking.openURL(customerPlayStore)}>
                                        <Image
                                            source={city.img}
                                            style={servicableStyle.cityImg}
                                        />
                                    </TouchableOpacity>
                                    <View style={servicableStyle.locationView}>
                                        <Text style={servicableStyle.cityText}>{city.city}</Text>
                                        {"\n"}
                                        <Text nativeID='orderInSeconds' style={servicableStyle.statusText}>{city.serviceStatus}</Text>
                                    </View>
                            </View>
                    })}

                    <TouchableOpacity style={servicableStyle.knowMore} onPress={()=>Linking.openURL(customerPlayStore)}>
                        <Image style={servicableStyle.cityImgKnowMore} source={require("../../../assets/common_assets/dottedCard.png")}/>
                    </TouchableOpacity>
                </ScrollView>
            </View>
            </View>
        </View>
    )
}

export default ServicableCity
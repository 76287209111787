import * as React from 'react'
import { useMediaQuery } from 'react-responsive'
import {buttonStyle} from './buttonStyle'
import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

import {
    View,
    Text,
    Linking,
    TouchableOpacity
} from 'react-native'

const CustomButton = (props) => {
    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:450
    })

    if(isMobileDisplay){
        return (
            <View style={buttonStyle.buttonContainerMobile}>
            <TouchableOpacity onPress={()=>Linking.openURL(props.buttonLink)}>
                <Text style={buttonStyle.buttonTextMobile}>{props.buttonText}</Text>
            </TouchableOpacity>
        </View>
        )
    }

    return (
        <View style={buttonStyle.buttonContainer}>
            <TouchableOpacity onPress={()=>Linking.openURL(props.buttonLink)}>
                <Text style={buttonStyle.buttonText}>{props.buttonText}</Text>
            </TouchableOpacity>
        </View>
    )
}

export default CustomButton
import * as React from 'react'
import {View,Text} from 'react-native'


// import Home from "../components/sellerComponents/Home/index"
import TopNavbar from '../components/commonComponents/TopNav/index'
import BottomNavbar from '../components/commonComponents/BottomNav/index'
import { sellerLinks } from '../components/commonComponents/BottomNav/data'
import Home from '../components/sellerComponents/Home/index'
import SellerBanner from './../components/sellerComponents/BannerComponent/index';
import RequestCall from '../components/commonComponents/RequestCallBack/index'

import More from "../components/sellerComponents/MoreComponent/index"
import SellingAtoZ from '../components/sellerComponents/SellingAtoZ/index';
import ProudSellers from '../components/sellerComponents/ProudSellers/index';
import AskedQuestion from "../components/commonComponents/AskedQuestion/index"
import {sellerQuestion} from '../components/commonComponents/AskedQuestion/data'
import Footer from '../components/commonComponents/Footer/index'
import OrderInSecond from './../components/commonComponents/OrderInSecond/index';
import { sellerCardData } from './../components/commonComponents/OrderInSecond/data';

const  sellerQr = require("../assets/sellers/sellerQr.png")
const sellerAppLink  = "https://play.google.com/store/apps/details?id=com.ishopatoz.android.vendor.release"
import GoToTop from '../components/commonComponents/GoToTop'

 const SellerPage = () => {
    return(
        <View>
            <TopNavbar/>
            <BottomNavbar links={sellerLinks}/> 
            <Home/>
            <GoToTop/>
            <SellerBanner/>
            {/* <RequestCall/> */}
            <More/>           
            <ProudSellers/>
            <SellingAtoZ/>
            <OrderInSecond 
                text="Get orders online in just 3 steps!"
                cardData ={sellerCardData}
                qrImage={sellerQr}
                appStoreUrl={sellerAppLink}
                appText="Download the seller app now"
            />
            <AskedQuestion data={sellerQuestion}/>
            <Footer
                contactMailLink="seller@ishopatoz.com"
                contactMailText="seller@ishopatoz.com"
                appUrl={sellerAppLink}
                downloadText="Download the seller app now"
            />
        </View>
    )
}

export default SellerPage
export const buyerLinks = [
    {id:0,link:"#highlights",text:"Highlights"},
    {id:1,link:"#whyIshop",text:"Why iShopAtoZ?"},
    {id:2,link:"#categories",text:"The Categories"},
    {id:3,link:"#testimonials",text:"Testimonials"},
    {id:4,link:"#serviceableCities",text:"Serviceable Cities"},
    {id:5,link:"#orderInSeconds",text:"Do Order in Seconds"},
    {id:6,link:"#faq",text:"FAQ"},
    {id:7,link:"#contact",text:"Contact Us"}
]


export const sellerLinks = [
    {id:0,link:"#highlights",text:"Highlights"},
    {id:1,link:"#sellerWhyUs",text:"Why iShopAtoZ?"},
    {id:2,link:"#testimonials",text:"Testimonials"},
    {id:3,link:"#sellingAtoZ",text:"Selling AtoZ"},
    {id:4,link:"#orderInSeconds",text:"Get orders online"},
    {id:5,link:"#faq",text:"FAQ"},
    {id:6,link:"#contact",text:"Contact Us"}
]

export const deliveryLinks = [
    {id:0,link:"#highlights",text:"Highlights"},
    {id:1,link:"#whyUs",text:"Why Us?"},
    {id:2,link:"#testimonials",text:"Testimonials"},
    {id:3,link:"#orderInSecondsdel",text:"Join Us"},
    {id:4,link:"#faq",text:"FAQ"},
    {id:5,link:"#contact",text:"Contact Us"}
]

export const contactUsLinks = [
    {id:0,link:"#highlights",text:"Highlights"},
    {id:1,link:"#story",text:"Our Story"},,
    {id:2,link:"#mission",text:"Our Mission"},
    // {link:"ishopAtoZ journey",text:"The iShopAtoZ Journey"},
    {link:"#theteam",text:"The Team"},
    {id:3,link:"#socialConnect",text:"Social Connect"},
    {id:4,link:"#contact",text:"Contact Us"}
]
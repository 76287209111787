import {StyleSheet} from 'react-native'
import {Raleway, Raleway_500Medium, Raleway_600SemiBold, Raleway_800ExtraBold} from '@expo-google-fonts/raleway'

export const homeStyle  = StyleSheet.create({
    homeContainer:{
        display:"flex",
        flexDirection:"row",
        width:"100%",
        marginTop:"10.509vh",
        height:"90vh",
        // margin: "5%",
        padding: "5%",
        // marginLeft:"auto",
        // marginRight:"auto",
        textAlign:"center",
        backgroundColor:"#FFFFFF",
        maxWidth:"100%",
        //backgroundColor:"#CCCCCC"
    },

    leftContainer:{
        // width:"50%",
        // margin:"4%",
        alignItem:"center",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        maxWidth:"100%",
        marginLeft:"5vw",
        //marginTop:"15vh",
        //backgroundColor:"#CCCCCC"
    },

    rightContainer:{
        // width:"50%",
        alignItem:"center",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"5vh",
        //maxWidth:"100%",
        //marginTop:"15vh",
        //marginRight:"20vw",
        //width:"32vw",
        //backgroundColor:"#EEEEEE"
    },

    topLeftText:{
        //width: "57.715vw",
        width: "42vw",
        height: "15.390vh",
        left: "13.828vw",
        top: "34.154vh",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "3.00vw",
        lineHeight: "7.1954vh",
        color: "#2C2E2F",
        textAlign:"left",
        marginTop:"5vh",
        //backgroundColor:"#AAAAAA"

    },

    leftRedText:{
        //width: "56.016vw",
        width: "40vw",
        height: "9.1954vh",
        left: "12.5vw",
        top: "53.859vh",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "3.00vw",
        lineHeight: "9.195vh",
        // color: "#FF002A",
        color:"#008489",
        textAlign:"left",
        marginTop:"2.135vh",

    },

    leftLightText:{
        //width: "44.297vw",
        width: "40vw",
        height: "7.553vh",
        left: "12.5vw",
        top: "66.995vh",
        fontFamily: 'Raleway_500Medium',
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "1.25vw",
        lineHeight: "3.777vh",
        color: "#2C2E2F",
        textAlign:"left",
        marginTop:"2.135vh"

    },

    textHighlight:{
        //color:"#008489",
        color:"#2C2E2F",
        // background: #2C2E2F;
        fontFamily: 'Raleway_800ExtraBold',
        fontWeight: 'bold'
    },

    

    deliveryGirl:{
        // width: "602px",
        // height: "744px",
        height:"60vh",
        width:"30.07vw",
        // top: "74px",
        resizeMode:"cover",
        // borderRadius:"",
        borderTopLeftRadius:"3.75vw",
        borderTopRightRadius: "3.75vw",
        borderBottomRightRadius: "3.75vw",
        // borderBottomLeftRadius:"280px 40%",
        // borderBottomLeftRadius:"200px",
        // marginBotoomLeft:"20px",
        overflow:"hidden"
    },

    lineView:{
        // width: "153px",
        width:"10.15625vw",
        height: "0px",
        // left: 1062px"
        top: "-4.105vh",
        border: "0.156vw solid #FF002A",
        borderRadius: "3.75vw",
        // transform: "rotate(26.86deg)",
        transform: "rotate(30.86deg)"

    },
    requestCallButton:{
        width: "10.675vw",
        height: "5.389vh",
        //backgroundColor: "#008489",
        // backgroundColor: "#28785A",
        backgroundColor:"#B3E6E8",
        borderRadius: "0.625vw",
        alignItems:"center",
        justifyContent:"center",
        marginTop:"4.105vh"
    },

    requestCallText:{
        width: "9.15vw",
        height: "2vh",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "0.9vw",
        lineHeight: "2.28vh",
        /* identical to box height */
        // color: "#FFFFFF",
        // color: "#CCCCCC"
        color:"#008489",
        alignItems:"center"
    },

    qrContainer:{
        marginTop:"2.956vh"
    },

    homeContainerMobile:{
        width:"100%",
        //height:"130vh",
        //height:"920px",
        marginTop:"64px",
        //maxHeight:"100%",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        backgroundColor:"#FFFFFF",
       
    },

    ///Responsive mobile CSS
    topBoldTextMobile:{
        // position: absolute;
        width: "100%",
        height: "126px",
        marginTop: "20px",

        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "32px",
        lineHeight: "130%",
        /* or 42px */
        textAlign: "center",
        color: "#2C2E2F",
        marginLeft:"auto",
        marginRight:"auto",

    },

    topBoldTextMobileBold:{
        // position: absolute;
        width: "100%",
        height: "126px",
        marginTop: "20px",

        fontFamily: 'Raleway_800ExtraBold',
        fontStyle: "normal",
        fontWeight: 800,
        fontSize: "32px",
        lineHeight: "130%",
        /* or 42px */
        textAlign: "center",
        color: "#2C2E2F",
        marginLeft:"auto",
        marginRight:"auto",

    },

    redTextMobile:{
        // position: "absolute",
        width: "297px",
        height: "28px",
        marginTop: "20px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "28px",
        textAlign: "center",
        marginLeft:"auto",
        marginRight:"auto",
        // color: "#FF002A"
        color:"#008489",
    },

    lightTextMobile:{
        width: "298px",
        height: "42px",
        marginTop: "25px",
        fontFamily: 'Raleway_500Medium',
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "21px",
        textAlign: "center",
        marginRight:"auto",
        marginLeft:"auto",
        color: "#2C2E2F",

    },

    requestCallButtonMobile:{
        width: "138px",
        height: "36px",
        backgroundColor:"#B3E6E8",
        borderRadius: "6.42px",
        alignItems:"center",
        justifyContent:"center",
        marginTop:"20px",
        marginLeft:"auto",
        marginRight:"auto",
        marginBottom:"0px"
    },

    requestCallTextMobile:{
        width: "121px",
        height: "15px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "12.85px",
        lineHeight: "15px",
        /* identical to box height */
        color:"#008489"

    },

    playStoreContainerMobile:{
        width:"100%",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"0px",
        marginBottom:"10px"
    },

    bannerContainerMobile:{
        width:"100%",
        height:"10vh",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center"
    },
    
    bannerImageMobile:{
        width:"100%",
        // width:"1458px",
        // width:"90vw",
        height:"92.7vh",
        marginLeft:"auto",
        marginRight:"auto",
        marginTop:"-60vh",
        resizeMode:"contain"
    },

    deliveryGirlMobile:{
        // width: "602px",
        // height: "744px",
        height:"250px",
        width:"300px",
        // top: "74px",
        resizeMode:"cover",
        // borderRadius:"",
        borderTopLeftRadius:"20px",
        borderTopRightRadius: "20px",
        borderBottomRightRadius: "20px",
        // borderBottomLeftRadius:"280px 40%",
        // borderBottomLeftRadius:"200px",
        // marginBotoomLeft:"20px",
        overflow:"hidden"
    },

    deliveryGirlContainerMobile:{
        // width:"50%",
        alignItem:"center",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        maxWidth:"100%",
        marginTop:"25px"
    },

    qrIconMobile:{
        resizeMode:"contain",
        height:100,
        width:100,
        alignItems:"center",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"0"
    },
})
import {StyleSheet} from 'react-native'
import {Raleway, Raleway_500Medium, Raleway_600SemiBold} from '@expo-google-fonts/raleway'

export const bottomNavStyles = StyleSheet.create({
    navContainer:{
        position: "fixed",
        width: "100%",
        // height: "6.568vh",
        height:"48px",
        left: "0px",
        // top: "10.509vh",
        marginTop:"64px",
        backgroundColor: "#FFFFFF",
        opacity: "0.9",
        display:"flex",
        flex: "1",
        flexDirection:"row",
        marginLeft:"auto",
        marginRight:"auto",
        alignItems:"center",
        zIndex:"10"
        
    },
    displayNone:{
        display:"none"
    },

    linkStyles:{
        marginLeft:"auto",
        marginRight:"auto",
        alignItems:"center",
        position: "static",
        width: "11.719vw",
        height: "2.627vh",
        // left: "124px",
        top: "0.657vh",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "1.09375vw",
        lineHeight: "2.627vh",
        textAlign: "center",
        color: "#2C2E2F",
        flex:"none",
        order:5,
        flexGrow:0,
        margin:"0vw 1.875vw"
    },
    hover:{
        backgroundColor: "#008489",
        borderRadius: "1.25vw",
    },

    linkContainer:{
        display:"flex",
        // width:"65%",
        marginLeft:"auto",
        marginRight:"auto",
        alignItems:"center",
        flexDirection:"row",
    },
    aStyle:{
        // textDecoration:"none",
        color: "#2C2E2F" ,
    },

    activeStyleText:{
        marginLeft:"auto",
        marginRight:"auto",
        alignItems:"center",
        position: "static",
        width: "11.719vw",
        height: "2.627vh",
        // left: "124px",
        top: "0.657vh",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "1.09375vw",
        lineHeight: "2.627vh",
        textAlign: "center",
        color: "#FF002A",
        flex:"none",
        order:5,
        flexGrow:0,
        margin:"0vw 1.875vw"
    },
    opacityContainer:{
        justifyContent: "center",
        alignItems: "center",
        padding: "4px 16px",
        position: "static",
        width: "200px",
        height: "36px",
        backgroundColor: "#008489",
        borderRadius: "16px",
        /* Inside Auto Layout */
        flex: "none",
        order: 0,
        flexGrow: 0,
        margin: "0px 24px"
    },


    //Responsive UI
    navContainerMobile:{
        width: "100%",
        height: "64px",
        top: "64px",
        backgroundColor: "#FFFFFF",
        marginLeft:"auto",
        marginRight:"auto",
        alignItems:"center",
        textAlign:"center",
        borderTopColor:"#EEEEEE",
        borderTopWidth:"0.2vh"
        
    },

    navSubContainerMob:{
        width:"60vw",
        display:"flex",
        flexDirection:"row",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        alignItems:"center",
        justifyContent:"center",
        height:"64px"
    },
    flag:{
        resizeMode:"contain",
        width:"20px",
        height:"12px",
        textAlign:"left",
        marginRight:"5px",
        alignItems:"center",
    },
    madeInIndiaText:{
        fontFamily: 'Raleway_500Medium',
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "180%",
        maxWidth:"100%",
        textAlign: "center",
        /* Charcoal-Text */
        color: "#2C2E2F",
        marginLeft:"auto",
        whiteSpace: "nowrap",

    }
})
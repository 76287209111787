import {StyleSheet} from 'react-native'
import {Raleway, Raleway_500Medium, Raleway_600SemiBold, Raleway_800ExtraBold} from '@expo-google-fonts/raleway'

export const sellerHomeStyle = StyleSheet.create({
    homeContainer:{
        width: "100%",
        height:"65vh",
        left: "0px",
        marginTop: "10.509vh",
        backgroundColor:"#E6F3F4",
        display:"flex",
        flexDirection:"row",
    },
    leftContainer:{
        marginTop:"15vh",
        marginLeft:"10vw",
        // width:"35%"
    },
    topBoldText:{
        // width: "738.75px",
        height: "15.391vh",
        left: "0px",
        top: "0px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "3.00vw",
        lineHeight: "7.1954vh",
        // color: "#FFFFFF",
        color:"#2C2E2F"

    },

    boldSubText:{
        fontFamily: 'Raleway_800ExtraBold',
        fontStyle: "normal",
        fontWeight: "800",
        fontSize: "3.00vw",
        lineHeight: "7.1954vh",
        // color: "#FFFFFF",
        color:"#2C2E2F"
    },
    boldRedText:{
        // width: "717px",
        height: "3.777vh",
        left: "0px",
        // top: 136px;
        marginTop:"4.105vh",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "3.00vw",
        lineHeight: "3.777vh",
        // color: "#FF002A"
        color:"#008489"

    },  
    topLightText:{
        // width: "567px",
        height: "3.777vh",
        left: "0px",
        marginTop: "5.105vh",
        fontFamily: 'Raleway_500Medium',
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "1.25vw",
        lineHeight: "3.777vh",
        color: "#2C2E2F",
        // color:"#FF002A"
    },

    qrContainer:{
        alignItems:"baseline",
        marginTop:"20vh",
        // width:"35%"
        marginLeft:"5vw"
    },

    requestCallButton:{
        width: "10.675vw",
        height: "5.389vh",
        //backgroundColor: "#008489",
        // backgroundColor: "#28785A",
        backgroundColor:"#B3E6E8",
        borderRadius: "0.625vw",
        alignItems:"center",
        justifyContent:"center",
        marginTop:"4.105vh",
        // marginLeft:"19.5vw"
    },

    requestCallText:{
        width: "9.15vw",
        height: "2vh",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "0.9vw",
        lineHeight: "2.28vh",
        /* identical to box height */
        // color: "#FFFFFF",
        // color: "#CCCCCC"
        color:"#008489",
        alignItems:"center"
    },

    requestCallTextMobile:{
        width: "121px",
        height: "15px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "12.85px",
        lineHeight: "15px",
        /* identical to box height */
        color:"#008489"

    },

    
    ///Responsive mobile CSS   
    topBoldTextMobile:{
        // position: absolute;
        width: "100%",
        height: "126px",
        marginTop: "20px",

        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "32px",
        lineHeight: "130%",
        textAlign: "center",
        color: "#2C2E2F",
        marginLeft:"auto",
        marginRight:"auto",

    },

    topBoldTextMobileBold:{
        // position: absolute;
        width: "100%",
        height: "126px",
        marginTop: "20px",

        fontFamily: 'Raleway_800ExtraBold',
        fontStyle: "normal",
        fontWeight: 800,
        fontSize: "32px",
        lineHeight: "130%",
        textAlign: "center",
        color: "#2C2E2F",
        marginLeft:"auto",
        marginRight:"auto",

    },

    homeContainerMobile:{
        width:"100%",
        //height:"113vh",
        //height:"640px",
        //height:"150vh",
        marginTop:"64px",
        maxHeight:"100%",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        backgroundColor:"#E6F3F4",
       
    },

    redTextMobile:{
        // position: "absolute",
        width: "297px",
        height: "28px",
        marginTop: "20px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "28px",
        textAlign: "center",
        marginLeft:"auto",
        marginRight:"auto",
        // color: "#FF002A"
        color:"#008489"
    },
    lightTextMobile:{
        width: "298px",
        height: "42px",
        marginTop: "25px",
        fontFamily: 'Raleway_500Medium',
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "21px",
        textAlign: "center",
        marginRight:"auto",
        marginLeft:"auto",
        color: "#2C2E2F",

    },
    playStoreContainer:{
        width:"100%",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center"
    },

    mobileBanner:{
        width: "100%",
        height: "216px",
        resizeMode:"cover",
        marginTop:"20px"
    },

    requestCallButtonMobile:{
        width: "138px",
        height: "36px",
        backgroundColor:"#B3E6E8",
        borderRadius: "6.42px",
        alignItems:"center",
        justifyContent:"center",
        marginTop:"20px",
        marginLeft:"auto",
        marginRight:"auto",
        marginBottom:"10px"
    },
    playStoreContainerMobile:{
        width:"100%",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        //marginTop:"10px",
        //ackgroundColor: "#F2F9F9",
    },

    appLinkContainerMobile:{
        justifyContent: "center",
        alignItems: "center",
        // padding: "16px",
        height:"100px",
        width:"240px",
        //backgroundColor: "#F2F9F9",
        borderRadius: "10px",
        // marginTop:"151px",
        marginLeft:"auto",
        marginRight:"auto",
        marginBottom:"10px"
    },

    qrIconMobile:{
        resizeMode:"contain",
        height:100,
        width:100,
        alignItems:"center",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"0"
    },

})
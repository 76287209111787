import * as React from 'react'
import {
    ImageBackground,
    Text,
    View,
    Image,
    TouchableOpacity,
    Linking
} from 'react-native'
import {sellerHomeStyle} from "./homeStyles"
import { useMediaQuery } from 'react-responsive';
import PlayStore from '../../commonComponents/mobilePlayStore';
import QrElement from "../../commonComponents/QrComponent/index"
import MobileQrComponent from '../../commonComponents/QrComponentMobile';
import IconButton from '../../../storybook/component/play_button';

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

const  sellerQr = require("../../../assets/sellers/sellerQr.png")
const sellerAppLink = "https://play.google.com/store/apps/details?id=com.ishopatoz.android.vendor.release"

const Home = () =>{

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:450
    })

    if(isMobileDisplay){
        return(
            <View style={sellerHomeStyle.homeContainerMobile}>
                <Text style={sellerHomeStyle.topBoldTextMobile}>Want to <Text style={sellerHomeStyle.topBoldTextMobileBold}>Grow</Text> your{"\n"}Business with <Text style={sellerHomeStyle.topBoldTextMobileBold}>0% {"\n"} Commission</Text>?</Text>
                <Text style={sellerHomeStyle.redTextMobile}>You are at the right place!</Text>
                <Text style={sellerHomeStyle.lightTextMobile}>Upgrade to digital shops & grow {"\n"}your business</Text>
                <View style={sellerHomeStyle.playStoreContainerMobile}>

                    {/*<PlayStore*/}
                    {/*    url={sellerAppLink}*/}
                    {/*    qrImage={sellerQr}*/}
                    {/*    qrMessage="Download the seller app now"*/}
                    {/*/>*/}
                    <IconButton
                        onPress={() => Linking.openURL(sellerAppLink)}
                        qrImage={sellerQr}
                        qrmess={"Download the seller app now"}
                        styles={{
                            qrContainer: {
                                display: "flex",
                                flexDirection: "colum",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "0px",
                                // position: "absolute",
                                width: "202.76px",
                                height: "237px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                // top: "376px",
                                // backgroundColor: "#F2F9F9",
                                //backgroundColor:"#FFFFFF",
                                backgroundColor: "#F5FAFA",
                                borderRadius: "25px",
                                marginLeft: "auto",
                                // opacity:"0.6",
                                marginRight: "auto",
                                //marginTop:"60px"
                                marginTop: "30px"
                            },
                            googlePlayImg: {
                                width: "168px",
                                height: "42px",
                                // left: "36px"
                                top: "11px",
                                background: "#000000",
                                borderRadius: "24px",
                                resizeMode: "contain"
                            },
                            lightText: {
                                width: "150px",
                                height: "50px",
                                // left: 1px;
                                // top: 27px;

                                /* Small */
                                fontFamily: 'Raleway_600SemiBold',
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontSize: "14px",
                                lineHeight: "180%",
                                /* or 25px */
                                textAlign: "center",
                                /* Charcoal-Text */
                                color: "#2C2E2F",
                                //backgroundColor:"#CCCCCC"
                            },
                            qrStyle: {
                                width: "102.84px",
                                height: "102.84px",
                                marginTop: "20px",
                                backgroundColor: "#FFFFFF",
                                boxShadow: "0px 0.803417px 0.803417px rgba(0, 0, 0, 0.25)",
                                resizeMode: "contain"
                            }
                        }}
                    />
                    {/* <View style={sellerHomeStyle.appLinkContainerMobile}>
                        <Image 
                            source={sellerQr}
                            style={sellerHomeStyle.qrIconMobile}
                        />
                    </View> */}
                    {/*
                    <MobileQrComponent
                        qrImage={sellerQr}
                        playStore={sellerAppLink}
                    />
                    */}
                </View>
                
                <TouchableOpacity
                    style={sellerHomeStyle.requestCallButtonMobile}
                    onPress={()=>Linking.openURL("https://forms.gle/HZLKBiQ6MbwGRmLL7")}
                >
                    <Text style={sellerHomeStyle.requestCallTextMobile}>Request a Call Back</Text>
                </TouchableOpacity>               
            </View>
        )
    }

    return (
        <View style={sellerHomeStyle.homeContainer} nativeID='highlights'>
            <View style={sellerHomeStyle.leftContainer}>
                <Text style={sellerHomeStyle.topBoldText}>Want to <Text style={sellerHomeStyle.boldSubText}>Grow</Text> your Business {"\n"}with<Text style={sellerHomeStyle.boldSubText}> 0% Commission?</Text></Text>
                <Text style={sellerHomeStyle.boldRedText}>You are at the right place!</Text>
                <Text style={sellerHomeStyle.topLightText}>Upgrade to digital shops & grow your business</Text>
                <TouchableOpacity
                    style={sellerHomeStyle.requestCallButton}
                    onPress={()=>Linking.openURL("https://forms.gle/HZLKBiQ6MbwGRmLL7")}
                >
                    <Text style={sellerHomeStyle.requestCallText}>&nbsp;&nbsp;Request a Call Back</Text>
                </TouchableOpacity>
            </View>
            <View style={sellerHomeStyle.qrContainer}>
                {/*<QrElement*/}
                {/*    qrImage = {sellerQr}*/}
                {/*    playStoreLink={sellerAppLink}*/}
                {/*    qrMessage="Download the seller app now"*/}
                {/*/>*/}
                <IconButton
                    onPress={() => Linking.openURL(sellerAppLink)}
                    qrImage={sellerQr}
                    qrmess={"Download the seller app now"}
                    styles={{
                        qrContainer: {
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "10%",
                            // width: "414px",
                            width: " 27.30vw",
                            height: "21.90vh",
                            backgroundColor: "#FFFFFF",
                            borderRadius: "16px",
                        },
                        button: {
                            width: "12.29vw",
                            height: "6.397vh",
                            top: "0.985vh",
                            background: "#000000",
                            borderRadius: "1.875vw",
                            resizeMode: "contain",
                        },
                        googlePlayImg: {
                            width: "12.29vw",
                            height: "6.397vh",
                            top: "0.985vh",
                            background: "#000000",
                            borderRadius: "1.875vw",
                            resizeMode: "contain",
                        },

                        lightText: {
                            width: "18.594vw",
                            // width:"17.42vw",
                            height: "4.240vh",
                            left: "1.875vw",
                            textAlign: "left",
                            // top: "30px",
                            fontFamily: 'Raleway_600SemiBold',
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "1.093vw",
                            lineHeight: "3.120vh",
                            color: "#2C2E2F",
                        },
                        qrStyle: {
                            width: "100px",
                            height: "100px",
                            background: "#FFFFFF",
                        }
                    }}
                />
            </View>
        </View>
    )
}

export default Home

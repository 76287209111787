import {StyleSheet} from 'react-native'
import {Raleway, Raleway_400Regular, Raleway_600SemiBold} from '@expo-google-fonts/raleway'

export const socialConnectStyle  = StyleSheet.create({
    socialContainer : {
        width:"100%",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"6vh"
    },

    socialCardContainer:{
        width:"100%",
        display:"flex",
        flexDirection:"row",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        height: "35vh",
        backgroundColor:"#F4F5F5",
        maxWidth:"100%",
        alignItems:"center",
        marginTop:"3vh"

    },

    socialSubContainer:{
        width:"70vw",
        display:"flex",
        flexDirection:"row",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center"
    },

    topBoldText:{
        width: " 25.79vw",
        height: "7.718vh",

        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "3.125vw",
        lineHeight: "7.718vh",
        /* identical to box height */
        color: "#2C2E2F",
        textAlign:"center",
        marginLeft:"auto",
        marginRight:"auto",

    },

    socialCard:{
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        alignItems:"center",
        width:"20vw",    
    },

    socialImg:{
        width: "5vw",
        height: "10.509vh",
        // left: "21px"
        // top: 0px;
        resizeMode:"stretch"
    },

    followerText:{
        width:"6.484vw",
        height: "9.195vh",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "3.75vw",
        lineHeight: "9.195vh",
        color: "#2C2E2F",
        // marginTop:"2vh",
        marginTop:"2.135vh",
        display:"none"

    },

    idText:{
        // width: "174px",
        width:"100%",
        height: "3.777vh",
        // left: "362px"
        // top: "151.87px"
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "1.875vw",
        lineHeight: "3.777vh",
        color: "#2C2E2F",
        opacity: "0.75",
        textAlign:"center",
        marginTop:"4vh"

    },

    socialContainerMobile:{
        width:"100%",
        height:"360vh",
        marginTop:"130px",
        maxHeight:"100%",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        backgroundColor:"#FFFFFF",
       
    },

    topBoldTextMobile:{
        // position: absolute;
        width: "100%",
        height: "60px",
        top: "130px",

        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "130%",
        /* or 42px */
        textAlign: "center",
        color: "#2C2E2F",
        marginLeft:"auto",
        marginRight:"auto",

    },

    socialCardContainerMobile:{
        width:"100%",
        display:"flex",
        flexDirection:"column",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        height: "400px",
        backgroundColor:"#F4F5F5",
        maxWidth:"100%",
        alignItems:"center",
        //marginTop:"0"

    },

    socialSubContainerMobile:{
        width:"70vw",
        display:"flex",
        flexDirection:"column",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"40px",
        marginBottom:"10px"
    },

    socialCardMobile:{
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        alignItems:"center",
        width:"40vw",    
    },

    socialImgMobile:{
        width: "55px",
        height: "55px",
        // left: "21px"
        // top: 0px;
        resizeMode:"contain",
        marginBottom:"5px"
    },

    followerTextMobile:{
        width:"83px",
        height: "56px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "48px",
        lineHeight: "56px",
        color: "#2C2E2F",
        // marginTop:"2vh",
        marginTop:"13px",
        display:"none"

    },

    idTextMobile:{
        // width: "174px",
        width:"100%",
        height: "23px",
        // left: "362px"
        // top: "151.87px"
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "15px",
        lineHeight: "23px",
        color: "#2C2E2F",
        opacity: "0.75",
        textAlign:"center",
        marginTop:"5px",
        marginBottom:"25px"

    },

})
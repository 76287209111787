import * as React from 'react'
import {View} from 'react-native'

import TopNavbar from '../components/commonComponents/TopNav/index';
import BottomNavbar from '../components/commonComponents/BottomNav/index'
import { buyerLinks } from '../components/commonComponents/BottomNav/data';
// import CenterRectangle from "../components/buyerComponents/Highlights/index"
import Benefits from "../components/buyerComponents/Benefits/index"
import Testimonials from '../components/buyerComponents/Testimonials/index'
import ServicableCity from "../components/buyerComponents/ServicableCities/index"
import OrderInSecond from "../components/commonComponents/OrderInSecond/index"
import Home from "../components/buyerComponents/Home/index"
// import Categories from "../components/buyerComponents/Categories/index"
import Footer from "../components/commonComponents/Footer/index"
import { buyerCardData } from '../components/commonComponents/OrderInSecond/data';
import Categories from './../components/buyerComponents/Categories/index';
const buyerQr = require("../assets/buyers/google_play_qr.png")
const shoppingApp  = 'https://play.google.com/store/apps/details?id=com.ishopatoz.android.customer.release'
import FlexComponent from '../components/commonComponents/FeatureFlex';
// import { buyerFlexData } from '../components/commonComponents/FeatureFlex/data';
import { buyerFlexData } from './../components/commonComponents/FeatureFlex/data';
import AskedQuestion from '../components/commonComponents/AskedQuestion/index';
import { buyerQuestion } from './../components/commonComponents/AskedQuestion/data';
import GoToTop from '../components/commonComponents/GoToTop/index';
 const BuyerPage = () => {
    return (
        <View>
            <TopNavbar/>
            <BottomNavbar links={buyerLinks}/>       
            <Home/>      
            <GoToTop/>
            <FlexComponent 
                topText = "We have the features that every shoppers look for"
                flexData={buyerFlexData}
            />      
            <Benefits/>     
            <Categories/>     
            <Testimonials/>    
            <ServicableCity/>    
            <OrderInSecond 
                text="Do order in Seconds"
                cardData = {buyerCardData}
                qrImage={buyerQr}
                appStoreUrl={shoppingApp}
                appText="Download the app now"
                />    
             
            <AskedQuestion
                data={buyerQuestion}
            />
              
            <Footer
                contactMailLink="contact@ishopatoz.com"
                contactMailText="contact@ishopatoz.com"
                appUrl={shoppingApp}
                downloadText="Download the app now"
            />    
        </View>
    )
}

export default BuyerPage
import * as React from 'react'

import {
    View,
    Text,
    Image,
    ScrollView,
    TouchableOpacity,
    Dimensions,
    Button,
    ImageBackground,
    Linking
} from 'react-native'
import { useState,useRef } from 'react'
import {sellingStyle} from './sellingStyles'
import { useMediaQuery } from 'react-responsive';
//import {segmentStyle} from './segmentStyle'

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

let backwardArrow = require("../../../assets/common_assets/back_arrow.png")
let forwardArrow = require("../../../assets/common_assets/forward_arrow.png")

const sellerAppPlayStore = "https://play.google.com/store/apps/details?id=com.ishopatoz.android.vendor.release"


const cardImages = [
                    {
                        id:1,
                        img:require("../../../assets/buyers/grocery.png"),
                        title:"Grocery",
                        text:"Fruits & Vegetables, Dairy, Breads, Beverages, etc."

                    },
                    {
                        id:2,
                        img:require("../../../assets/buyers/food.png"),
                        title:"Food & Restaurant",
                        text:"Breakfast Food, Burgers, Meat & Seafood, etc."
                    },
                    {
                        id:3,
                        img:require("../../../assets/buyers/beauty.png"),
                        title:"Beauty & Salons",
                        text:"Hair-cutting, Colouring, Facials, Massages, etc."
                       
                    },
                    {
                        id:4,
                        img:require("../../../assets/buyers/health.png"),
                        title:"Health",
                        text:"Medicine, Dentistry, Eye Care, Diagnostic & Test Labs, etc."
                    },
                    {
                        id:5,
                        img:require("../../../assets/buyers/fitness.png"),
                        title:"Fitness & Gym",
                        text:"Physical Fitness, Exercise Equipment, etc."
                    },
                    {
                        id:6,
                        img:require("../../../assets/buyers/electronics.png"),
                        title:"Electronics",
                        text:"Mobiles &, Computers, Cameras, TV, etc."
                    },
                    {
                        id:7,
                        img:require("../../../assets/buyers/kitchen.png"),
                        title:"Home & Kitchen Appliances",
                        text:"Microwave, Pressure cooker, Mixer Grinder, etc."
                    }
]

const SellingAtoZ = () => {

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const scrollViewRef = useRef(null);
    const width = Dimensions.get("window")
    
    const [cardState,setCardState] = useState({currentCard:0})

    const {currentCard:pageIndex} = cardState

    const toNextView = () =>{
            scrollViewRef.current.scrollTo({
                // screenX:width*(pageIndex-4)*-1,
                x:1600,
                animated:true,
            })
    }

    const toBackView = () => {
        if(scrollViewRef.current !== null){
            scrollViewRef.current.scrollTo({
                x:width*(pageIndex+1),
                animated:true,
            })
        }
    }

    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:450
    })


    if(isMobileDisplay){
        return(
            <View style={sellingStyle.mobileContainer}>
                <Text style={sellingStyle.sellingAtoZTextMobile}>Selling AtoZ</Text>
                <Text style={sellingStyle.middleLightTextMobile}>When it comes to what you can sell on our platform, Sky is the limit. We mean it when we say iShopAtoZ! </Text>

                <View style={sellingStyle.cardContainerMobile}>
                    <ScrollView
                            horizontal={true}
                            showsHorizontalScrollIndicator={false}
                            ref={scrollViewRef}
                    >
                        {cardImages.map(card=>{
                            return<View style={sellingStyle.cardViewMobile}> 
                            
                            <TouchableOpacity 
                                style={sellingStyle.cardImageMobile} 
                                onPress={() =>{Linking.openURL(sellerAppPlayStore)}}
                                >
                                <Image
                                source={card.img}
                                style={sellingStyle.cardImageMobile}
                                />
                            </TouchableOpacity>
                            
                            {/*
                            <Image
                            source={card.img}
                            style={sellingStyle.cardImageMobile}
                            />
                            */}
                                <Text style={sellingStyle.cardTitleMobile}>{card.title}</Text>
                                <Text nativeID='serviceableCities'style={sellingStyle.cardTextMobile}>{card.text}</Text>
                            </View>
                            
                        })}
                                                 <TouchableOpacity style={sellingStyle.knowMoreMobile} onPress={()=>Linking.openURL(sellerAppPlayStore)}>
                                                    <Image style={sellingStyle.cardImageMobile} source={require("../../../assets/common_assets/dottedCard.png")}/>
                                                 </TouchableOpacity>
                    </ScrollView>
                </View>

            </View>
        )
    }

    /*
    return (
        <View style={sellingStyle.sellingContainer}>
            <View style={sellingStyle.topTextContainer}>
                <Text style={sellingStyle.sellingAtoZText}>Selling AtoZ</Text>
                <Text style={sellingStyle.middleLightText}>When it comes to what you can sell as our partner {"\n"} Sky is the limit.We mean it when we sat iShopAtoZ</Text>
                <Image
                    style={sellingStyle.arrowImage} 
                    source={require("../../../assets/common_assets/back_arrow.png")}
                    />
                <Image
                    style={sellingStyle.arrowImage}
                    source={require("../../../assets/common_assets/forward_arrow.png")}
                    />
            </View>
            <View style={sellingStyle.gridContainer} >
                <ImageBackground
                    source={require("../../../assets/sellers/grocery.png")}
                    style={sellingStyle.groceryStyle}
                />
            </View>
        </View>
    )
    */

    return (
        <View style={sellingStyle.sellingContainer}>
           <View style={sellingStyle.topContainer}>
                <Text style={sellingStyle.theSegmentText}>Selling AtoZ</Text>
                <View>
                    <Text style={sellingStyle.centerText}>What you can sell on our platform, Sky is the limit. {"\n"}We mean it when we say iShopAtoZ! </Text>
                </View>
                <View style={sellingStyle.navigatorContainer}>
                    <TouchableOpacity
                        onPress={()=>toBackView()}
                        style={sellingStyle.opacityElement}
                    >
                        <Image 
                            source={require("../../../assets/common_assets/backward.png")}
                            style={sellingStyle.greyArrowImage}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={()=>toNextView()}
                        style={sellingStyle.opacityElement}
                    >
                        <Image
                            source={require("../../../assets/common_assets/forward.png")}
                            onPress={() => source={backwardArrow}}
                            style={sellingStyle.redArrowImage}
                            // onProgress={()=>{this.scrollview.scrollToEnd({x:screenWidth})}}
                        />
                     </TouchableOpacity>
                </View>
           </View>
           <View style={sellingStyle.cardContainer}>
               <ScrollView
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    ref={scrollViewRef}
               >
                {cardImages.map(card=>{
                    return<View style={sellingStyle.cardView}>
                    <TouchableOpacity 
                        onPress={()=>Linking.openURL(sellerAppPlayStore)}
                    >
                        <Image
                        source={card.img}
                        style={sellingStyle.cardImage}
                        />
                    </TouchableOpacity>
                        <Text style={sellingStyle.cardTitle}>{card.title}</Text>
                        <Text nativeID='serviceableCities'style={sellingStyle.cardText}>{card.text}</Text>
                    </View>
                     
                })}
                                    <TouchableOpacity style={sellingStyle.knowMore} onPress={()=>Linking.openURL(sellerAppPlayStore)}>
                                        <Image style={sellingStyle.cardImage} source={require("../../../assets/common_assets/dottedCard.png")}/>
                                    </TouchableOpacity>
                 </ScrollView>
           </View>
        </View>
    )

}

export default SellingAtoZ

import { Raleway_400Regular, Raleway_600SemiBold } from '@expo-google-fonts/raleway'
import {StyleSheet} from 'react-native'


export const orderStyle = StyleSheet.create({
    orderContainer:{
        marginRight:"auto",
        marginLeft:"auto",
        textAlign:"center",
        width:"100%",
        height:"106vh",
        backgroundColor: "#F2F9F9",
        marginTop:"9.852vh",
        padding: "2%",
    },

    topText:{
        // width:"28.40vw",
        width:"100%",
        height: "7.718vh",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "3.125vw",
        lineHeight: "7.718vh",
        /* identical to box height */
        color: "#2C2E2F",

    },
    cardContainer:{
        display:"flex",
        flexDirection:"row",
        flexWrap:"wrap",
        width:"80vw",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"10vh",
        backgroundColor: "#F2F9F9",

    },

     cardStyle:{
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        width:"16vw",
        justifyContent:"space-between",
     },

    cardImg:{
        width:"15.625vw",
        height:"31.199vh",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        resizeMode:"contain"
    },
    
    titleStyle:{
        width:"30vw",
        height: "3.777vh",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "1.25vw",
        lineHeight: "3.777vh",
        color: "#008489",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"left",
        marginTop:"1.642vh"
    },
    textStyle:{
        width: "16vw",
        height: "7.553vh",
        marginTop:"1.642vh",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "1.093vw",
        lineHeight: "3.777vh",
        color: "#2C2E2F",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"left"

    },
    googlePlayItems:{
        width:" 33.82vw",
        height:"26.788vh",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        top:"8vh"
    },

    //Responsive Mobile CSS

    orderContainerMobile:{
        marginRight:"auto",
        marginLeft:"auto",
        textAlign:"center",
        width:"100%",
        maxHeight:"100%",
        marginTop:"25px",
    },

    cardContainerMobile:{
        display:"flex",
        flexDirection:"colunm",
        flexWrap:"wrap",
        //width:"80vw",
        width:"100%",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"10px",

    },

    topTextMobile:{
        width:"80%",
        height: "60px",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "24px",
        lineHeight: "30px",
        /* identical to box height */
        color: "#2C2E2F",
    },

    cardImgMobile:{
        width:"224px",
        height:"221px",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        resizeMode:"contain"
    },
    
    cardStyleMobile:{
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        width:"100%",
        height:"296px",
        justifyContent:"space-between",
        marginTop:"10%"
     },
     titleStyleMobile:{
        width:"100%",
        height: "19px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "19px",
        color: "#008489",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"10px"
    },
    textStyleMobile:{
        width: "80%",
        height: "32px",
        marginTop:"10px",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "16px",
        color: "#2C2E2F",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center"
    }


})
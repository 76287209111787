import { StyleSheet } from "react-native";
import {Raleway, Raleway_400Regular, Raleway_600SemiBold} from '@expo-google-fonts/raleway'

export const hiringStyle = StyleSheet.create({
    /*
    hiringContainer:{
        height:"70vh",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        width:"100%",
        // padding: "10%",
        // padding: "2%",
        margin:"2%"
    },
    */

    hiringContainer:{
        width:"32vw",
        marginLeft:"3.90625vw",
        marginTop: "6.284vh",
        //backgroundColor:"#CCCCCC"
    },

    topContainer:{
        display:"flex",
        flexDirection:"row",
        width:"31.25vw",
        //textAlign:"left",
        //alignItems:"left",
        marginLeft:"1.5625vw",
        marginRight:"0",
    },

    applyNowButtonMobile:{
        width: "200px",
        height: "45px",
        backgroundColor: "#008489",
        borderRadius: "8px",
        alignItems:"center",
        justifyContent:"center",
        marginTop:"25px",
        marginLeft:"auto",
        marginRight:"auto",
        marginBottom:"0px"
    },

    applyNowButton:{
        width: "11.71875vw",
        height: "6.568vh",
        backgroundColor: "#008489",
        borderRadius: "8px",
        alignItems:"center",
        justifyContent:"center",
        marginTop:"1.642vh",
        //marginLeft:"auto",
        //marginRight:"auto",
        marginBottom:"0vh"
    },

    applyNowTextMobile:{
        width: "151px",
        height: "19px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        /* identical to box height */
        color: "#FFFFFF",
        textAlign:"center"

    },

    applyNowText:{
        width: "11.797vw",
        height: "3.12vh",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "1.25vw",
        lineHeight: "3.12vh",
        /* identical to box height */
        color: "#FFFFFF",
        textAlign:"center"

    },

    topBoldText:{
        width: "13vw",
        height: "7.718vh",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "1.875vw",
        lineHeight: "7.718vh",
        /* identical to box height */
        textAlign: "left",
        color: "#2C2E2F",
        //marginLeft:"30px",
        //marginRight:"",
        //backgroundColor:"#AAAAAA"
    },
    topLightText:{
        // width: "1184px",
        // width:" 86.67vw",
        height: "7.553vh",
        // top: "1300.12px"
        marginTop:"2vh",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "1.25vw",
        lineHeight: "3.777vh",
        textAlign: "left",
        marginRight:"auto",
        marginLeft:"1.5625vw",
        color: "#2C2E2F",

    },

    hiringContainerMobile:{
        //height:"22vh",
        height:"150px",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        alignItems:"center",
        width:"100%",
        // padding: "10%",
        // padding: "2%",
        margin:"2%"
    },

    topBoldTextMobile:{
        // position: absolute;
        width: "200px",
        height: "30px",
        //left: "304px",
        marginLeft: "25px",
        marginRight: "25px",
        // top: 2442.12px;

        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "47px",
        /* identical to box height */
        color: "#2C2E2F",

    },

    topLightTextMobile:{
        //width: "300px",
        width: "88%",
        height: "30px",
        marginLeft: "18px",
        marginRight: "18px",
        // left: 638px;
        // top: 2442px;
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "23px",
        color: "#2C2E2F",
        marginTop:"20px"

    },

    blockSpace:{
        borderBottomColor: 'black',
        borderBottomWidth: 1,
        width:"6.5vw",
        height:"0.328vh",
        left:"1vw",
        alignItems:"center",
        backgroundColor:"#2C2E2F",
        marginTop:"1.970vh",
        alignContent:"center",
        marginLeft:"0.78125vw"
    },

})
import * as React from 'react'
import {
    View,
    Text,
    Image,
    TouchableOpacity,
    Linking
} from 'react-native'
import {orderStyle} from "./orderStyle"
import { useMediaQuery } from 'react-responsive';
import QrElement from '../QrComponent/index'
import MobileQrComponent from '../QrComponentMobile';
import IconButton from '../../../storybook/component/play_button';

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

const OrderInSecond = (props) => {

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });
    
    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:600
    })


    if(isMobileDisplay){
        return (
            <View style={orderStyle.orderContainerMobile}>
                <Text style={orderStyle.topTextMobile}>{props.text}</Text>        
                <View style={orderStyle.cardContainerMobile}>
                    {
                        props.cardData.map(card=>{
                            return <View style={orderStyle.cardStyleMobile}>
                                        <Image
                                            source={card.img}
                                            style={orderStyle.cardImgMobile}
                                        />
                                        <Text style={orderStyle.titleStyleMobile}>{card.title}</Text>
                                        <Text style={orderStyle.textStyleMobile}>{card.text}</Text>
                                    </View>
                        })
                    }
                </View>
                    {/*<MobileQrComponent*/}
                    {/*    qrImage={props.qrImage}*/}
                    {/*    playStore={props.appStoreUrl}*/}
                    {/*    qrMessage={props.appText}*/}
                {/*/>*/}
                <View style=
                    {{
                    width: "100%",
                            backgroundColor: "#F2F9F9",
                            height: "356px",
                            marginLeft: "auto",
                            marginRight: "auto",
                            alignItems: "center",
                            textAlign: "center",
                            justifyContent: "center",
                            marginTop: "50px"
                        }}>
                <IconButton
                    onPress={() => Linking.openURL(props.appStoreUrl)}
                    qrImage={props.qrImage}
                    qrmess={props.appText}
                    styles={{
                        qrContainer: {
                            justifyContent: "center",
                            alignItems: "center",
                            // padding: "16px",
                            height: "264px",
                            width: "270px",
                            backgroundColor: "#FFFFFF",
                            borderRadius: "8px",
                            // marginTop:"151px",
                            marginLeft: "auto",
                            marginRight: "auto"
                        },
                        qrSubContainer: {
                            justifyContent: "center",
                            alignItems: "center",
                            // padding: "16px",
                            height: "264px",
                            width: "270px",
                            backgroundColor: "#FFFFFF",
                            borderRadius: "8px",
                            // marginTop:"151px",
                            marginLeft: "auto",
                            marginRight: "auto"
                        },
                        button: {
                            width: "168px",
                            height: "42px",
                            backgroundColor: "#000000",
                            borderRadius: "24px",
                            resizeMode: "contain",
                            marginLeft: "auto",
                            textAlign: "center",
                            marginRight: "auto"
                        },
                        googlePlayImg: {
                            width: "168px",
                            height: "42px",
                            backgroundColor: "#000000",
                            borderRadius: "24px",
                            resizeMode: "contain",
                            marginLeft: "auto",
                            textAlign: "center",
                            marginRight: "auto"
                        },

                        lightText: {
                            // position: absolute;
                            width: "238px",
                            height: "50px",
                            // left: 16px;
                            // marginTop: "16px",
                            /* Small */
                            fontFamily: 'Raleway_600SemiBold',
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "14px",
                            // lineHeight: "180%",
                            /* or 25px */
                            textAlign: "center",
                            /* Charcoal-Text */
                            color: "#2C2E2F"
                        },
                        qrStyle: {
                            width: "100px",
                            height: "100px",
                            backgroundColor: "#FFFFFF",
                            //boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
                            marginTop: "34px"
                        }
                    }}
                />
                </View>

            </View>
        )
    }
    
    return (
        <View style={orderStyle.orderContainer} nativeID='orderInSeconds'>
            <Text style={orderStyle.topText}>{props.text}</Text>        
            <View style={orderStyle.cardContainer}>
            {
                props.cardData.map(card=>{
                    return <View style={orderStyle.cardStyle}>
                                <Image
                                    source={card.img}
                                    style={orderStyle.cardImg}
                                />
                                <Text style={orderStyle.titleStyle}>{card.title}</Text>
                                <Text style={orderStyle.textStyle}>{card.text}</Text>
                            </View>
                })
            }
            </View>

            <View  style={orderStyle.googlePlayItems}>
                {/*<QrElement*/}
                {/*    qrImage={props.qrImage}*/}
                {/*    playStoreLink={props.appStoreUrl}*/}
                {/*    qrMessage={props.appText}*/}
                {/*/>*/}
                <IconButton
                    onPress={() => Linking.openURL(props.appStoreUrl)}
                    qrImage={props.qrImage}
                    qrmess={props.appText}
                    styles={{
                        qrContainer: {
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "10%",
                            // width: "414px",
                            width: " 27.30vw",
                            height: "21.90vh",
                            backgroundColor: "#FFFFFF",
                            borderRadius: "16px",
                        },
                        button: {
                            width: "12.29vw",
                            height: "6.397vh",
                            top: "0.985vh",
                            background: "#000000",
                            borderRadius: "1.875vw",
                            resizeMode: "contain",
                        },
                        googlePlayImg: {
                            width: "12.29vw",
                            height: "6.397vh",
                            top: "0.985vh",
                            background: "#000000",
                            borderRadius: "1.875vw",
                            resizeMode: "contain",
                        },

                        lightText: {
                            width: "18.594vw",
                            // width:"17.42vw",
                            height: "4.240vh",
                            left: "1.875vw",
                            textAlign: "left",
                            // top: "30px",
                            fontFamily: 'Raleway_600SemiBold',
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "1.093vw",
                            lineHeight: "3.120vh",
                            color: "#2C2E2F",
                        },
                        qrStyle: {
                            width: "100px",
                            height: "100px",
                            background: "#FFFFFF",
                        }
                    }}
                />
            </View>
            <View nativeID='faq'></View>
        </View>
    )
}

export default OrderInSecond
import * as React from 'react'
import {
    ImageBackground,
    Text,
    View,
    Image,
    TouchableOpacity,
    Linking
} from 'react-native'
import QrElement from "../../commonComponents/QrComponent/index"
import {homeStyle} from './homeStyles'
import { useMediaQuery } from 'react-responsive';
import PlayStore from '../../commonComponents/mobilePlayStore';

import IconButton from '../../../storybook/component/play_button';

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

const deliveryQr = require("../../../assets/delivery/delivery_qr.png")
const playStoreApp = "https://play.google.com/store/apps/details?id=com.ishopatoz.android.delivery.release"


const DeliveryHome = () => {

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:450
    })

    if(isMobileDisplay){
        return(
            <View style={homeStyle.homeContainerMobile}>
                <Text style={homeStyle.topBoldTextMobile}>Want to earn extra{"\n"}<Text style={{fontWeight:720}}>Income</Text> in your{"\n"}<Text style={{fontWeight:720}}> Spare</Text> time?</Text>
                <Text style={homeStyle.redTextMobile}>You are at the right place!</Text>
                <Text style={homeStyle.lightTextMobile}>Register with us, choose your{"\n"}locality & start earning</Text>
                <View style={homeStyle.playStoreContainerMobile}>
                    {/*<PlayStore*/}
                    {/*    url={playStoreApp}*/}
                    {/*    qrImage={deliveryQr}*/}
                    {/*    qrMessage="Download the delivery app now"*/}
                    {/*/>*/}
                    <IconButton
                        onPress={() => Linking.openURL(playStoreApp)}
                        qrImage={deliveryQr}
                        qrmess={"Download the delivery app now"}
                        styles={{
                            qrContainer: {
                                display: "flex",
                                flexDirection: "colum",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "0px",
                                // position: "absolute",
                                width: "202.76px",
                                height: "237px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                // top: "376px",
                                // backgroundColor: "#F2F9F9",
                                //backgroundColor:"#FFFFFF",
                                backgroundColor: "#F5FAFA",
                                borderRadius: "25px",
                                marginLeft: "auto",
                                // opacity:"0.6",
                                marginRight: "auto",
                                //marginTop:"60px"
                                marginTop: "30px"
                            },
                            button: {
                                width: "168px",
                                height: "42px",
                                // left: "36px"
                                top: "11px",
                                background: "#000000",
                                borderRadius: "24px",
                                resizeMode: "contain"
                            },
                            googlePlayImg: {
                                width: "168px",
                                height: "42px",
                                // left: "36px"
                                top: "11px",
                                background: "#000000",
                                borderRadius: "24px",
                                resizeMode: "contain"
                            },

                            lightText: {
                                width: "150px",
                                height: "50px",
                                // left: 1px;
                                // top: 27px;

                                /* Small */
                                fontFamily: 'Raleway_600SemiBold',
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontSize: "14px",
                                lineHeight: "180%",
                                /* or 25px */
                                textAlign: "center",
                                /* Charcoal-Text */
                                color: "#2C2E2F",
                                //backgroundColor:"#CCCCCC"
                            },
                            qrStyle: {
                                width: "102.84px",
                                height: "102.84px",
                                marginTop: "20px",
                                backgroundColor: "#FFFFFF",
                                boxShadow: "0px 0.803417px 0.803417px rgba(0, 0, 0, 0.25)",
                                resizeMode: "contain"
                            }
                        }}
                    />
                </View>
                <TouchableOpacity
                    style={homeStyle.requestCallButtonMobile}
                    onPress={()=>Linking.openURL("https://forms.gle/9Wo8qzEK3Q76hj2UA")}
                    >
                    <Text style={homeStyle.requestCallTextMobile}>Request a Call Back</Text>
                </TouchableOpacity>
                <View style={homeStyle.deliveryGirlContainerMobile}>
                    <Image
                        source={require("../../../assets/delivery/delivery_girl.png")}
                        style={homeStyle.deliveryGirlMobile}
                    />
                </View>
            </View>
        )
    }


    return (
        <View style={homeStyle.homeContainer} nativeID='highlights'>
            <View style={homeStyle.leftContainer}>
                <Text style={homeStyle.topLeftText}>Want to earn extra 
                <Text style={homeStyle.textHighlight}> Income </Text> 
                in {"\n"}your <Text style={homeStyle.textHighlight}>Spare </Text>time?</Text>
                <Text style={homeStyle.leftRedText}>You are at the right place!</Text>
                <Text style={homeStyle.leftLightText}>Register with us, choose your locality & start earning</Text>
                <View style={homeStyle.qrContainer}>
                    {/*<QrElement */}
                    {/*    qrImage={deliveryQr}*/}
                    {/*    playStoreLink={playStoreApp}*/}
                    {/*    qrMessage="Download the Delivery app now"*/}
                    {/*/>*/}
                    <IconButton
                        onPress={() => Linking.openURL(playStoreApp)}
                        qrImage={deliveryQr}
                        qrmess={"Download the Delivery app now"}
                        styles={{
                            qrContainer: {
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "10%",
                                // width: "414px",
                                width: " 27.30vw",
                                height: "21.90vh",
                                backgroundColor: "#FFFFFF",
                                borderRadius: "16px",
                            },
                            button: {
                                width: "12.29vw",
                                height: "6.397vh",
                                top: "0.985vh",
                                background: "#000000",
                                borderRadius: "1.875vw",
                                resizeMode: "contain",
                            },
                            googlePlayImg: {
                                width: "12.29vw",
                                height: "6.397vh",
                                top: "0.985vh",
                                background: "#000000",
                                borderRadius: "1.875vw",
                                resizeMode: "contain",
                            },

                            lightText: {
                                width: "18.594vw",
                                // width:"17.42vw",
                                height: "4.240vh",
                                left: "1.875vw",
                                textAlign: "left",
                                // top: "30px",
                                fontFamily: 'Raleway_600SemiBold',
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontSize: "1.093vw",
                                lineHeight: "3.120vh",
                                color: "#2C2E2F",
                            },
                            qrStyle: {
                                width: "100px",
                                height: "100px",
                                background: "#FFFFFF",
                            }
                        }}
                    />
                </View>
                <TouchableOpacity
                    style={homeStyle.requestCallButton}
                    onPress={()=>Linking.openURL("https://forms.gle/9Wo8qzEK3Q76hj2UA")}
                    >
                    <Text style={homeStyle.requestCallText}>Request a Call Back</Text>
                </TouchableOpacity>
            </View>
            <View style={homeStyle.rightContainer}>
                <Image
                    source = {require("../../../assets/delivery/delivery_girl.png")}
                    style={homeStyle.deliveryGirl}
                />
                {/* <View style={homeStyle.lineView}/> */}
            </View>
        </View>
    )
}

export default DeliveryHome
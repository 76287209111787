import * as React from 'react'
import{
    Image,
    Text,
    View,
} from 'react-native'

import { flexStyle } from './flexStyle'
import { useMediaQuery } from 'react-responsive'

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

const FlexComponent = (props) => {

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:600
    })

    if (isMobileDisplay){
        return(
            <View style={flexStyle.rootContainerMobile}>
            <Text style={flexStyle.topTextMobile}>{props.topText}</Text>
            <View style={flexStyle.cardContainerMobile}>
                {props.flexData.map(card=>{
                    return <View style={flexStyle.flexCardMobile}>      
                            <Image 
                                source={card.img}
                                style={flexStyle.cardImgMobile}
                                />
                            <View style={flexStyle.textContainerMobile}>
                                <Text style={flexStyle.titleStyleMobile}>{card.title}</Text>
                                <Text style={flexStyle.textStyleMobile}>{card.textMobile}</Text>
                            </View>
                    </View>
                })}
            </View>
        </View>
        )
    }

    return (
        <View style={flexStyle.rootContainer}>
            <Text style={flexStyle.topText}>{props.topText}</Text>
            <View style={flexStyle.flexContainer}>
                {props.flexData.map(card=>{
                    return <View style={flexStyle.flexCard}>
                        <Image 
                            source={card.img}
                            style={flexStyle.cardImg}
                            />
                        <Text style={flexStyle.titleStyle}>{card.title}</Text>
                        <Text style={flexStyle.textStyle}>{card.text}</Text>
                    </View>
                })}
            </View>
        </View>
    )
}

export default FlexComponent
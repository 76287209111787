import * as React from 'react'

import {
    View,
    Text,
    Image,
    ScrollView,
    TouchableOpacity,
    Dimensions,
    Button,
    ImageBackground,
    Linking,
    SafeAreaView, 
} from 'react-native'
import { useState,useRef } from 'react'
import { partnerStyle } from './partnerStyles'
import { useMediaQuery } from 'react-responsive';
//import {segmentStyle} from './segmentStyle'
import CustomButton from '../../commonComponents/SubmitButton';

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

let backwardArrow = require("../../../assets/common_assets/back_arrow.png")
let forwardArrow = require("../../../assets/common_assets/forward_arrow.png")
let doublequote = require("../../../assets/common_assets/double_quote_green.png")

const deliveryAppPlayStore = "https://play.google.com/store/apps/details?id=com.ishopatoz.android.delivery.release"

const cardImages = [
                    {
                        id:5,
                        img:require("../../../assets/testimonials/delivery/dhanya.png"),
                        title:"Dhanya B\nStudent",
                        text:"The iShopAtoZ policies and guidelines make it comfortable to consider joining the delivery network."
                    },
                    {
                        id:1,
                        img:require("../../../assets/testimonials/delivery/alex.png"),
                        title:"Alex John\nStudent",
                        text:"As a student, the app allows me to work and earn in my free time."
                    },
                    {
                        id:2,
                        img:require("../../../assets/testimonials/delivery/manjunath.png"),
                        title:"Manjunath\nMechanic",
                        text:"The iShopAtoZ wallet allows me to transfer money directly into my bank account with ease."
                    },
                    {
                        id:3,
                        img:require("../../../assets/testimonials/delivery/rehman.png"),
                        title:"Rehman\nArtist",
                        text:"iShopAtoZ gives me the flexibility to work in my free hours - this way I can be more productive with my day."
                    },
                   
                    // {
                    //     id:4,
                    //     img:require("../../../assets/testimonials/delivery/mohan.png"),
                    //     title:"Mohan Kumar\nPlumber",
                    //     text:"As a delivery partner on iShopAtoZ has helped me earn some good money and exposure by working part time."
                    // },
]

const deliveryPartner = () => {

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const scrollViewRef = useRef(null);
    const width = Dimensions.get("window")
    
    const [cardState,setCardState] = useState({currentCard:0})

    const {currentCard:pageIndex} = cardState

    const toNextView = () =>{
            scrollViewRef.current.scrollTo({
                // screenX:width*(pageIndex-4)*-1,
                x:1600,
                animated:true,
            })
    }

    const toBackView = () => {
        if(scrollViewRef.current !== null){
            scrollViewRef.current.scrollTo({
                x:width*(pageIndex+1),
                animated:true,
            })
        }
    }

    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:450
    })


    if(isMobileDisplay){
        return(
            <View style={partnerStyle.proudContainerMobile}>
                <Text style={partnerStyle.partnerTextMobile}>What our delivery{"\n"}partners say!</Text>
                
                <View style={partnerStyle.cardContainerMobile}>
                    <ScrollView
                            horizontal={true}
                            showsHorizontalScrollIndicator={false}
                            ref={scrollViewRef}
                    >
                        {cardImages.map(card=>{
                            return<View style={partnerStyle.cardViewMobile}> 
                                <View style={partnerStyle.cardViewContainerMobile}>
                                    <Image
                                        source={card.img}
                                        style={partnerStyle.cardImageMobile}
                                    />
                                </View>
                                
                                <View style={partnerStyle.cardAllTextContainerMobile}>
                                    <Image
                                        source={doublequote}
                                        style={partnerStyle.doublequoteMobile}
                                    />
                                    <View style={partnerStyle.cardTextContainerMobile}>
                                        {/*
                                        <Text nativeID='serviceableCities' style={partnerStyle.cardTextMobile}>{card.text}</Text>
                                        */}  
                                        <SafeAreaView style={partnerStyle.safeAreaContainerMobile}>
                                            <ScrollView style={partnerStyle.scrollViewCardMobile}>
                                                <Text nativeID='serviceableCities' style={partnerStyle.cardTextMobile}>{card.text}</Text>
                                            </ScrollView>   
                                        </SafeAreaView>        
                                    </View>
                                </View>
                                <View style={partnerStyle.cardTitleContainerMobile}>
                                    <Text style={partnerStyle.cardTitleMobile}>{card.title}</Text>
                                </View>
                            </View>
                            
                        })}
                         <TouchableOpacity style={partnerStyle.knowMore} onPress={()=>Linking.openURL(deliveryAppPlayStore)}>
                                <Image style={partnerStyle.cardImageMobile} source={require("../../../assets/common_assets/dottedCard.png")}/>
                            </TouchableOpacity>
                    </ScrollView>
                </View>
                <View style={partnerStyle.buttonRootContainerMobile}>
                    <CustomButton
                                buttonLink="https://docs.google.com/forms/d/1jg97TPMrnM6P7WTs50vLmO7uU8WVsWrLk4L6YWjJhH4/edit?usp=sharing"
                                buttonText="Submit Feedback"
                                />
                 </View>
            </View>
        )
    }

    return (
        <View style={partnerStyle.proudContainer}>
           <View style={partnerStyle.topContainer}>
                <Text style={partnerStyle.theSegmentText}>What our delivery partners say!</Text>
                
                <View style={partnerStyle.navigatorContainer}>
                    <TouchableOpacity
                        onPress={()=>toBackView()}
                        style={partnerStyle.opacityElement}
                    >
                        <Image 
                            source={require("../../../assets/common_assets/backward.png")}
                            style={partnerStyle.greyArrowImage}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={()=>toNextView()}
                        style={partnerStyle.opacityElement}
                    >
                        <Image
                            source={require("../../../assets/common_assets/forward.png")}
                            onPress={() => source={backwardArrow}}
                            style={partnerStyle.redArrowImage}
                            // onProgress={()=>{this.scrollview.scrollToEnd({x:screenWidth})}}
                        />
                     </TouchableOpacity>
                </View>
           </View>
           <View style={partnerStyle.cardContainer}>
               <ScrollView
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    ref={scrollViewRef}
               >
                {cardImages.map(card=>{
                    return<View style={partnerStyle.cardView}> 
                        <View style={partnerStyle.cardViewContainer}>
                            <Image
                            source={card.img}
                            style={partnerStyle.cardImage}
                            />
                        </View>
                        <View style={partnerStyle.cardAllTextContainer}>
                            <Image
                                source={doublequote}
                                style={partnerStyle.doublequote}
                            />
                            <View style={partnerStyle.cardTextContainer}>
                                {/*
                                <Text nativeID='serviceableCities' style={partnerStyle.cardText}>{card.text}</Text>
                                */}
                                <SafeAreaView style={partnerStyle.safeAreaContainer}>
                                    <ScrollView style={partnerStyle.scrollViewCard}>
                                        <Text nativeID='serviceableCities' style={partnerStyle.cardText}>{card.text}</Text>
                                    </ScrollView>   
                                </SafeAreaView>
                            </View>
                        </View> 
                        <View style={partnerStyle.cardTitleContainer}>
                            <Text style={partnerStyle.cardTitle}>{card.title}</Text>
                        </View>
                    </View>
                     
                })}
                 <TouchableOpacity style={partnerStyle.knowMore} onPress={()=>Linking.openURL(deliveryAppPlayStore)}>
                        <Image style={partnerStyle.cardImageMore} source={require("../../../assets/common_assets/dottedCard.png")}/>
                    </TouchableOpacity>
                 </ScrollView>
           </View>
           <View nativeID='orderInSecondsdel'/>
           <View style={partnerStyle.buttonRootContainer}>
                <CustomButton
                            buttonLink="https://docs.google.com/forms/d/1jg97TPMrnM6P7WTs50vLmO7uU8WVsWrLk4L6YWjJhH4/edit?usp=sharing"
                            buttonText="Submit Feedback"
                            />
            </View>
        </View>
    )

}

export default deliveryPartner
export const buyerCardData = [
    {
        img:require("../../../assets/common_assets/order_img_1.png"),
        title:"Download the app",
        text:"Install the app & sign in with your mobile number"
    },
    {
        img:require("../../../assets/common_assets/order_img_2.png"),
        title:"Place the order",
        text:"Get the best deals from your nearest favourite stores"
    },
    {
        img:require("../../../assets/common_assets/order_img_3.png"),
        title:"Sit back & relax",
        text:"Enjoy the quick & quality delivery",
    }
]


export const sellerCardData = [
    {
        img:require("../../../assets/sellers/order_img.png"),
        title:"Download the app",
        text:"Install the app, sign-up & register"
    },
    {
        img:require("../../../assets/sellers/digital_store.png"),
        title:"Open the digital store",
        text:"Add your products & services"
    },
    {
        img:require("../../../assets/sellers/receiving_money.png"),
        title:"Start receiving orders",
        text:"Sit back, relax & check your order notifications"
    }
]


export const deliveryCardData = [
    {
        img:require("../../../assets/common_assets/order_img_1.png"),
        title:"Download the app",
        text:"Install the app, sign-up & register"
    },
    {
        img:require("../../../assets/delivery/goOnline.png"),
        title:"Go online & get notified",
        text:"Use every single opportunity to contribute to the community",
    },
    {
        img:require("../../../assets/delivery/earn.png"),
        title:"Earn from your locality",
        text:"Get upto ₹50K per month in your spare time"
    }
]
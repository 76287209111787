import * as React from 'react'

import {
    View,
    Text,
    Image,
    ScrollView,
    TouchableOpacity,
    Dimensions,
    Button,
    ImageBackground,
    Linking,
    SafeAreaView, 
} from 'react-native'
import { useState,useRef } from 'react'
import { teamStyle } from './theteamStyle'
import { useMediaQuery } from 'react-responsive';
//import {segmentStyle} from './segmentStyle'


import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

let backwardArrow = require("../../../assets/common_assets/back_arrow.png")
let forwardArrow = require("../../../assets/common_assets/forward_arrow.png")
let doublequote = require("../../../assets/common_assets/double_quote_green.png")

const linkedinPeopleURL = "https://www.linkedin.com/company/ishopatoz/"

const cardImages = [
                    {
                        id:1,
                        img:require("../../../assets/testimonials/aboutUs/Raghavendra.jpg"),
                        title:"Raghavendra Singh\nCEO",
                        text:"I completed my Master's Degree in Computer Science from IIT Roorkee and later had been associated with various E-Commerce companies.\n\niShopAtoZ was founded in the year 2019  with an aim to create an End-to-End E-Commerce platform for MSMEs and provide new businesses and employment opportunities for Indians. I believe that the Indian MSMEs should have a strong platform where they can do business online while still continuing offline, which will help them grow and compete in the market catering to the changing consumer habits of the new digital India.\n\niShopAtoZ is not only solving problems of the people of India but also it is going to solve the issues of any common man across the globe. We are creating a bright future for the world. If you resonate with this vision, connect with us and together we will solve the challenging problems and build this world a better place to live.\n\n "
                    },
                    {
                        id:2,
                        img:require("../../../assets/testimonials/aboutUs/Dipin4.jpg"),
                        title:"Dipin KP\nDirector of Product Management",
                        text:"iShopAtoZ is one of the few e-commerce companies with a strong vision to help bring the MSMEs segment to the fore and to create a lot of employment opportunities to serve the needs of the community.\n\nVery soon iShopAtoZ is going to touch the life of every Indian citizen in one way or the other.\n\nIt is the right place for any energetic and passionate person to learn, contribute, enjoy and earn big.\n\nEarlier to iShopAtoZ, I had been in the IT industry for close to two decades and was fortunate to work for many top global companies like Nokia, Samsung etc. doing multiple roles connecting with many brightest minds in the industry. Innovation is my passion and so far I have filed 27 patents and few of them are already granted by the US Patent Office (USPTO). I did a Master's in Computer Science prior to starting my career.\n\n"
                    }
]

const TheTeam = () => {

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const scrollViewRef = useRef(null);
    const width = Dimensions.get("window")

    const [cardState,setCardState] = useState({currentCard:0})

    const {currentCard:pageIndex} = cardState

    const toNextView = () =>{
            scrollViewRef.current.scrollTo({
                // screenX:width*(pageIndex-4)*-1,
                x:1600,
                animated:true,
            })
    }

    const toBackView = () => {
        if(scrollViewRef.current !== null){
            scrollViewRef.current.scrollTo({
                x:width*(pageIndex+1),
                animated:true,
            })
        }
    }

    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:450
    })


    if(isMobileDisplay){
        return(
            <View style={teamStyle.proudContainerMobile}>
                <Text style={teamStyle.teamTextMobile}>The Team</Text>
                <Text
                    style={teamStyle.middleLightTextMobile}>We are a team of highly motivated & enthusiastic professionals from various reputed institutions with exceptional industry experience.
                    <TouchableOpacity onPress={() =>Linking.openURL(linkedinPeopleURL) }><Text style={teamStyle.middleLightTextRedMobile}> Know More</Text></TouchableOpacity>
                </Text>
                <View style={teamStyle.cardContainerMobile}>
                    <ScrollView
                            horizontal={true}
                            showsHorizontalScrollIndicator={false}
                            ref={scrollViewRef}
                    >
                        {cardImages.map(card=>{
                            return<View style={teamStyle.cardViewMobile}>
                                <View style={teamStyle.cardViewContainerMobile}>
                                   <TouchableOpacity onPress={()=>Linking.openURL(linkedinPeopleURL)}>
                                    <Image
                                        source={card.img}
                                        style={teamStyle.cardImageMobile}
                                    />
                                   </TouchableOpacity>
                                </View>
                                <View style={teamStyle.cardTitleContainerMobile}>
                                    <Text style={teamStyle.cardTitleMobile}>{card.title}</Text>
                                </View>
                                <View style={teamStyle.cardAllTextContainerMobile}>
                                    <Image
                                        source={doublequote}
                                        style={teamStyle.doublequoteMobile}
                                    />
                                    <View style={teamStyle.cardTextContainerMobile}>
                                        {/*
                                        <Text nativeID='serviceableCities' style={teamStyle.cardTextMobile}>{card.text}</Text>
                                        */}
                                        <SafeAreaView style={teamStyle.safeAreaContainerMobile}>
                                            <ScrollView style={teamStyle.scrollViewCardMobile}>
                                                <Text nativeID='serviceableCities' style={teamStyle.cardTextMobile}>{card.text}</Text>
                                            </ScrollView>
                                        </SafeAreaView>
                                    </View>
                                </View>
                            </View>

                        })}
                        <TouchableOpacity style={teamStyle.knowMore} onPress={()=>Linking.openURL(linkedinPeopleURL)}>
                            <Image style={teamStyle.cardImageMobile} source={require("../../../assets/common_assets/dottedCard.png")}/>
                        </TouchableOpacity>
                    </ScrollView>
                </View>

            </View>
        )
    }

    return (
        <View style={teamStyle.proudContainer}>
           <View style={teamStyle.topContainer}>
           <Text style={teamStyle.teamText}>The Team</Text>
                <Text
                    style={teamStyle.middleLightText}>We are a team of highly motivated & enthusiastic professionals from various reputed institutions with exceptional industry experience.&nbsp;&nbsp;
                    <TouchableOpacity onPress={() =>Linking.openURL(linkedinPeopleURL) }><Text style={teamStyle.middleLightTextUnderLine}>Know More</Text></TouchableOpacity>
                </Text>
                <View style={teamStyle.navigatorContainer}>
                    <TouchableOpacity
                        onPress={()=>toBackView()}
                        style={teamStyle.opacityElement}
                    >
                        <Image
                            source={require("../../../assets/common_assets/backward.png")}
                            style={teamStyle.greyArrowImage}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={()=>toNextView()}
                        style={teamStyle.opacityElement}
                    >
                        <Image
                            source={require("../../../assets/common_assets/forward.png")}
                            onPress={() => source={backwardArrow}}
                            style={teamStyle.redArrowImage}
                            // onProgress={()=>{this.scrollview.scrollToEnd({x:screenWidth})}}
                        />
                     </TouchableOpacity>
                </View>
           </View>
           <View style={teamStyle.cardContainer}>
               <ScrollView
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    ref={scrollViewRef}
               >
                {cardImages.map(card=>{
                    return<View style={teamStyle.cardView}>
                        <View style={teamStyle.cardViewContainer}>
                         <TouchableOpacity onPress={()=>Linking.openURL(linkedinPeopleURL)}>
                            <Image
                            source={card.img}
                            style={teamStyle.cardImage}
                            />
                         </TouchableOpacity>

                        </View>
                        <View style={teamStyle.cardTitleContainer}>
                            <Text style={teamStyle.cardTitle}>{card.title}</Text>
                        </View>
                        <View style={teamStyle.cardAllTextContainer}>
                            <Image
                                source={doublequote}
                                style={teamStyle.doublequote}
                            />
                            <View style={teamStyle.cardTextContainer}>
                                {/*
                                <Text nativeID='serviceableCities' style={teamStyle.cardText}>{card.text}</Text>
                                */}
                                <SafeAreaView style={teamStyle.safeAreaContainer}>
                                    <ScrollView style={teamStyle.scrollViewCard}>
                                        <Text nativeID='serviceableCities' style={teamStyle.cardText}>{card.text}</Text>
                                    </ScrollView>
                                </SafeAreaView>
                            </View>
                        </View>
                    </View>

                })}

                                    <TouchableOpacity style={teamStyle.knowMore} onPress={()=>Linking.openURL(linkedinPeopleURL)}>
                                        <Image style={teamStyle.cardImageMore} source={require("../../../assets/common_assets/dottedCard.png")}/>
                                    </TouchableOpacity>

                 </ScrollView>
           </View>
        </View>
    )

}

export default TheTeam
 export const socialImg = [
// import { servicableStyle } from './../../buyerComponents/ServicableCities/ServicableStyle';
    {
        img:require("../../../assets/common_assets/fb_logo.png"),
        link:"https://www.facebook.com/iShopAtoZ/"
    },
    {
        img:require("../../../assets/common_assets/insta_logo.png"),
        link:"https://www.instagram.com/ishopatoz_official/"
    },
    {
        img:require("../../../assets/common_assets/linkedin_logo.png"),
        link:"https://www.linkedin.com/company/ishopatoz/"
    }
]

export const contactItem = [
    {id:0,img:require("../../../assets/common_assets/gps_logo.png"),text:"Manipal County Rd,\nSingasandra, Bengaluru,\nKaranataka - 560 068"},
    {id:1,img:require("../../../assets/common_assets/message_logo.png"),text:"contact@ishopatoz.com"},
    // {id:2,img:require("../../../assets/common_assets/phone_logo.png"),text:"(+91) 00000 00000"}
]

export const VerticalTextMobile = [
    {text:"Grocery"},
    {text:"Food & Restaurant"},
    {text:"Beauty & Salon"},
    {text:"Health"},
    {text:"Fitness & Gym"},
    {text:"Electronics"},
    {text:"Home & Kitchen Appliances"},
    {text:"more..."}
]

export const VerticalText = [
    {text:"Grocery"},
    {text:"Food & Restaurant"},
    {text:"Beauty & Salon"},
    {text:"Health"},
    {text:"Fitness & Gym"},
    {text:"Electronics"}
]

export const VerticalText02 = [
    {text:"Home & Kitchen Appliances"},
    {text:"more..."}
]

export const serviceableCities = [
    {text:"Bengaluru"},
    {text:" "},
    {text:"Arriving Soon - "},
    {text:" "},
    {text:"Delhi"},
    {text:"Mumbai"},
    {text:"Chennai"},
    {text:"Kolkata"},
    {text:"Hyderabad"},
    {text:"Pune"},
    {text:"Ahmedabad"}

]

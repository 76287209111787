import { Raleway_400Regular, Raleway_600SemiBold } from '@expo-google-fonts/raleway'
import {StyleSheet} from 'react-native'

//import {Raleway} from '@expo-google-fonts/raleway'

export const askedStyle = StyleSheet.create({
    askContainer:{
        width:"100%",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        //height:"120vh",
        //maxHeight:"100%",
        // padding: "8%",
        marginTop:"4.084vh",
        marginBottom:"3.284vh"
    },
    topText:{
        //width: "38.72",
        //width:"100%",
        width:"50vw",
        height: "9.852vh",
        marginLeft:"28vh",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "3.125vw",
        lineHeight: "9.852vh",
        /* identical to box height, or 180% */
        color: "#2C2E2F",
        textAlign:"left",
        //backgroundColor:"#CCCCCC"

    },

    questionCardContainer:{
        marginTop:"3.284vh",
        width:"100%",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center"
    },

    questionCardContainerMobile:{
        marginTop:"20px",
        width:"100%",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center"
    },

    questionRoot:{
        boxShadow: "0 0.3px 0px 0 #888",
        maxHeight:"100%",
        width:"72vw",
        textAlign:"center",
        marginLeft:"auto",
        marginRight:"auto"
    },

    questionContainer:{
        height:"8vh",
        width: "72vw",
        marginLeft:"auto",
        marginRight:"auto",
        alignItems:"center",
        display:"flex",
        flexDirection:"row",
        marginTop:"1%"
    },
    questionContainerClick:{
        height:"15vh",
        width: "60vw",
        marginLeft:"auto",
        marginRight:"auto",
        alignItems:"center",
        display:"flex",
        flexDirection:"row",
        marginTop:"1%"
    },


    iconContainer:{
        width:"2.5vw",
        height:"5.255vh",
        marginRight:"2vw",
        alignItems:"center"
    },
    clickIcon:{
        resizeMode:"contain",
        // height:"7.41px",
        // width:"12px",

        height:"4.926vh",
        width:"2.344vw",
        float:"right",
       
    },

    clickIconMobile:{
        resizeMode:"contain",
        // height:"7.41px",
        // width:"12px",

        height:"25px",
        width:"25px",
        float:"right",
       
    },

    questionText:{
        width: "100%",
        // height: "23px",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "1.25vw",
        lineHeight: "3.777vh",
        fontFeatureSettings: "'salt' on, 'liga' off",
        color: "#2C2E2F",
        textAlign:"left"

    },

    hiddenAnswer:{
        display:"none"
    },
    answerText:{
        width: "80%",
        // height: "23px",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        // fontWeight: "normal",
        fontWeight:"400",
        fontSize: "1.094vw",
        lineHeight: "3.777vh",
        fontFeatureSettings: "'salt' on, 'liga' off",
        color: "#2C2E2F",
        textAlign:"left",
        // marginLeft:"1%"
        marginBottom:"3.284vh"
    },  

    answerView:{
        width: "100%",
        // height: "23px",
        // alignItems:"center",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "1.25vw",
        lineHeight: "3.777vh",
        fontFeatureSettings: "'salt' on, 'liga' off",
        color: "#2C2E2F",
        textAlign:"left"
    },

    //responsive mobile

    askContainerMobile:{
        width:"100%",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        //height:"135vh",
        //maxHeight:"100%",
    },
    topTextMobile:{
        width: "257px",
        height: "56px",
        marginLeft: "30px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "28px",
        color: "#2C2E2F",
        textAlign:"left",
        marginTop:"30px"
    },

    questionRootMobile:{
        // boxShadow: "0 0.3px 0px 0 #888",
        maxHeight:"100%",
        //width:"100vw",
        width:"100%",
        textAlign:"center",
        marginLeft:"auto",
        marginRight:"auto",
    },
    bottomLine:{
        width: "302px",
        height: "1px",
        marginLeft: "34px",
        // top: 4304px;
        backgroundColor: "#2C2E2F",
        opacity: "0.1",
    },
    questionContainerMobile:{
        //height:"8vh",
        //width: "72vw",
        width: "85%",
        marginLeft:"auto",
        marginRight:"auto",
        alignItems:"center",
        display:"flex",
        flexDirection:"row",
        maxHeight:"100%",
        // marginTop:"1%"
        margin:"4%"
    },
    questionTextMobile:{
        width: "95%",
        // height: "22px",
        // left: "34px"
        // top: 4256px;
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        marginRight:"4%",
        //lineHeight: "22px",
        /* or 22px */

        fontFeatureSettings: "'salt' on, 'liga' off",
        color: "#2C2E2F",
        textAlign:"left"

    },
    answerTextMobile:{
        //width: "70vw",
        width:"80%",
        // height: "22px",
        marginLeft: "30px",
        //marginTop:"-6px",
        // top: 4256px;
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "22px",
        /* or 22px */

        fontFeatureSettings: "'salt' on, 'liga' off",
        color: "#2C2E2F",
        textAlign:"left",
        marginBottom:"20px"
    }


})
//import { Raleway_800ExtraBold } from '@expo-google-fonts/raleway'
import { Raleway_600SemiBold } from '@expo-google-fonts/raleway'
import {StyleSheet} from 'react-native'
//import {Raleway, Raleway_400Regular, Raleway_500Medium, Raleway_600SemiBold} from '@expo-google-fonts/raleway'


export const homeStyle = StyleSheet.create({
    homeContainer:{
        width:"100%",
        height:"100vh",
        marginTop:"64px",
        maxHeight:"100%",
       
    },
    backgroundImage:{
        flex:1,
        width:null,
        height:null,
        resizeMode:"cover",
        width:"100%",
        height:"100vh",
        // height:100,
        // width:100,
    },

    topBoldText:{
        // width: "537px",
        width:"40.3125vw",
        // width:" 39.31vw",
        height: "21.586vh",
        marginTop: "19.204vh",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "2.734vw",
        lineHeight: "6.695vh",
        textAlign: "center",
        color: "#2C2E2F",
        marginLeft:"auto",
        marginRight:"auto",

    },

    topBoldTextBold:{
        // width: "537px",
        width:"40.3125vw",
        // width:" 39.31vw",
        height: "21.586vh",
        marginTop: "19.204vh",
        fontFamily: 'Raleway_800ExtraBold',
        fontStyle: "normal",
        fontWeight: 800,
        fontSize: "2.734vw",
        lineHeight: "6.695vh",
        textAlign: "center",
        color: "#2C2E2F",
        marginLeft:"auto",
        marginRight:"auto",

    },

    boldRedText:{
        // position: "absolute",
        width: "45.625vw",
        height: "7.195vh",
        marginTop: "1vh",
        marginRight:"auto",
        marginLeft:"auto",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "2.734vw",
        lineHeight: "7.1954vh",
        textAlign: "center",
        // color: "#FF002A"
        color:"#008489"

    },
    mediumLightText:{
        width: "25.859vw",
        height: "7.553vh",
        marginLeft:"auto",
        marginRight:"auto",
        marginTop: "2vh",
        fontFamily: 'Raleway_500Medium',
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "1.1625vw",
        lineHeight: "3.777vh",
        textAlign: "center",
        color: "#2C2E2F"

    },

    lightText:{
        position: "static",
        width: "18.594vw",
        height: "4.240vh",
        marginTop: "1vh",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "1.093vw",
        lineHeight: "3.120vh",
        color: "#2C2E2F",
        /* Inside Auto Layout */
        flex: "none",
        order: 0,
        flexGrow: 0,
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center"
    },

    googlePlayImg:{
        // position: absolute;
        width: "10.9375vw",
        height: "4.926vh",
        marginRight:"auto",
        marginLeft:"auto",
        justifyContent:"center",
        borderRadius: "1.875vw",
        resizeMode:"contain",
        marginTop:"1vh"
    },

    qrImageStyle:{
        width:"7.8125vw",
        height:"16.420vh",
        resizeMode:"contain",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"1vh"
    },

    ///Responsive mobile CSS
    topBoldTextMobile:{
        // position: absolute;
        width: "266px",
        height: "126px",
        top: "130px",
        marginTop:"3.5vh",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "32px",
        lineHeight: "130%",
        /* or 42px */
        textAlign: "center",
        color: "#2C2E2F",
        marginLeft:"auto",
        marginRight:"auto",

    },

    topBoldTextMobileBold:{
        // position: absolute;
        width: "266px",
        height: "126px",
        top: "130px",
        marginTop:"3.5vh",
        fontFamily: 'Raleway_800ExtraBold',
        fontStyle: "normal",
        fontWeight: 800,
        fontSize: "32px",
        lineHeight: "130%",
        /* or 42px */
        textAlign: "center",
        color: "#2C2E2F",
        marginLeft:"auto",
        marginRight:"auto",

    },

    homeContainerMobile:{
        width:"100%",
        //height:"100vh",
        marginTop:"64px",
        //maxHeight:"100%",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        backgroundColor:"#FFFFFF"
    },

    redTextMobile:{
        // position: "absolute",
        width: "297px",
        height: "28px",
        marginTop: "20px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "28px",
        textAlign: "center",
        marginLeft:"auto",
        marginRight:"auto",
        // color: "#FF002A"
        color:"#008489",
    },
    lightTextMobile:{
        width: "298px",
        height: "30 px",
        marginTop: "25px",
        fontFamily: 'Raleway_500Medium',
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "21px",
        textAlign: "center",
        marginRight:"auto",
        marginLeft:"auto",
        color: "#2C2E2F",

    },

    playStoreContainer:{
        width:"100%",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center"
    },

    playStoreContainerMobile:{
        width:"100%",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center"
    },

    appLinkContainerMobile:{
        justifyContent: "center",
        alignItems: "center",
        // padding: "16px",
        height:"100px",
        width:"240px",
        //backgroundColor: "#F2F9F9",
        borderRadius: "10px",
        // marginTop:"151px",
        marginLeft:"auto",
        marginRight:"auto",
        marginBottom:"10px"
    },

    qrIconMobile:{
        resizeMode:"contain",
        height:100,
        width:100,
        alignItems:"center",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"0"
    },

    mobileBanner:{
        width: "100%",
        height: "216px",
        resizeMode:"cover",
        marginTop:"20px"
    }

})
import * as React from 'react'
import {
    View,
    Text,
    TouchableOpacity,
    Image
} from 'react-native'
import { GoToTopStyle } from './gotTotopStyles'
import {useState} from 'react'
import { useMediaQuery } from 'react-responsive'

const GoToTop = () => {


    const [visibility,setVisibility] = useState(GoToTopStyle.nonDisplay)
    
    window.addEventListener('scroll',()=>{
        if(window.pageYOffset > 200){
            setVisibility(GoToTopStyle.goToTopCont)
        }else{
            setVisibility(GoToTopStyle.nonDisplay)
        }
    })
    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:600
    })

    if(isMobileDisplay){
        return (
            <View>

            </View>
        )
    }
    return (
        <View style={{zIndex:10000}}>
            <TouchableOpacity
                    onPress={()=>{window.scrollTo(0,0)}}
                    style={visibility}>
                    <Image
                        style={GoToTopStyle.goToTop}
                        source={require('../../../assets/common_assets/go_to_top.png')}
                        />
            </TouchableOpacity>
        </View>
    )
}

export default GoToTop
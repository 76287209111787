import { Raleway_400Regular, Raleway_600SemiBold } from '@expo-google-fonts/raleway'
import {StyleSheet} from 'react-native'
//import {Raleway} from '@expo-google-fonts/raleway'

export const mobileQrSytles = StyleSheet.create({
    qrContainer:{
        width:"100%",
        backgroundColor:"#F2F9F9",
        height:"356px",
        marginLeft:"auto",
        marginRight:"auto",
        alignItems:"center",
        textAlign:"center",
        justifyContent:"center",
        marginTop:"50px"
    },
    qrSubContainer:{
        justifyContent: "center",
        alignItems: "center",
        // padding: "16px",
        height:"264px",
        width:"270px",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        // marginTop:"151px",
        marginLeft:"auto",
        marginRight:"auto"
    },
    topText:{
        // position: absolute;
        width: "238px",
        height: "50px",
        // left: 16px;
        // marginTop: "16px",
        /* Small */
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        // lineHeight: "180%",
        /* or 25px */
        textAlign: "center",
        /* Charcoal-Text */
        color: "#2C2E2F"

    },

    googlePlayImage:{
        width: "168px",
        height: "42px",
        backgroundColor: "#000000",
        borderRadius: "24px",
        resizeMode:"contain",
        marginLeft:"auto",
        textAlign:"center",
        marginRight:"auto"
    },
    qrImage:{
        width: "100px",
        height: "100px",
        backgroundColor: "#FFFFFF",
        //boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
        marginTop:"34px"
        
    }
})
import React,{ useEffect, useState, useRef } from 'react'
import {Link} from '@react-navigation/native'
import {
    View,
    Text,
    Image,
    TouchableOpacity,
    TouchableHighlight,
    Linking
} from 'react-native'
import {navigation} from '@react-navigation/native-stack'

import '@expo/match-media'
import { useMediaQuery } from 'react-responsive'
// import ishoplogo from "../assets/images"
import {topNavStyles} from './navStyles'
import {NativeRouter,Route} from 'react-router-native'
import { useNavigation } from '@react-navigation/native'
import AboutUsPage from './../../../pages/aboutUsPage';
let menuIcon = require('../../../assets/common_assets/menu_button.png');
// let closeIcon = require('../../../assets/common_assets/close_icon.png'
import { menuData } from './data'

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

 const MenuLink = (props) => {

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const navigation = useNavigation();
    return (
        <View style={topNavStyles.linkContainer}>
            <TouchableOpacity
                style={topNavStyles.opacityContainer}
                onPress={ () =>navigation.navigate(props.linkName)
            }>
                <Image 
                    source={props.icon}
                    style={topNavStyles.linkIconStyle}
                    />
                <Text style={topNavStyles.linkTitleStyle}>{props.title}</Text>
            </TouchableOpacity>
        </View>
    )
}

const TopNavbar = () =>{

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const ref = useRef();
    const navigation = useNavigation();

    let hiddenMenu = topNavStyles.menuDisplayNone
    let visibleMenu = topNavStyles.menuTextContainer
    let visibleMenuMobile = topNavStyles.menuTextContainerMobile
    const [isVisible,setVisible] = useState(hiddenMenu);

    useEffect(() => {
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if ((isVisible == visibleMenuMobile) && ref.current && !ref.current.contains(e.target)) {
                setVisible(hiddenMenu)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isVisible])


    let sellerStyle = topNavStyles.sellerText
    let deliveryStyle = topNavStyles.deliveryText
    let aboutStyle = topNavStyles.aboutText

    if(window.location.pathname == "/seller/"){
        sellerStyle = topNavStyles.sellerRedText
    }else if(window.location.pathname == '/delivery/'){
        deliveryStyle = topNavStyles.deliveryRedText
    }else if(window.location.pathname == '/aboutus/'){
        aboutStyle = topNavStyles.aboutRedText
    }else{

    }
   
    const [refreshPage,setRefreshPage] = useState("");


    // media query for responsive view

    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:450,
    })

    const email = {
        '/seller': 'seller@ishopatoz.com', '/delivery': 'delivery@ishopatoz.com', '/aboutus': 'jobs@ishopatoz.com',
        '/shopping':'contact@ishopatoz.com'}

    console.log(window.location.pathname);




    let currentActive;
    if(window.location.pathname === "/shopping/"){
        currentActive = 0;
    }else if(window.location.pathname === "/seller/"){
        currentActive = 1;
    }else if(window.location.pathname === "/delivery/"){
        currentActive = 2
    }else if(window.location.pathname === "/aboutus/"){
        currentActive = 3;
    }

      const handleClick = (e) => {
        setActive(e);
    }
    const [activeLink,setActive] = useState(currentActive)

    if(isMobileDisplay){
        return (
            <View style={topNavStyles.navContainerMobile} ref={ref}>
                <TouchableOpacity style={topNavStyles.logoContainerMobile}>
                    <Image
                        source={require("../../../assets/buyers/ishop_logo.svg")}
                        style={topNavStyles.logoStyleMobile}
                        />
                </TouchableOpacity>
                <TouchableOpacity 
                    style={topNavStyles.menuContainerMobile}
                    onPress={()=>{
                        if(isVisible == hiddenMenu){
                            setVisible(visibleMenuMobile)
                        }else{
                            setVisible(hiddenMenu)
                        }
                    }}>
                    <Image
                        source={require('../../../assets/common_assets/menu_button.png')}
                        style={topNavStyles.menuLogoMobile}
                    />
                </TouchableOpacity>
                <View style={isVisible}>
                    <TouchableOpacity style={topNavStyles.closeIconContainer}
                         onPress={()=>{
                            if(isVisible == hiddenMenu){
                                setVisible(visibleMenuMobile)
                            }else{
                                setVisible(hiddenMenu)
                            }
                        }}
                    >
                        <Image
                                style={topNavStyles.closeIconStyle}
                                source={require("../../../assets/common_assets/close_icon.png")}/>
                    </TouchableOpacity>
                    {menuData.map(link => {
                        // let linkStyle =  activeLink === link.id ? topNavStyles.linkRedTitleStyle:topNavStyles.linkTitleStyle
                       return(
                            <View style={topNavStyles.linkContainer}>
                                <TouchableOpacity
                                    style={topNavStyles.opacityContainer}
                                    onPress={() => {
                                        handleClick(link.id),
                                        navigation.navigate(link.navName)
                                        // Linking.openURL(link.linkName)
                                    }}>
                                    <Image
                                        source={link.icon}
                                        style={topNavStyles.linkIconStyle}
                                    />
                                    <Text style={topNavStyles.linkTitleStyle}>{link.title}</Text>

                                </TouchableOpacity>
                            </View>
                        )
                })}
                    <TouchableOpacity 
                        style={topNavStyles.mailTextContainer}
                        onPress={() => {
                            Linking.openURL(`mailto:contact@ishopatoz.com`)
                        if(isVisible == hiddenMenu){
                            setVisible(visibleMenuMobile)
                        }else{
                            setVisible(hiddenMenu)
                        }
                    }}
                    >
                        <Image
                            style={topNavStyles.linkIconStyle}
                            source={require("../../../assets/common_assets/message_logo.png")}/>
                        <Text style={topNavStyles.contactMailText}>contact@ishopatoz.com</Text>
                    </TouchableOpacity>
                    </View>
                </View>
        )
    }

    
    return (
        <View  style={topNavStyles.navContainer}>
            <TouchableOpacity
                style={topNavStyles.logoContainer}
                onPress={() => {
                    Linking.openURL("/shopping/")
                    // setRefreshPage("refresh")
                    // navigation.navigate('buyer')

                }}

            >    
                <Image 
                    source={require("../../../assets/buyers/ishop_logo.svg")}
                    style={topNavStyles.logoStyle}
                />
            </TouchableOpacity>
            <Image
                source={require("../../../assets/buyers/india.png")}
                style={topNavStyles.flagStyle}
            /> 
           <Text style={topNavStyles.stripTextStyle}>Made in India  |  Built for 1.2+ Billion Indians</Text>
            <TouchableOpacity 
                style={topNavStyles.textLinkContainer}
                onPress={() => {
                    // navigation.navigate('seller');
                    Linking.openURL('/seller/')
                }}
                ><Text
                 style={sellerStyle}
                 >Join as a Seller</Text>
            </TouchableOpacity>
            <TouchableOpacity
                style={topNavStyles.textLinkContainer}
                onPress={() =>{
                    Linking.openURL("/delivery/")
                 }}
                >
                   <Text
                    style={deliveryStyle}> Join as a Delivery Partner</Text>
            </TouchableOpacity>
            <TouchableOpacity 
                style={topNavStyles.textLinkContainer}
                onPress={() => {
                    Linking.openURL('/aboutus/')
                }}
               >
                <Text style={aboutStyle}>About Us</Text>
            </TouchableOpacity>
                {/* <TouchableOpacity 
                    onPress={()=>{
                        if(isVisible == hiddenMenu){
                            setVisible(visibleMenu)
                        }else{
                            setVisible(hiddenMenu)
                        }
                    }}>
                    <Image
                        source={require('../../../assets/common_assets/menu_button.png')}
                        style={topNavStyles.menuLogo}
                    />
                </TouchableOpacity>
                <View style={isVisible}>
                    <Text 
                        style={topNavStyles.menuText}
                        onPress={()=>{navigation.navigate('aboutUs')}}
                        >About Us</Text>
                    <Text style={topNavStyles.menuText}>Join Us</Text>
                    <Text style={topNavStyles.menuText}>Help Center</Text>
                </View> */}
        </View>
    )
}




export default TopNavbar
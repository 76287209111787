import * as React from 'react'
import {View,Image,Text} from 'react-native'

import {moreStyle} from './moreStyles'
import { useMediaQuery } from 'react-responsive';
import FlexComponent from '../../commonComponents/FeatureFlex';
import { sellerFlexData } from '../../commonComponents/FeatureFlex/data';

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

const More = () => {

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:450
    })

    if(isMobileDisplay){
        return (
            <View style={moreStyle.moreContainerMobile}   >
                <View style={moreStyle.topTextContainerMobile}>
                   <Text style={moreStyle.whyIshopTextMobile}>Why iShopAtoZ?</Text>
                        <View
                            style={moreStyle.blockSpaceMobile}
                        />
                </View>
                <Text 
                    style={moreStyle.moreTextMobile}> 
                    Sell more{"\n"}
                    <Image style={moreStyle.colorArrowStyleMobile} 
                    source={require("../../../assets/buyers/colorful_arrow.png")}/> 
                    {"\n"}Grow more{"\n"}
                    <Image style={moreStyle.colorArrowStyleMobile} 
                    source={require("../../../assets/buyers/colorful_arrow.png")}/> 
                    {"\n"}Earn more
                </Text>
                <View style={moreStyle.allCardContainerMobile} >
                   <FlexComponent
                        flexData={sellerFlexData}
                   />
                </View>
            </View>
        )
    }

    return (
        <View style={moreStyle.moreContainer} nativeID='sellerWhyUs'  >
            <View style={moreStyle.topTextContainer}>
               <Text style={moreStyle.whyIshopText}>Why iShopAtoZ?</Text>
                    <View
                        style={moreStyle.blockSpace}
                    />
            </View>
            <Text style={moreStyle.moreText}> Sell more <Image style={moreStyle.colorArrowStyle} source={require("../../../assets/buyers/colorful_arrow.png")}/> Grow more <Image style={moreStyle.colorArrowStyle} source={require("../../../assets/buyers/colorful_arrow.png")}/> Earn more</Text>
            <View  style={moreStyle.allCardContainer} >
               <FlexComponent
                    flexData={sellerFlexData}
               />
                <View nativeID='testimonials'></View>
            </View>
        </View>
    )
}

export default More
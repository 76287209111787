import * as React from 'react'
import {View,Image} from 'react-native'
import {bannerStyle} from './bannerStyle'
import { useMediaQuery } from 'react-responsive';

const SellerBanner = () => {
    
    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:450
    })

    if(isMobileDisplay){
        return(
            <View style={bannerStyle.bannerContainerMobile}>
                <Image
                    source={require("../../../assets/sellers/grid_banner.png")}
                    style={bannerStyle.bannerImageMobile}
                />
            </View>
        )
    }

    return(
        <View style={bannerStyle.bannerContainer}>
            <Image
                source={require("../../../assets/sellers/grid_banner.png")}
                style={bannerStyle.bannerImage}
            />
            <View nativeID='sellerWhyUs'/>
            <View/>
            <View/>
            <View/>
            
        </View>
    )
}

export default SellerBanner
import * as React from 'react'
import { useState } from 'react';
import {View,
    Text,
    Image,
    Animated,
    NativeEventEmitter,
} from 'react-native'
import {bottomNavStyles} from './BottomNavStyles'
import {useRef} from 'react';
import {useHover,useFocus,useActive} from 'react-native-web-hooks'
import {A} from '@expo/html-elements'
import '@expo/match-media'
import { useMediaQuery } from 'react-responsive';
import { TouchableOpacity } from 'react-native-web';

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';


 

const BottomNavbar = (props) => {

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:600
    })

    

    const [defaultColor,setColor] = useState(bottomNavStyles.linkStyles)
    const [visibility,setVisibility] = useState(bottomNavStyles.navContainer)

    const [activeLink,setActive] = useState(props.links[0].id)
    // const isHovered = useHover(ref)



    // let lastScrollTop = 0;
    // window.addEventListener('scroll',(event)=>{
            
    //     let st = window.pageYOffset || document.documentElement.scrollTop;
    //     if(st > lastScrollTop){
    //         setVisibility(bottomNavStyles.displayNone)
    //     }else{
    //         setVisibility(bottomNavStyles.navContainer)
    //     }  
    // })

    // window.addEventListener('scroll',_.debounce(()=>{
    //     setVisibility(bottomNavStyles.navContainer)
    // }))

    const handleClick = e => {
        setActive(e)
    }
    
    if(isMobileDisplay){
        return(
            <View style={bottomNavStyles.navContainerMobile}
            >
                <View style={bottomNavStyles.navSubContainerMob}>
                    <Image 
                        source={require("../../../assets/buyers/india.png")}
                        style={bottomNavStyles.flag}    
                        />
                    <Text style={bottomNavStyles.madeInIndiaText}>Made in India | Built for 1.2+ Billion Indians</Text>
                </View>
            </View>
        )
    }

    return (
         <View style={visibility} >
         <View style={bottomNavStyles.linkContainer}>
            {props.links.map(button=>{
                let LinkStyle = activeLink === button.id ? bottomNavStyles.activeStyleText:bottomNavStyles.linkStyles
                return<TouchableOpacity onPress={()=>handleClick(button.id)
                }>
                         <A
                            accessibilityRole='link'
                            style={LinkStyle} 
                            href={button.link}
                            nativeID={button.id}
                    >
                        {button.text}
                    </A>
                    </TouchableOpacity>
        })}
        </View>
    </View>
    )
}

export default BottomNavbar
import * as React from 'react'
import  {View, Image,Text} from 'react-native'
import {whyUsStyle} from './whyUsStyles'
import { useMediaQuery } from 'react-responsive';
import FlexComponent from '../../commonComponents/FeatureFlex';
import { deliveryFlexData } from '../../commonComponents/FeatureFlex/data';

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

const cardData = [
    {
        img:require("../../../assets/delivery/location_icon.png"),
        boldText:"Pick a Location",
        lightText:"Be at your preferred\nlocation",
        lightTextMobile:"Be at your preferred location"
    },
    {
        img:require("../../../assets/delivery/time_icon.png"),
        boldText:"Flexi Hours",
        lightText:"You decide your working\nhours",
        lightTextMobile:"You decide your working hours"
    },
    {
        img:require("../../../assets/delivery/money_icon.png"),
        boldText:"Grow Together",
        lightText:"Contribute to the\ncommunity & earn",
        lightTextMobile:"Contribute to the community & earn"
    }
]

const WhyUs = () => {

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:450
    })

    if(isMobileDisplay){
        return (
            <View style={whyUsStyle.whyUsContainerMobile}>
                <Text style={whyUsStyle.topBoldTextMobile}>Why us?</Text>
                <Text style={whyUsStyle.topLightTextMobile}>We help you enrich your life with ease{"\n"}by providing you an opportunity for{"\n"}serving the community around you</Text>
                <View style={whyUsStyle.cardContainerMobile}>
                    {cardData.map(card=>{
                        return <View style={whyUsStyle.cardMobile}>
                                <View style={whyUsStyle.iconContainerMobile}>
                                    <Image source={card.img}
                                        style={whyUsStyle.iconsStyleMobile}
                                    />
                                </View>
                                <Text style={whyUsStyle.cardBoldTextMobile}>{card.boldText}</Text>
                                <Text style={whyUsStyle.cardLightTextMobile}>{card.lightTextMobile}</Text>
                            </View>
                    })}
                </View>
            </View>
        )
    }

    {/*}
    return (
        <View style={whyUsStyle.whyUsContainer}>
            <Text style={whyUsStyle.topBoldText}>Why us?</Text>
            <Text style={whyUsStyle.topLightText}>We help you enrich your life with ease by providing you an{"\n"}opportunity for serving the community around you</Text>
            <View style={whyUsStyle.cardContainer}>
                {cardData.map(card=>{
                    return <View style={whyUsStyle.card}>
                            <View style={whyUsStyle.iconContainer}>
                                <Image source={card.img}
                                    style={whyUsStyle.iconsStyle}
                                />
                            </View>
                            <Text style={whyUsStyle.cardBoldText}>{card.boldText}</Text>
                            <Text nativeID='joinUs' style={whyUsStyle.cardLightText}>{card.lightText}</Text>
                        </View>
                })}
            </View>
        </View>
    )
    */}

    return (
        <View nativeID='whyUs' style={whyUsStyle.whyUsContainer}>
            <Text style={whyUsStyle.topBoldText}>Why us?</Text>
            <Text style={whyUsStyle.topLightText}>We help you enrich your life with ease by providing you an opportunity for serving the community around you</Text>
            {/*<View style={whyUsStyle.cardContainer}>*/}
            <View style={whyUsStyle.allCardContainer}>
               <FlexComponent
                    flexData={deliveryFlexData}
               />
            </View>
            <View nativeID='testimonials'/>
        </View>
    )

}

export default WhyUs;
import * as React from 'react'
import {
    View,
    Text,
    Image,
    TouchableOpacity,
    Linking
} from 'react-native'
import {benefitStyles} from "./benefitStyles"
import { useMediaQuery } from 'react-responsive'

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

const cards = [
                {text:"Best Discount",img:require("../../../assets/buyers/discount_logo.png")},
                {text:"Quick Delivery",img:require("../../../assets/buyers/delivery_logo.png")},
                {text:"Assured Quality",img:require("../../../assets/buyers/quality_logo.png")},
                {text:"Painless Return",img:require("../../../assets/buyers/return_logo.png")},
            ]


const Benefits = () => {

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:450
    })

    if(isMobileDisplay){
        return(
            <View style={benefitStyles.mobileContainer}>
                <View style={benefitStyles.rightTopTextMobile}>
                    <Text style={benefitStyles.whyIshopTextMobile}>Why iShopAtoZ?</Text>
                    <View
                        style={benefitStyles.blockSpace}
                    />
                </View>
                <Text style={benefitStyles.rightTextMobile}>We enable the most{"\n"}satisfying shopping{"\n"}experience</Text>               
                <Text style={benefitStyles.rightTextTwoMobile}>
                    At iShopAtoZ, consumers come first & we always go an extra mile to deliver an incredible consumer experience. Moreover, we charge 0% commission from the seller, ensuring that you get the items for the actual price offered at the store or even lesser.
                </Text>
                <Text  nativeID='categories' style={benefitStyles.suggestionText}>Feel free to share your suggestions at{"\n"}
                        <TouchableOpacity
                                
                                style={benefitStyles.mailTextMobile}
                                onPress={()=>Linking.openURL(`mailto:contact@ishopatoz.com`)}
                                >
                                    contact@ishopatoz.com</TouchableOpacity>
                    </Text>     

                <View style={benefitStyles.mobileBenefitCard}>  
                    <Text style={benefitStyles.topLeftText}>We have the features that every{"\n"}shopper looks for</Text>
                    <View style={benefitStyles.flexCardContainerMobile}>
                        {cards.map(card=>{
                            return <View style={benefitStyles.blockCardContainerMobile}> 
                                                <Image
                                                    style={benefitStyles.circleLogo}
                                                    source={card.img}
                                                />
                                            <Text style={benefitStyles.cardText}>{card.text}</Text>
                                    </View>
                        })}
                    </View>
                </View>           

            </View>
        )
    }

    return (
        <View  style = {benefitStyles.benefitContainer}>
            <View style={benefitStyles.rightView}>
                <View style={benefitStyles.rightTopText}>
                    <Text style={benefitStyles.whyIshopText}>Why iShopAtoZ?</Text>
                    <View
                        style={benefitStyles.blockSpace}
                    />
                </View>
                <Text style={benefitStyles.rightTextOne}>We enable most satisfying shopping experience</Text>               
                <Text style={benefitStyles.rightTextTwo}>
                        At iShopAtoZ, consumers come first & we always go an extra mile to deliver an incredible consumer experience. Moreover, we charge 0% commission from the seller, ensuring that you get the items for the actual price offered at the store or even lesser.
                </Text>
                <View style={benefitStyles.rightBottomContainer}>
                    <Text  nativeID='categories' style={benefitStyles.rightTextTwo}>Feel free to share your suggestions at <TouchableOpacity
                                
                                style={benefitStyles.mailText}
                                onPress={()=>Linking.openURL(`mailto:contact@ishopatoz.com`)}
                                >
                                contact@ishopatoz.com</TouchableOpacity>
                    </Text>
                    <View style={benefitStyles.playLogoContainer} >
                        <Image
                        style={benefitStyles.playLogo}
                        source={require("../../../assets/buyers/play_button.png")}
                        />
                    </View>
                </View>
            </View>
        </View>
    )
}


export default Benefits;
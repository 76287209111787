import { StyleSheet } from 'react-native';
import GoToTop from './index';

export const GoToTopStyle = StyleSheet.create({
    goToTopCont:{
        marginLeft:"92vw",
        marginRight:"auto",
        // marginRight:"5vw",
        marginTop:"75vh",
        top:"59.1px",
        elevation:1000,
        width:"52px",
        height:"52px",
        position: "fixed",
        filter: "drop-shadow(0px 1.60683px 1.60683px rgba(0, 0, 0, 0.25))",
        flex: "none",
        order: 1,
        flexGrow: 0,
        zIndex: 9999999
    },
    
    goToTop:{
        width:"51x",
        height:"51px",
        filter: "drop-shadow(0px 1.60683px 1.60683px rgba(0, 0, 0, 0.25))",
    
    /* Inside auto layout */
        flex: 1,
        order: 1,
        flexGrow: 1,
        margin: "6.42734px 0px",
        resizeMode:"contain",
        // zIndex:6000
       
    },
    
    nonDisplay:{
        display:"none"
    }
})


import { StyleSheet } from "react-native";
import {Raleway, Raleway_400Regular, Raleway_600SemiBold} from '@expo-google-fonts/raleway'

export const easyStyle = StyleSheet.create({

    easyContainer:{
        width:"100%",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"10vh"
    },

    topBoldText:{
        // width: "1184px",
        width:" 86.67vw",
        height: "75px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "64px",
        lineHeight: "75px",
        color: "#2C2E2F"

    },
    topLightText:{
        // width: "1184px",
        height: "46px",
        marginTop:"4.85vh",
        marginLeft: "200px",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "20px",
        lineHeight: "23px",
        fontFeatureSettings: "'salt' on, 'liga' off",
        color: "#2C2E2F",
        textAlign:"left"

    },

    subContainer:{
        display:"flex",
        flexDirection:"row",
        height: "296px",
        backgroundColor: "#F2F9F9",
        width:"100%",
        marginRight:"auto",
        marginLeft:"auto",
        alignItems:"center",
        textAlign:"center",
        marginTop:"2vh"
    },

    leftContainer:{
        width:"60%",
        alignItems:"center",
    },
    rightContainer:{
        width:"40%",
    },
    itemContainer:{
        display:"flex",
        flexDirection:"row",
        textAlign:"center",
        textAlign:"center",
        margin:"5%"
    },

    joinText:{
        width: "233px",
        height: "23px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "20px",
        lineHeight: "23px",
        color: "#008489",
        textAlign:"center"

    },
    stepContainer:{
        // width:"16vw",
        textAlign:"center",
        marginLeft:"auto",
        marginRight:"auto",
        width:"40%",
        justifyContent:"space-between",
        margin:"5%"
    },
    stepIcons:{
        width: "27px",
        height: "34px",
        resizeMode:"contain",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center"
    },
    stepText:{
        // width: "167px",
        height: "23px",
        // left: 539px;
        // top: 2831.12px;

        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "20px",
        lineHeight: "23px",
        fontFeatureSettings: "'salt' on, 'liga' off",
        color: "#2C2E2F"

    }

})
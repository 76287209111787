import * as React from 'react'
import { useState } from 'react'
import {
    View,
    Text,
    Image,
    TouchableHighlight,
    TextInput,
    Linking,
    TouchableOpacity
} from 'react-native'
import {footerStyle} from "./footerStyle"
import { useMediaQuery } from 'react-responsive'
import { socialImg, contactItem, VerticalText, VerticalText02, VerticalTextMobile, serviceableCities,comingSoon } from './data'

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

let  expand = require("../../../assets/common_assets/expand.png")
let collapse = require("../../../assets/common_assets/collapse.png") 
let alterView = footerStyle.collapseView
const privacyPolicy = "https://prod-ishopatoz-public.s3.ap-south-1.amazonaws.com/privacy/website/website_privacy_policy.html"
const termsCondition = "https://prod-ishopatoz-public.s3.ap-south-1.amazonaws.com/privacy/website/website_terms_and_conditions.html"

const nullArr = []
let isAddressView =  true;
let address = `Manipal County Rd,{"\n"}Singasandra, Bengaluru,{"\n"}Karanataka - 560 068`

const MobileList = (props) => {

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const [icon,changeIcon] = useState(expand)
    const  [closedView, switchView] = useState(alterView)

    return (
        <View>
            <View style={footerStyle.titleContainer}>
                <TouchableOpacity
                    onPress={()=>
                        {
                            if(closedView == alterView){
                                switchView(footerStyle.expandView)
                                changeIcon(collapse)
                            }else{
                                switchView(alterView)
                                changeIcon(expand)
                            }
                        }}
                >
                    <Image style={footerStyle.stateIcon} source = {icon}/>
                </TouchableOpacity>
                <Text style={footerStyle.titleStyle}>{props.title}</Text>
            </View>
            <View style={closedView}>
               {props.list.map(item=>{
                   return <Text style={footerStyle.listText}>{item.text}</Text>
               })}

               



               <TouchableOpacity onPress={()=>Linking.openURL(props.link)}>
                   <Text style={footerStyle.linkText}>{props.linkText}</Text>
               </TouchableOpacity>
               <View style={footerStyle.socialLogoContainerMobile}>
                {props.socialImg.map(items=>{
                        return <TouchableOpacity
                            onPress={()=>Linking.openURL(items.link)}
                            >
                            <Image
                                style={footerStyle.contactUsLogo}
                                source={items.img}/>
                        </TouchableOpacity>
                    })}
                </View>
            </View>
        </View>
    )
}

const Footer = (props) =>{

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const [icon,changeIcon] = useState(expand)
    const  [closedView, switchView] = useState(alterView)


    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:600
    })

    //Mobile view

    if(isMobileDisplay){
        return (
            <View style={footerStyle.footerContainerMobile}>
                <Text style={footerStyle.updatedText}>Stay Updated</Text>
                <View  style={footerStyle.inputContainerMobile}>
                    <TextInput
                        placeholder="Enter your email"
                        type="email"
                        style={footerStyle.userInputMobile}
                    />
                    <View style={footerStyle.sendIconContainerMobile}>
                        <Image 
                            source={require("../../../assets/common_assets/send_icon.png")}
                            style={footerStyle.sendIconMobile}
                        />
                    </View>
                </View>
                <View style={footerStyle.listContainer}>
                    {/* temporary code, to be removed after effective implementation  */}
                    <View>
                        <View style={footerStyle.titleContainer}>
                            <TouchableOpacity
                                onPress={()=>
                                    {
                                        if(closedView == alterView){
                                            switchView(footerStyle.expandView)
                                            changeIcon(collapse)
                                        }else{
                                            switchView(alterView)
                                            changeIcon(expand)
                                        }
                                    }}
                            >
                                <Image style={footerStyle.stateIcon} source = {icon}/>
                            </TouchableOpacity>
                            <Text style={footerStyle.titleStyle}>Contact Us</Text>
                        </View>
                        <View style={closedView}>
                            <View style={footerStyle.addressContainer}>
                                    <View style={footerStyle.contactContainerMobile}>
                                        <Image
                                            source={require("../../../assets/common_assets/gps_logo.png")}
                                            style={footerStyle.contactUsLogoMobile}
                                        />
                                            <Text style={footerStyle.lightAddressTextMobile}>Manipal County Rd,{"\n"}Singasandra, Bengaluru,{"\n"}Karanataka - 560 068</Text>
                                    </View>
                                    <View style={footerStyle.contactContainerMobile}>
                                        <Image
                                            source={require("../../../assets/common_assets/message_logo.png")}
                                            style={footerStyle.contactUsLogoMobile}
                                        />
                                            <TouchableOpacity 
                                            style={footerStyle.lightTextUnderline}
                                            onPress={()=>Linking.openURL(`mailto:${props.contactMailLink}`)}
                                            ><Text style={footerStyle.lightTextMobile}>{props.contactMailText}</Text></TouchableOpacity>
                                    </View>
                            </View>
                        </View>
                    </View>
                    
                    <MobileList 
                        title="Social Links"
                        images={socialImg}
                        list={nullArr}
                        socialImg={socialImg}
                        />
                    <MobileList
                        title="Serviceable Cities"
                        list={serviceableCities}
                        socialImg={nullArr}
                        />
                    <MobileList
                        title="Categories"
                        list={VerticalTextMobile}
                        socialImg={nullArr}
                        />
                    <MobileList title="Privacy Policy" 
                        list={nullArr}
                        link={privacyPolicy}
                        linkText="Privacy Policy"
                        socialImg={nullArr}
                    />
                    <MobileList title="Terms & Conditions"
                        list={nullArr}
                        link={termsCondition}
                        linkText="Terms & Condition"
                        socialImg={nullArr}
                     />
                </View>
                <Text style={footerStyle.downloadTextMobile}>{props.downloadText}</Text>
                <TouchableOpacity
                    style={footerStyle.mobilePlayContainer}
                    onPress={()=>Linking.openURL(props.appUrl)}
                >
                    <Image
                        style={footerStyle.googlePlayImgMobile}
                        source={require('../../../assets/common_assets/google_play.png')}/>
                </TouchableOpacity>

                <Text style={footerStyle.ishopTextMobile}>iShopAtoZ</Text>
                <Text style={footerStyle.endToEndText}>End-to-end E-Commerce platform for MSMEs</Text>
                <Text style={footerStyle.copyrightText}>Copyright © 2019-2022 iShopAtoZ Pvt. Ltd.</Text>
            </View>
            )
    }




    //Desktop view
    return (
        <View style={footerStyle.allFooterContainer}>
        <View style={footerStyle.footerContainer} nativeID='contact'>
            <View style={footerStyle.rootContactContainer}>
                <Text style={footerStyle.boldText}>Contact Us</Text>
                <View style={footerStyle.lightTextContainer}>
                {/* {contactItem.map(item=>{
                    return <View style={footerStyle.contactContainer}>
                             <Image
                                 source={item.img}
                                 style={footerStyle.contactUsLogo}
                            />
                                <Text style={footerStyle.lightText}>{item.text}</Text>
                        </View>
                })} */}

                        <View style={footerStyle.contactContainer}>
                             <Image
                                 source={require("../../../assets/common_assets/gps_logo.png")}
                                 style={footerStyle.contactUsLogo}
                            />
                                <Text style={footerStyle.lightText}>Manipal County Rd,{"\n"}Singasandra, Bengaluru,{"\n"}Karanataka - 560 068</Text>
                        </View>
                        <View style={footerStyle.contactContainer}>
                             <Image
                                 source={require("../../../assets/common_assets/message_logo.png")}
                                 style={footerStyle.contactUsLogo}
                            />
                                <TouchableOpacity 
                                style={footerStyle.lightTextUnderline}
                                onPress={()=>Linking.openURL(`mailto:${props.contactMailLink}`)}
                                ><Text style={footerStyle.lightTextUnderline}>{props.contactMailText}</Text></TouchableOpacity>
                        </View>
                </View>
                <View style={footerStyle.socialLogoContainer}>
                {socialImg.map(item=>{                    
                    return<TouchableOpacity
                        onPress={() => Linking.openURL(item.link)}   
                        style={footerStyle.contactUsLogo}
                    >
                            <Image
                                    style={footerStyle.contactUsLogo}
                                    source={item.img}
                                    
                                />
                        </TouchableOpacity>
                })}
                </View>
            </View>
            <View style={footerStyle.verticalContainer01}>
                <Text style={footerStyle.boldText}>Serviceable Cities</Text>
                <Text style={footerStyle.lightTextCity01}>Bengaluru</Text>
                <Text style={footerStyle.boldText}>Arriving Soon</Text>
                <Text style={footerStyle.lightTextCity02}>Delhi    Mumbai    Chennai</Text>
                <Text style={footerStyle.lightTextCity02}>Kolkata    Hyderabad</Text>
                <Text style={footerStyle.lightTextCity02}>Pune    Ahmedabad</Text>
            </View>
            <View style={footerStyle.verticalContainer02_01}>
                <Text style={footerStyle.boldText}>Categories</Text>
                <View style={footerStyle.verticalItem}>
                    {VerticalText.map(item=>{
                        return <Text style={footerStyle.verticalsText}>{item.text}</Text>
                    })}
                </View>
            </View>
            <View style={footerStyle.verticalContainer02_02}>
                <Text style={footerStyle.boldText}>&nbsp;</Text>
                <View style={footerStyle.verticalItem}>
                    {VerticalText02.map(item=>{
                        return <Text style={footerStyle.verticalsText}>{item.text}</Text>
                    })}
                </View>
            </View>
            <View>
                <Text style={footerStyle.boldText}>iShopAtoZ</Text>
                <Text style={footerStyle.ishopText}>End-to-end E-Commerce platform for MSMEs</Text>
                <Text style={footerStyle.stayUpdated}>Stay Updated</Text>
                <Text style={footerStyle.downloadText}>{props.downloadText}</Text>
                <TouchableOpacity
                    onPress={()=>Linking.openURL(props.appUrl)}
                >
                    <Image
                        style={footerStyle.googlePlayImg}
                        source={require('../../../assets/common_assets/google_play.png')}/>
                </TouchableOpacity>
                <View  style={footerStyle.inputContainer}>
                    <TextInput
                        placeholder="Enter your email"
                        type="email"
                        style={footerStyle.userInput}
                    />
                    <View style={footerStyle.sendIconContainer}>
                        <Image 
                            source={require("../../../assets/common_assets/send_icon.png")}
                            style={footerStyle.sendIcon}
                        />
                    </View>
                </View>
            </View>
        </View>
        <View
            style={footerStyle.blockSpace}
        />
        <View style={footerStyle.lowerFooterContainer}>
                    <TouchableOpacity 
                        style={footerStyle.opacityContainer}
                        onPress={() => Linking.openURL(privacyPolicy)
                    }>
                        <Text style={footerStyle.legalText}>Privacy Policy</Text>
                    </TouchableOpacity>
                    <TouchableOpacity 
                        style={footerStyle.opacityContainer}
                        onPress={() => Linking.openURL(termsCondition)}
                    >
                        <Text  style={footerStyle.legalText}>Terms & Conditions</Text>
                    </TouchableOpacity>
                    <Text style={footerStyle.cyear}>Copyright © 2019-2022 iShopAtoZ Pvt. Ltd</Text>
        </View>
        </View>
        
    )
}

export default Footer
import { Raleway_400Regular, Raleway_500Medium, Raleway_600SemiBold, Raleway_700Bold } from '@expo-google-fonts/raleway'
import {StyleSheet} from 'react-native'
//import {Raleway} from '@expo-google-fonts/raleway'

export const topNavStyles = StyleSheet.create({
    navContainer:{
        display:'flex',
        width:"100%",
        alignItems:'center',
        top:'0',
        height:"64px",
        backgroundColor:"white",
        position: "fixed",
        flex: '1',
        flexDirection:'row',
        zIndex:1,
        borderBottomColor:"#EEEEEE",
        borderBottomWidth:"0.1vh"
    },
    logoStyle:{
        // position: "absolute",
        // width: "94.53px",
        // height: "50px",
        // left: "3vw",
        resizeMode:"contain",
        width: "5.625vw",
        height: "6.369vh",

    },


    logoContainer:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px",
        position: "absolute",
        // width: "94.53px",
        // height: "50px",
        width: "5.625vw",
        height: "6.369vh",
        // marginLeft: "140px",
        marginLeft:"8.24vw",
        top: "2.134vh",
        alignContent:"center"

    },  

    navContainerMobile:{
        display:'flex',
        width:"100%",
        alignItems:'center',
        top:'0',
        height:"64px",
        backgroundColor:"white",
        position: "fixed",
        flex: '1',
        flexDirection:'row',
        zIndex:1,
    },
    logoStyleMobile:{
        // position: "absolute",
        // width: "94.53px",
        // height: "50px",
        // left: "3vw",
        resizeMode:"contain",
        width: "72px",
        height: "38.79px",

    },


    logoContainerMobile:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px",
        position: "absolute",
        // width: "94.53px",
        // height: "50px",
        width: "72px",
        height: "38.79px",
        // marginLeft: "140px",
        marginLeft:"10.24vw",
        top: "13px",
        alignContent:"center"

    },  


    flagStyle:{
        position: "absolute",
        width: "1.09375vw",
        height: "1.532vh",
        // left: "341px",
        left:"20.963vw",
        top: "4.51vh"
        // top:"31.64px"
    },
    stripTextStyle:{
        position: "absolute",
        width: "22.5vw",
        height: "2.627vh",
        // left: "361px",
        left:"22.428vw",
        top: "3.941vh",
        fontFamily: 'Raleway_500Medium',
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "1.09375vw",
        // left: "16px",
        textAlign: "center",
        color: "#2C2E2F"
    },

    textLinkContainer:{
        alignItems:"center",
        justifyContent:"center",
        // display:"none"
    },

    sellerText:{
        position: "absolute",
        width: "8.828vw",
        height: "3.120vh",
        // left: "1243px",

        // left:"900px",
        // left:"65.88579795021963vw",
        left:"60vw",
        // top: "22px",
        alignItems:"center",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "1.25vw",
        lineHeight: "3.120vh",
        /* identical to box height */
        color: "#2C2E2F",

    },

    sellerRedText:{
        position: "absolute",
        width: "8.828vw",
        height: "3.120vh",
        // left: "1243px",

        // left:"900px",
        // left:"65.88579795021963vw",
        left:"60vw",
        // top: "22px",
        alignItems:"center",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "1.25vw",
        lineHeight: "3.120vh",
        /* identical to box height */
        color: "#FF002A",

    },

    deliveryText:{
        position: "absolute",
        width: "15.922vw",
        height: "3.120vh",
        // left: "1388px",
        // left:"1050px",
        // left:"76.86676427525622vw",
        left:"70vw",
        // top: "22px",
        alignItems:"center",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "1.25vw",
        lineHeight: "3.120vh",
        /* identical to box height */
        color: "#2C2E2F",

    },

    deliveryRedText:{
        position: "absolute",
        width: "15.922vw",
        height: "3.120vh",
        // left: "1388px",
        // left:"1050px",
        // left:"76.86676427525622vw",
        left:"70vw",
        // top: "22px",
        alignItems:"center",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "1.25vw",
        lineHeight: "3.120vh",
        /* identical to box height */
        color: "#FF002A",

    },

    aboutText:{
        position: "absolute",
        width: " 5.46875vw",
        height: "3.120vh",
        // left: "1388px",
        // left:"1050px",
        // left:"76.86676427525622vw",
        left:"87vw",
        // top: "22px",
        alignItems:"center",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "1.25vw",
        lineHeight: "3.120vh",
        /* identical to box height */
        color: "#2C2E2F",

    },

    aboutRedText:{
        position: "absolute",
        width: " 5.46875vw",
        height: "3.120vh",
        // left: "1388px",
        // left:"1050px",
        // left:"76.86676427525622vw",
        left:"87vw",
        // top: "22px",
        alignItems:"center",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "1.25vw",
        lineHeight: "3.120vh",
        /* identical to box height */
        color: "#FF002A",

    },

    menuLogo:{
        // position: "absolute",
        // left: "88vw",
        left:"65vw",
        top: "25%",
        bottom: "25%",
        resizeMode:"contain",
        height:"24px",
        width:"24px",
        float:"right",
        alignContent:"center",
        alignItems:"center",
        zIndex: "20",
    },

   
    
    menuTextContainer:{
        width: "16vw",
        height: "25vh",
        left: "0px",
        top: "10vh",
        backgroundColor: "#FFFFFF",
        borderRadius: "16px",
        marginTop:"38vh",
        float:"right",
        marginLeft:"80vw",
        backgroundColor:"#FFFFFF",
        zIndex:"16",
        display:"flex",
    },
    menuText:{
        width: "100%",
        height: "19px",
        left: "0px",
        top: "0px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "19px",
        /* identical to box height */
        color: "#2C2E2F",
        /* Inside Auto Layout */
        flex: "none",
        order: "0",
        flexGrow: "0",
        margin: "16px",
        zIndex:"1000"
    },

    menuDisplayNone:{
        display:"none",
    },


    //responsive css 
    menuContainerMobile:{
        left:"85vw",
        top: "25%",
        bottom: "25%",
        resizeMode:"contain",
        height:"24px",
        width:"24px",
        float:"right",
        alignContent:"center",
        alignItems:"center",
        justifyContent:"center",
        zIndex: "20",
    },
    menuLogoMobile:{
        // position: "absolute",
        // left: "88vw",
        // left:"85vw",
        // top: "25%",
        bottom: "60%",
        resizeMode:"contain",
        height:"24px",
        width:"24px",
        float:"right",
        alignContent:"center",
        alignItems:"center",
        zIndex: "1000",
    
        // display:"none"
    },

    menuTextContainerMobile:{
        width: "80%",
        height:"67vh",
        marginLeft: "-6vw",
        marginTop: "35%",
        backgroundColor: "#FFFFFF",
        zIndex: "20",
        float:"right",
        alignContent:"center",
        // alignItems:"center",
        justifyContent:"center",
    },

    betaText:{
        display:"none"
    },

     closeIconContainer:{
        width:"25px",
        height:"25px",
        // resizeMode:"contain",
        marginTop:"16vh",
        marginRight:"35px",
        textAlign:"right",
        // marginLeft:"60vw"
        marginLeft:"auto"
    },

    closeIconStyle:{
        width:"24px",
        height:"24px",
        resizeMode:"contain",
        // marginTop:"25px"
    },

    opacityContainer:{
        display:"flex",
        flexDirection:"row",
        width:"75%",
        justifyContent:"flex-start",
        alignItems:"center",
        marginLeft:"11%"
        // justifyContent:"e"
    },
  
    linkContainer:{
        // display:"flex",
        // flexDirection:"row",
        height:"6vh",
        width:"100%",
        borderBottom:"#000000 1px"
    },
    linkIconStyle:{
        resizeMode:"contain",
        width:"20.72px",
        height:"17px"
    },
    linkTitleStyle:{
        // position: absolute;
        marginLeft: "10.56%",
        right: "25.83%",
        // top: 18.89%""
        // bottom: 77.89%;
        whiteSpace: "nowrap",

        /* Medium-Styles/Medium-Bold */
        fontFamily: 'Raleway_700Bold',
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "180%",
        /* identical to box height, or 29px */

        color: "#000000"

    },

    linkRedTitleStyle: {
        // position: absolute;
        marginLeft: "10.56%",
        right: "25.83%",
        // top: 18.89%""
        // bottom: 77.89%;
        whiteSpace: "nowrap",

        /* Medium-Styles/Medium-Bold */
        fontFamily: 'Raleway_700Bold',
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "180%",
        /* identical to box height, or 29px */

        color:"#FF0000"

    },

    mailTextContainer:{
        width:"50vw",
        height:"20px",
        marginLeft:"10.56%",
        display:"flex",
        flexDirection:"row",
        width:"60%",
        alignItems:"center",
        marginTop:"8vh"
        // marginLeft:"auto",
        // marginRight:"auto",
        // textAlign:"center"
    },
    contactMailText:{
        // position: static;
        // width: "176px",
        height: "19px",
        // marginTop: "2.5px",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "19px",
        /* identical to box height */
        color: "#000000",

        /* Inside auto layout */
        flex: "none",
        order: 1,
        flexGrow: 0,
        margin: "0px 8px",
        textDecorationLine:"underline",
        marginLeft:"10.56%"
    }

    


    

})
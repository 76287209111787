export const menuData = [
    {
        id:0,
        title:"Shopping",
        linkName:"/shopping/",
        navName:"shopping",
        icon:require('../../../assets/common_assets/shopping_icon.png')
    },
    {
        id:1,
        title:"Join as a Seller",
        linkName:"/seller/",
        navName:"seller",
        icon:require('../../../assets/common_assets/seller_icon.png')
    },
    {
        id:2,
        title:"Join as a Delivery Partner",
        linkName:"/delivery/",
        navName:"delivery",
        icon:require('../../../assets/common_assets/delivery_icon.png')
    },
    {
        id:3,
        title:"About Us",
        linkName:"/aboutus/",
        navName:"aboutus",
        icon:require('../../../assets/common_assets/about_us.png')
    }
]
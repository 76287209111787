import * as React from 'react'
import { useState,useEffect } from 'react';
import {
    ImageBackground,
    Text,
    View,
    Image,
    TouchableOpacity,
    TouchableHighlight,
    Linking
} from 'react-native'
import {homeStyle} from './homeStyle'
import { useMediaQuery } from 'react-responsive';
// import {PlayStore} from '../../commonComponents/mobilePlayStore';
import PlayStore from '../../commonComponents/mobilePlayStore';
import IconButton from '../../../storybook/component/play_button';


import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

const image = require("../../../assets/buyers/home_banner.jpg")
const buyerMobileQr = require("../../../assets/buyers/google_play_qr.png")

const buyerApp = 'https://play.google.com/store/apps/details?id=com.ishopatoz.android.customer.release'


const Home = () =>{

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:450
    })
    // const onScroll = () => setOffset(window.pageYOffset);
    // window.addEventListener('scroll',()=>{
    //     if(document.body.scrollTop >=1001){
    //         setVisibility(homeStyle.goToTop)
    //     }else{
    //         setVisibility(homeStyle.nonDisplay)
    //     }
    // })
        



    if(isMobileDisplay){
        return(
            <View style={homeStyle.homeContainerMobile}>
                
                <ImageBackground
                source={require('../../../assets/buyers/mobile_globe.png')}
                style={homeStyle.backgroundImage}
                >
                
                <Text style={homeStyle.topBoldTextMobile}>Want to buy a{"\n"}product with the{"\n"}best <Text style={homeStyle.topBoldTextMobileBold}>discount</Text>?</Text>
                <Text style={homeStyle.redTextMobile}>You are at the right place!</Text>
                <Text style={homeStyle.lightTextMobile}>Shop from your nearest stores</Text>
                <View style={homeStyle.playStoreContainer}>
                    {/*<PlayStore*/}
                    {/*    url={buyerApp}*/}
                    {/*    qrImage={buyerMobileQr}*/}
                    {/*    qrMessage="Download the app now"*/}
                        {/*    />*/}
                        <IconButton
                            onPress={() => Linking.openURL(buyerApp)}
                            qrImage={buyerMobileQr}
                            qrmess={"Download the app now"}
                            styles={{
                                qrContainer: {
                                    display: "flex",
                                    flexDirection: "colum",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "0px",
                                    // position: "absolute",
                                    width: "202.76px",
                                    height: "237px",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    // top: "376px",
                                    // backgroundColor: "#F2F9F9",
                                    //backgroundColor:"#FFFFFF",
                                    backgroundColor: "#F5FAFA",
                                    borderRadius: "25px",
                                    marginLeft: "auto",
                                    // opacity:"0.6",
                                    marginRight: "auto",
                                    //marginTop:"60px"
                                    marginTop: "30px"
                                },
                                button: {
                                    width: "168px",
                                    height: "42px",
                                    // left: "36px"
                                    top: "11px",
                                    background: "#000000",
                                    borderRadius: "24px",
                                    resizeMode: "contain"
                                },
                                googlePlayImg: {
                                    width: "168px",
                                    height: "42px",
                                    // left: "36px"
                                    top: "11px",
                                    background: "#000000",
                                    borderRadius: "24px",
                                    resizeMode: "contain"
                                },

                                lightText: {
                                    width: "150px",
                                    height: "50px",
                                    // left: 1px;
                                    // top: 27px;

                                    /* Small */
                                    fontFamily: 'Raleway_600SemiBold',
                                    fontStyle: "normal",
                                    fontWeight: "normal",
                                    fontSize: "14px",
                                    lineHeight: "180%",
                                    /* or 25px */
                                    textAlign: "center",
                                    /* Charcoal-Text */
                                    color: "#2C2E2F",
                                    //backgroundColor:"#CCCCCC"
                                },
                                qrStyle: {
                                    width: "102.84px",
                                    height: "102.84px",
                                    marginTop: "20px",
                                    backgroundColor: "#FFFFFF",
                                    boxShadow: "0px 0.803417px 0.803417px rgba(0, 0, 0, 0.25)",
                                    resizeMode: "contain"
                                }
                            }}
                        />
                </View>
                <Image
                    style={homeStyle.mobileBanner}
                    source={require("../../../assets/buyers/buyer_mobile_banner.png")}/>
                
                </ImageBackground>
                
            </View>
        )
    }


    return (
        <View nativeID='highlights' style={homeStyle.homeContainer}>
            <ImageBackground
                source={require('../../../assets/buyers/home_banner.jpg')}
                style={homeStyle.backgroundImage}
                >
                <Text style={homeStyle.topBoldText}>Want to buy a{"\n"}product with the{"\n"}best <Text style={homeStyle.topBoldTextBold}>discount</Text>?</Text>
                <Text style={homeStyle.boldRedText}>You are at the right place!</Text>
                <Text style={homeStyle.mediumLightText}>Shop from your nearest stores</Text>
                {/*<Text style={homeStyle.lightText}>Download the app now</Text>*/}
                {/*<TouchableOpacity*/}
                {/*    onPress={() =>{Linking.openURL('https://play.google.com/store/apps/details?id=com.ishopatoz.android.customer.release')}}*/}
                {/*    style={homeStyle.googlePlayImg}*/}
                {/*>*/}
                {/*    <Image*/}
                {/*        source={require('../../../assets/common_assets/google_play.png')}*/}
                {/*        style={homeStyle.googlePlayImg}*/}
                {/*    />*/}
                {/*</TouchableOpacity>*/}
                
                {/*<Image */}
                {/*    source={require('../../../assets/buyers/google_play_qr.png')}*/}
                {/*    style={homeStyle.qrImageStyle}*/}
                {/*/>*/}
                <IconButton
                    onPress={() => Linking.openURL("https://play.google.com/store/apps/details?id=com.ishopatoz.android.customer.release")}
                    qrImage={require('../../../assets/buyers/google_play_qr.png')}
                    qrmess="Download the app now"
                    styles={{
                        button: homeStyle.googlePlayImg,
                        googlePlayImg: homeStyle.googlePlayImg,
                        lightText: homeStyle.lightText,
                        qrStyle: homeStyle.qrImageStyle
                    }}
                />
                <Text nativeID='whyIshop'>{""}</Text>
            </ImageBackground>
        </View>
    )
}

export default Home
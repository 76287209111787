import * as React from 'react'

import {
    View,
    Image,
    Text,
    TouchableOpacity} from 'react-native'
import {useState} from 'react'
import {askedStyle} from "./askedStyles"
import {useMediaQuery} from 'react-responsive'

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

let expand = require("../../../assets/common_assets/expand.png")
let collapse = require("../../../assets/common_assets/collapse.png")


const QuestionCard = (props) => {

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const  [icon,changeIcon] = useState(expand)
    const [visibility,setVisibility] = useState(askedStyle.hiddenAnswer)
    const [defaultHeight,setHeight] = useState(askedStyle.questionContainer)

    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:600
    })

    if(isMobileDisplay){
        return (
            <View style={askedStyle.questionRootMobile}>
            <View style={askedStyle.questionContainerMobile}>
                    <Text style={askedStyle.questionTextMobile}>{props.question}</Text>

                    {/* {"\n"}
                    <Text style={askedStyle.answerText}>{item.answer}</Text> */}
                    <TouchableOpacity 
                        onPress={()=>{
                            if(icon == expand){
                                changeIcon(collapse)
                                setVisibility(askedStyle.answerView)

                            }else{
                                changeIcon(expand)
                                setVisibility(askedStyle.hiddenAnswer)
                            }
                        }}

                        style={askedStyle.iconContainer}
                        >
                        <Image 
                            source={icon}
                            style={askedStyle.clickIconMobile}
                            
                        />
                    </TouchableOpacity>
                </View>
                <View style={visibility}>
                    <Text style={askedStyle.answerTextMobile}>{props.answer}</Text>
                </View>
                <View style={askedStyle.bottomLine}/>
        </View>
        )
    }


    return(
        <View style={askedStyle.questionRoot}>
            <View style={askedStyle.questionContainer}>
                    <Text style={askedStyle.questionText}>{props.question}</Text>

                    {/* {"\n"}
                    <Text style={askedStyle.answerText}>{item.answer}</Text> */}
                    <TouchableOpacity 
                        onPress={()=>{
                            if(icon == expand){
                                changeIcon(collapse)
                                setVisibility(askedStyle.answerView)

                            }else{
                                changeIcon(expand)
                                setVisibility(askedStyle.hiddenAnswer)
                            }
                        }}

                        style={askedStyle.iconContainer}
                        >
                        <Image 
                            source={icon}
                            style={askedStyle.clickIcon}
                            
                        />
                    </TouchableOpacity>
                </View>
                <View style={visibility}>
                    <Text style={askedStyle.answerText}>{props.answer}</Text>
                </View>
        </View>
    )

}



const AskedQuestion = (props)  => {

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:600
    })

    if(isMobileDisplay){
        return(
            <View style={askedStyle.askContainerMobile}>
                <Text style={askedStyle.topTextMobile}>Frequently Asked Questions</Text>
                <View style={askedStyle.questionCardContainerMobile}>
                {props.data.map(item=>{
                    return<QuestionCard
                            question={item.question}
                            answer={item.answer}
                    />
                })}
            </View>
            </View>
        )
    }

    return(
        <View style={askedStyle.askContainer}>
            <Text style={askedStyle.topText}>Frequently Asked Questions</Text>
            <View style={askedStyle.questionCardContainer}>
                {props.data.map(item=>{
                    return<QuestionCard
                            question={item.question}
                            answer={item.answer}
                    />
                })}
            </View>
            
        </View>
    )
}

export default AskedQuestion
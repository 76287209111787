import { StyleSheet } from "react-native";
import {Raleway, Raleway_400Regular, Raleway_600SemiBold} from '@expo-google-fonts/raleway'

export const whyUsStyle = StyleSheet.create({
    whyUsContainer:{
        height:"40vh",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        width:"100%",
        // padding: "10%",
        // padding: "2%",
        margin:"2%",
        //backgroundColor:"#CCCCCC"
    },

    topBoldText:{
        // width: "160px",
        height: "7.718vh",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "2.5vw",
        lineHeight: "7.718vh",
        /* identical to box height */
        textAlign: "center",
        color: "#2C2E2F",
        marginLeft:"auto",
        marginRight:"auto",
    },

    topLightText:{
        // width: "1184px",
        // width:" 86.67vw",
        height: "1.553vh",
        // top: "1300.12px"
        marginTop:"4vh",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "1.25vw",
        lineHeight: "1.777vh",
        textAlign: "center",
        marginRight:"auto",
        marginLeft:"auto",
        color: "#2C2E2F",

    },

    /*
    cardContainer:{
        display:"flex",
        flexDirection:"row",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"6vh",
        flexWrap:"wrap",
        justifyContent:"center",
        width:"100%",
    },
    */

    allCardContainer:{
        marginTop:"-20vh",
    },

    card:{
        // width: "375px",
        width:" 21.45vw",
        // height: "320px",
        height:"42.70vh",
        background: "#FFFFFF",
        boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
        margin:"1%",
        textAlign:"center",
        alignItems:"center",
    },

    iconContainer:{
        width: "96px",
        height: "96px",
        backgroundColor: "#008489",
        borderRadius:"50%",
        alignItems:"center",
        textAlign:"center",
    },

    iconsStyle:{
        height:30,
        width:35,
        resizeMode:"contain",
        marginTop:"4.5vh",
    },

    cardBoldText:{
        // width: "221px",
        height: "31px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "26px",
        lineHeight: "31px",
        /* identical to box height */
        textAlign: "center",
        color: "#2C2E2F",
        marginTop:"2vh"
    },

    cardLightText:{
        width: "260px",
        height: "69px",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "20px",
        lineHeight: "23px",
        textAlign: "center",
        color: "#2C2E2F",
        marginTop:"2vh"
    },

    whyUsContainerMobile:{
        //height:"80vh",
        //height:"580px",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        alignItems:"center",
        width:"100%",
        // padding: "10%",
        // padding: "2%",
        margin:"2%",
        marginTop:"30px"
    },

    topBoldTextMobile:{
        // position: absolute;
        width: "350px",
        height: "47px",
        //left: "304px",
        marginLeft: "25px",
        marginRight: "25px",
        // top: 2442.12px;

        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "47px",
        /* identical to box height */
        color: "#2C2E2F",

    },

    topLightTextMobile:{
        width: "350px",
        height: "46px",
        marginLeft: "25px",
        marginRight: "25px",
        // left: 638px;
        // top: 2442px;
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "23px",
        color: "#2C2E2F"

    },
    /*
    cardContainerMobile:{
        display:"flex",
        flexDirection:"column",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"6vh",
        flexWrap:"wrap",
        justifyContent:"center",
        width:"100%",
    },
    */
    cardContainerMobile:{
        marginTop:"8vh",
        //height:"200px",
        width:"100%",
        alignItems:"center",
    },

    cardMobile:{
        height: "120px",
        // width:"60vw"
        display:"flex",
        flexDirection:"column",
        width:"100%",
        margin:"2%",
        //boxShadow: "0 0.4px #888",
        marginBottom:"25px",
        alignItems:"center"
    },

    iconContainerMobile:{
        width: "60px",
        height: "60px",
        backgroundColor: "#008489",
        borderRadius:"50%",
        alignItems:"center",
        textAlign:"center",
    },

    iconsStyleMobile:{
        height:50,
        width:50,
        resizeMode:"contain",
        marginTop:"0.8vh",
    },

    cardBoldTextMobile:{
        // width: "221px",
        height: "31px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "20px",
        //lineHeight: "31px",
        /* identical to box height */
        textAlign: "center",
        color: "#2C2E2F",
        marginTop:"2vh"
    },

    cardLightTextMobile:{
        //width: "260px",
        height: "69px",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "15px",
        //lineHeight: "23px",
        textAlign: "center",
        color: "#2C2E2F",
        marginTop:"5px",
    },

})
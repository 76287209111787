import * as React from 'react'
import {View,Image,Text} from 'react-native'
import {homeStyle} from "./homeStyles"
import { paragraph1,paragraph2,paragraph3,paragraph4,paragraph5 } from '../../../data/aboutus/data_aboutus_home'
import { useMediaQuery } from 'react-responsive';

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';


const ContactUsHome = () => {

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });
    
    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:450
    })

    if(isMobileDisplay){
        return(
            <View nativeID='highlights' style={homeStyle.homeContainerMobile}>
                
                <Text style={homeStyle.topBoldTextMobile}>
                    An Endeavour to{"\n"}
                    <Text style={homeStyle.topBoldTextMobileBold}>Onboard Business{"\n"}Enterprises </Text>
                     to the{"\n"}
                     <Text style={homeStyle.topBoldTextMobileBold}>Digital Platform</Text> 
                </Text>
                
                <Image
                nativeID='story'
                style={homeStyle.bannerDesignMobile}
                source={require("../../../assets/aboutUs/banner_design_mobile.png")}
                />
                
                <View style={homeStyle.textContainerMobile} >
                    <View style={homeStyle.topTextContainerMobile}>
                        <Text style={homeStyle.aboutUsTextMobile}>About Us</Text>
                        <View
                            style={homeStyle.blockSpaceMobile}
                        />
                    </View>
                    
                    <Text style={homeStyle.ourStoryTextMobile}>Our Story</Text>
                    <Text style={homeStyle.ourStoryParagraphMobile}>{paragraph1}</Text>
                    <Text style={homeStyle.ourStoryParagraphMobile}>{paragraph2}</Text>
                    <Text style={homeStyle.ourStoryParagraphMobile}>{paragraph3}</Text>
                    <Text style={homeStyle.ourStoryParagraphMobile}>{paragraph4}</Text>
                    <Text nativeID='mission' style={homeStyle.ourStoryParagraphMobile}>{paragraph5}</Text>
                    
                </View>
                
            </View>
        )
    }

    return(
        <View nativeID='highlights' style={homeStyle.homeContainer}>
            <Text style={homeStyle.topBoldText}>An Endeavour to Onboard {"\n"}Business Enterprises to the Digital Platform </Text>
            <Image
             nativeID='story'
             style={homeStyle.bannerDesign}
             source={require("../../../assets/aboutUs/banner_design.png")}
            />
            <View style={homeStyle.textContainer} >
                <View style={homeStyle.topTextContainer}>
                    <Text style={homeStyle.aboutUsText}>About Us</Text>
                    <View
                        style={homeStyle.blockSpace}
                    />
                <View/>
                </View>
                <Text style={homeStyle.ourStoryText}>Our Story</Text>
                <Text style={homeStyle.ourStoryParagraph}>{paragraph1}</Text>
                <Text style={homeStyle.ourStoryParagraph}>{paragraph2}</Text>
                <Text style={homeStyle.ourStoryParagraph}>{paragraph3}</Text>
                <Text style={homeStyle.ourStoryParagraph}>{paragraph4}</Text>
                <Text nativeID='mission' style={homeStyle.ourStoryParagraph}>{paragraph5}</Text>
            </View>
        </View>
    )
}


export default ContactUsHome
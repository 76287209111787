import { Raleway_400Regular, Raleway_600SemiBold, Raleway_700Bold } from '@expo-google-fonts/raleway';
import {StyleSheet} from 'react-native'
//import { Raleway } from '@expo-google-fonts/raleway';
import { journeyStyle } from './../../aboutUsComponents/ishopJourney/ishopJourneyStyles';

export const flexStyle = StyleSheet.create({
    rootContainer:{
        width:"100%",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center"
    },

    rootContainerMobile:{
        width:"100%",
        //marginLeft:"10%",
        //marginRight:"10%",
        textAlign:"center",
        marginBottom:"30px"
    },

    topText:{
        // position: absolute;
        width: "123.974vh",
        height: "4.531vw",
        // left: 343px;
        // top: 851px;

        /* XL-Styles/XL-Semi Bold */
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "2.5vw",
        lineHeight: "180%",
        /* identical to box height, or 58px */
        textAlign: "center",
        /* Charcoal-Text */
        color: "#2C2E2F",
        marginLeft:"auto",
        marginRight:"auto",
        resize:"none",
        marginTop:"8.210vh",
        whiteSpace:"nowrap"
    },

    flexContainer:{
        display:"flex",
        flexDirection:"row",
        width:"100%",
        flexWrap:"wrap",
        float:"left",
        textAlign:"flex-start",
        marginTop:"8.210vh",
    },

    flexCard:{
        width:"30vw",
        // height:"20vh",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"left",
        borderBottomWidth:"1px solid #000000",
        // opacity:"0.1",
        // borderBottomWidth:"24vw"
        margin:"1.4%",
        alignSelf:"flex-start"
    },
    cardImg:{
        resizeMode:"contain",
        width:"5vw",
        height:"8.509vh",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center"
    },
    titleStyle:{
        // width: 113px;
        height: "4.762vh",
        // left: 219px;
        // top: 1051px;
        marginTop:"2.5vh",
        /* Medium-Styles/Medium-Semi Bold */

        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "1.25vw",
        lineHeight: "180%",
        /* ientical to box height, or 29px */
        textAlign: "center",
        /* Charcoal-Text */
        color: "#2C2E2F"

    },
    textStyle:{
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "1.094vw",
        lineHeight: "180%",
        /* or 25px */
        textAlign: "center",

        /* Charcoal-Text */
        color: "#2C2E2F"

    },


    //css for Mobile View

    topTextMobile:{
        width: "298px",
        height: "25px",
        fontFamily: 'Raleway_700Bold',
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "18px",
        lineHeight: "25px",
        /* or 25px */
        marginLeft:"auto",
        marginRight:"auto",
        textAlign: "center",
        color: "#2C2E2F",
        marginTop:"40px"

    },
    
    cardContainerMobile:{
        marginTop:"30px",
        width:"100%",
        padding: "0",
        margin:"0"
    } ,
    flexCardMobile:{
        display:"flex",
        flexDirection:"row",
        width:"100%",
        justifyContent:"center",
        alignItems:"center",
        height:"60px",
        // margin:"1%"
        //marginLeft:"-30px",
        marginTop:"20px",
        marginBottom:"20px"
    },
    cardImgMobile:{
        resizeMode:"contain",
        width:"50px",
        height:"50px",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"left",
        marginTop:"20px"
    },

    textContainer:{
       maxHeight:"100%",
       marginTop:"14px",
       marginRight:"20px"
    },  
    
    textContainerMobile:{
        maxHeight:"100%",
        marginTop:"14px",
        marginRight:"20px"
     }, 

    titleStyleMobile:{
        // width: "145px",
        height: "29px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "180%",
        /* identical to box height, or 29px */
        /* Cyan-Secondary */
        color: "#008489",
        textAlign:"left"
    },
    textStyleMobile:{
        width: "236px",
        height: "50px",
        // left: "0px"
        // top: 132px;

        /* Small */
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "180%",
        /* or 25px */
        /* Charcoal-Text */
        color: "#2C2E2F",
        textAlign:"left"

    }

})
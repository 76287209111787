import {StyleSheet} from 'react-native'
//import {Raleway, Raleway_400Regular, Raleway_600SemiBold} from '@expo-google-fonts/raleway'

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

export const homeStyle   = StyleSheet.create({
    homeContainer:{
        alignItem:"center",
        width:"100%",
        maxWidth:"100%",
        backgroundColor:"#FFFFFF",
        marginLeft:"auto",
        marginRight:"auto",
    },

    topBoldText:{
        // position: absolute;
        width: "100%",
        height: "8.39vh",
        // left: "213px"
        // marginTop: "224px",
        marginTop:"25vh",
        fontFamily: 'Raleway_800ExtraBold',
        fontStyle: "normal",
        fontWeight: 900,
        // fontSize: "3.00vw",
        fontSize:"40px",
        lineHeight: "9.195vh",
        textAlign: "center",
        color: "#2C2E2F",

    },
    bannerDesign:{
        // position: absolute;
        // width: "1407.04px",
        // width:"60vw",
        width:"90vw",
        height: "25.227vh",
        marginTop: "15vh",
        resizeMode:"contain",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center"

    },

    bannerDesignMobile:{
        // position: absolute;
        // width: "1407.04px",
        // width:"60vw",
        width:"80vw",
        height: "20.227vh",
        marginTop: "3vh",
        resizeMode:"contain",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center"

    },

    topTextContainer:{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        textAlign:"center",
    },

    blockSpace:{
        borderBottomColor: 'black',
        borderBottomWidth: 1,
        width:"5.5vw",
        height:"0.33vh",
        left:"1.5vw",
        alignItems:"center",
        backgroundColor:"#2C2E2F",
        marginTop:"1.97vh",
        alignContent:"center"
    },

    blockSpaceMobile:{
        borderBottomColor: 'black',
        borderBottomWidth: 1,
        width:"20.8125vw",
        height:"0.33vh",
        left:"1vw",
        alignItems:"center",
        backgroundColor:"#2C2E2F",
        marginTop:"1.50vh",
        alignContent:"center"
    },

    textContainer:{
        //marginLeft:"4.297vw",
        //marginRight:"auto",
        // textAlign:"center",
        //width:"60vw",
        width:"100%",
        // alignItems:"center"
        padding: "4%",
    },
    aboutUsText:{
        width: "9vw",
        height: "3.777vh",
        // left: 440.67px;
        // top: 681.63px;
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        // fontSize: "1.875vw",
        fontSize:"24px",
        lineHeight: "3.777vh",
        color: "#2C2E2F",
        marginTop:"2vh",
        marginLeft:"10vh"

    },

    ourStoryText:{
        width: "66.66vw",
        height: "7.718vh",
        // left: 440.67px;
        // top: 728.63px;
        marginTop:"3.5vh",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        // fontSize: "3.125vw",
        fontSize:"40px",
        lineHeight: "7.718vh",
        /* identical to box height */
        color: "#2C2E2F",
        marginLeft:"10vh"

    },
    ourStoryParagraph:{
        // position: absolute;
        // width: "910.66px",
        width:"80vw",
        // height: "299px",
        // left: 440.67px
        // top: 799.63px;
        // marginTop:"2.15vh",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        // fontSize: "1.25vw",
        fontSize:"16px",
        lineHeight: "5.255vh",
        color: "#2C2E2F",
        marginTop:"4.926vh",
        marginLeft:"10vh"
    },

    homeContainerMobile:{
        width:"100%",
        //height:"335vh",
        //height:"2285px",
        //height:"54%",
        marginTop:"64px",
        maxHeight:"100%",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        backgroundColor:"#FFFFFF",
        //backgroundColor:"#AAAAAA",
        marginBottom:"10px"

    },

    topBoldTextMobile:{
        // position: absolute;
        width: "100%",
        height: "126px",
        top: "130px",

        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "32px",
        lineHeight: "130%",
        /* or 42px */
        textAlign: "center",
        color: "#2C2E2F",
        marginLeft:"auto",
        marginRight:"auto",

    },

    topBoldTextMobileBold:{
        // position: absolute;
        width: "100%",
        height: "126px",
        top: "130px",

        fontFamily: 'Raleway_800ExtraBold',
        fontStyle: "normal",
        fontWeight: 800,
        fontSize: "32px",
        lineHeight: "130%",
        /* or 42px */
        textAlign: "center",
        color: "#2C2E2F",
        marginLeft:"auto",
        marginRight:"auto",

    },

    aboutUsTextMobile:{
        width: "75px",
        // width:"10.54vw",
        height: "23px",
        left: "20px",
        marginLeft: "20px",
        //marginRight: "20px",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "23px",
        color: "#2C2E2F",
        //backgroundColor:"#CCCCCC"
    },

    /*ourStoryTextMobile:{
        width: "66.66vw",
        height: "47px",
        // left: 440.67px;
        // top: 728.63px;
        marginTop:"2.15vh",
        fontFamily:Raleway,
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "25px",
        lineHeight: "47px",
        
        color: "#2C2E2F"

    },
    */

    ourStoryTextMobile:{
        // position: absolute;
        width: "200px",
        height: "47px",
        //left: "304px",
        marginLeft: "20px",
        marginRight: "20px",
        // top: 2442.12px;

        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "47px",
        /* identical to box height */
        color: "#2C2E2F",
        textAlign:"left",
        marginTop:"10px"
    },

    ourStoryParagraphMobile:{
        // position: absolute;
        // width: "910.66px",
        width:"85%",
        //width:"24em",
        // height: "299px",
        // left: 440.67px
        // top: 799.63px;
        marginLeft:"20px",
        marginRight: "20px",
        // marginTop:"2.15vh",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "30px",
        color: "#2C2E2F",
        marginTop:"10px",
        textAlign:"left",
        //backgroundColor:"#CCCCCC"
    },

    textContainerMobile:{
        //marginLeft:"20px",
        //marginRight:"20px",
        // textAlign:"center",
        width:"100%",
        //height:"500px"
        // alignItems:"center"
        // padding: "10%",
        flex:1,
    },

    topTextContainerMobile:{
        display:"flex",
        flexDirection:"row",
        alignItems:"left",
        textAlign:"left",
    },

})
import {StyleSheet} from 'react-native'
import {Raleway, Raleway_400Regular, Raleway_600SemiBold} from '@expo-google-fonts/raleway'


export const missionStyle = StyleSheet.create({
    missionContainer:{
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        //height:"80vh",
        marginTop:"5vh",
        maxWidth:"100%",
        justifyContent:"center",
    },
    textContainer:{
        //height: "57.53vh",
        //backgroundColor:"#F2F9F9",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        width:"100%",
        //margin:"6%",
        padding:"4%",
        justifyContent:"center",
        backgroundColor:"#E6F3F4",
    },

    topBoldText:{
        width: "17.1875vw",
        height: "7.7175vh",
        // left: "786px",
        // top: "1273px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        // fontSize: "3.125vw",
        fontSize:"40px",
        lineHeight: "7.7175vh",
        /* identical to box height */
        color: "#2C2E2F",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"1vh",
        justifyContent:"center",
        marginBottom:"3vh"

    },

    lightText:{
        // width: " 86.67vw",
        width:"90vw",
        // height: "10.50vh",
        // left: 304px;
        // top: 1348px;
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: 400,
        // fontSize: "1.25vw",
        fontSize:"16px",
        lineHeight: "5.255vh",
        color: "#2C2E2F",
        textAlign:"left",
        marginTop:"2.217vh",
        justifyContent:"center"
    },
    coloredText:{
        // width: " 86.67vw",
        width:"90vw",
        // height: "12.78vh",
        // left: 304px;
        // top: 1475px;
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        // fontSize: "1.25vw",
        fontSize:"16px",
        lineHeight: "5.255vh",
        color: "#008489",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"left",
        marginTop:"6.568vh",

    },

    imageContainer:{
        display:"flex",
        // width:"70vw",
        objectFit:"contain",
        boxSizing:"content-box",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        flexDirection:"row",
        margin:"6%",
        alignItems:"center",
        justifyContent:"center",
        
    },

    imageStyle:{
        width: "28.20vw",
        height: "20.34vh",
        resizeMode:"contain"
    },

    arrowStyle:{
        resizeMode:"contain",
        height:"16%",
        width:"6%",
        alignItems:"center",
    },

    missionContainerMobile:{
        width:"100%",
        //height:"215vh",
        //height:"1600px",
        marginTop:"20px",
        //maxHeight:"100%",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        //backgroundColor:"#AAAAAA",
       
    },

    textContainerMobile:{
        marginLeft:"auto",
        marginRight:"auto",
        // textAlign:"center",
        //width:"60vw",
        width:"100%",
        //height:"500px",
        // alignItems:"center"
        // padding: "10%",
        backgroundColor:"#CCCCCC",
        flex:1
    },

    topBoldTextMobile:{
        // position: absolute;
        width: "200px",
        height: "35px",
        //left: "304px",
        marginLeft: "20px",
        marginRight: "20px",
        // top: 2442.12px;

        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "47px",
        /* identical to box height */
        color: "#2C2E2F",
        textAlign:"left"
    },

    lightTextMobile:{
        // position: absolute;
        // width: "910.66px",
        //width:"400px",
        width:"85%",
        //width:"20em",
        marginLeft:"20px",
        marginRight: "20px",
        // height: "299px",
        // left: 440.67px
        // top: 799.63px;
        // marginTop:"2.15vh",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "32px",
        color: "#2C2E2F",
        marginTop:"20px",
        textAlign:"left"
    },

    coloredTextMobile:{
        // position: absolute;
        // width: "910.66px",
        //width:"400px",
        width:"85%",
        //width:"20em",
        marginLeft:"20px",
        marginRight: "20px",
        // height: "299px",
        // left: 440.67px
        // top: 799.63px;
        // marginTop:"2.15vh",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "32px",
        color: "#008489",
        marginTop:"20px",
        textAlign:"left",
        marginBottom:"20px"
    },

    imageContainerMobile:{
        display:"flex",
        // width:"70vw",
        //objectFit:"contain",
        boxSizing:"content-box",
        marginLeft:"0px",
        //marginRight:"auto",
        //marginTop:"50px",
        marginTop:"0px",
        textAlign:"center",
        flexDirection:"column",
        //margin:"6%",
        alignItems:"center",
        justifyContent:"center",
        //backgroundColor:"#CCCCFF"
        
    },

    /*
    imageStyleMobile:{
        width: "28.20vw",
        height: "20.34vh",
        alignItems:"center",
        resizeMode:"contain"
    },
    */

    imageStyleMobile:{
        //width: "50vw",
        width: "200px",
        //height: "120vh",
        height: "800px",
        alignItems:"center",
        resizeMode:"contain"
    },

    arrowStyleMobile:{
        resizeMode:"contain",
        height:"16%",
        width:"6%",
        alignItems:"center",
        transform:"rotate(90deg)",
    },

})
//import { Raleway_400Regular, Raleway_600SemiBold } from '@expo-google-fonts/raleway'
import { Raleway_500Medium } from '@expo-google-fonts/raleway'
import {StyleSheet} from 'react-native'
//import {Raleway} from '@expo-google-fonts/raleway'

export const benefitStyles = StyleSheet.create({
    benefitContainer:{
        display:"flex",
        flexDirection:"row",
        // marginTop:"10vh",
        // width:"80vw",
        width:"100%",
        padding: "4%",
        marginLeft:"auto",
        marginRight:"auto",
        // textAlign:"center",
        float:"left",
        height:"55vh",
        zIndex:0
        //backgroundColor:"#008489",
    },
    topRightText:{
        // position: "static",
        width: "31.332vw",
        height: "7.553vh",
        marginLeft:"auto",
        marginRight:"auto",
        top: "30.706vh",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "1.5625vw",
        lineHeight: "3.777wh",
        textAlign: "center",
        color: "#2C2E2F",
        /* Inside Auto Layout */
        flex: "none",
        order: "0",
        flexGrow: "0",
        margin: "5vw 0vw"
    },

    leftView:{
        width:"40vw",
        height:"60vh",
        display:"block",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"3.284vh"
    },

    rightView:{
        // width:"40vw",
        height:"60vh",
        // display:"block",
    },

    flexCardContainer:{
        display:"flex",
        flexDirection:"row",
        marginTop:"144.54px"
        // width:"100%"
    },

    blockCardContainer:{
       display:"flex",
       marginLeft:"auto",
       marginRight:"auto",
       textAlign:"center",
       justifyContent:"center",
    },

    cardText:{
        width: "75px",
        height: "42px",
        // left: "158px"
        // top: 109px;
        
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "18px",
        lineHeight: "21px",
        textAlign: "center",
        color: "#2C2E2F",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"20px"
        // marginTop:"5vh"
    },

   
    circleLogo:{
       resizeMode:"contain",
       width: "96px",
       height: "96px",
       marginLeft:"auto",
       marginRight:"auto",
       textAlign:"center",
       alignItems:"center",
    //    marginTop:"5vh"
    },



    topLeftText:{
        // width: "428px",
        height: "23px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "20px",
        lineHeight: "23px",
        color: "#2C2E2F",
        position:"relative",
        marginTop:"120px"
    },

    rightTopText:{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        marginLeft:"15.6vw"
    },

    blockSpace:{
        borderBottomColor: 'black',
        borderBottomWidth: 1,
        width:"73px",
        height:"2px",
        left:"1vw",
        alignItems: 'center',
        backgroundColor:"#2C2E2F"
    },

    rightTextOne:{
        // width: "490px",
        height: "8.435vh",
        // left: "694px",
        marginTop: "2.568vh",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "2.125vw",
        lineHeight: "7.718vh",
        color: "#2C2E2F",
        marginLeft:"15.6vw"
    },

    rightTextTwo:{
        width: "60vw",
        height: "8.286vh",
        // left: "694px",
        marginLeft:"15.6vw",
        marginTop: "1.105vh",
        fontFamily: 'Raleway_400Regular',
        //fontWeight:"400",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "1.2625vw",
        lineHeight: "4.433vh",
        color: "#2C2E2F",
    },

    rightTextThree:{
        width: "60vw",
        height: "10.286vh",
        // left: "694px",
        marginLeft:"15.6vw",
        marginTop: "0.605vh",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "1.0625vw",
        lineHeight: "4.433vh",
        color: "#2C2E2F",
    },

    whyIshopText:{
        width: "12.109vw",
        height: "3.777vh",
        // left: "694px",
        top: "0px",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "1.5625vw",
        lineHeight: "3.777vh",
        color: "#2C2E2F",
    },

    whyIshopTextMobile:{
        width: "120px",
        height: "18px",
        // left: "694px",
        top: "0px",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "18px",
        color: "#2C2E2F",
        textAlign:"left",
        //backgroundColor:"#CCCCCC"
    },

    rightBottomContainer:{
        display:"flex",
        flexDirection:"row",
        width:"100%",
        marginTop:"6.568vh",
        // justifyContent:"center"
    },
    playLogoContainer:{
        height:"11.823vh",
        width:"5.625vw",
        backgroundColor:"#FF002A",
        borderRadius:"0.625vw",
        opacity:"0.25",
        display:"none"
    },


    playLogo:{
        width:"2.734vw",
        height:"6vh",
        marginLeft:"auto",
        marginRight:"auto",
        alignItems:"center",
	    resizeMode:"contain",
        marginTop:"2.5vh"
    },

    mailText:{
        width: "17.969vw",
        // height: "94px",
        // left: "694px",
        // marginTop: "2px",
        fontFamily: 'Raleway_400Regular' ,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "1.0625vw",
        lineHeight: "2.433vh",
        color: "#2C2E2F",
        textDecorationLine:"underline"
    },
   //Mobile responsive css

    mobileContainer:{
        display:"flex",
        flexDirection:"column",
        marginTop:"15px",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        width:"100%"
    },

    rightTextMobile:{
        width: "309px",
        height: "85px",
        marginLeft: "30px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "28px",
        textAlign:"left",
        color: "#2C2E2F",
        marginTop:"20px",
        //backgroundColor:"#CCCCCC"
    },
    rightTopTextMobile:{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        marginLeft:"30px"
    },

    rightTextTwoMobile:{
        // position: absolute;
        width: "80%",
        // height: "280px",
        marginLeft: "30px",
        // top: 954px;
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "28px",
        /* or 175% */
        color: "#2C2E2F",
        textAlign:"left",
        marginTop:"20px"

    },

    suggestionText:{
        //width: "311px",
        width: "80%",
        // height: "280px",
        marginLeft: "30px",
        // top: 954px;
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "28px",
        /* or 175% */
        color: "#2C2E2F",
        textAlign:"left",
        marginTop:"20px"

    },

    suggestionTextMobile:{
        width: "80%",
        // height: "280px",
        marginLeft: "30px",
        // top: 954px;
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "28px",
        /* or 175% */
        color: "#2C2E2F",
        textAlign:"left",
        marginTop:"20px"

    },

    mailTextMobile:{
        width: "230px",
        fontFamily: 'Raleway_500Medium' ,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "28px",
        //color: "#FF002A",
        color: "#2C2E2F",
        textDecorationLine:"underline"
    },
    mobileBenefitCard:{
        width:"100%",
        // height:"60vh",
        // display:"flex",
        flexWrap:"wrap",
        flexDirection:"column",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        // marginTop:"20px"
        display:"none"
    },
    flexCardContainerMobile:{
        display:"flex",
        flexDirection:"row",
        flexWrap:"wrap",
        order:2,
        marginTop:"144.54px",
        width:"100%"
    },

    blockCardContainerMobile:{
        display:"flex",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        justifyContent:"center",
        width:"45vw",
        margin:"4%",
    }


})

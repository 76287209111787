import React from "react";
import PropTypes from "prop-types";
import { TouchableOpacity, Text, Image, View } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";


const IconButton = ({ onPress, qrImage, qrmess, styles}) => {
    return (
        <View style={styles.qrContainer}>
            <View>
            <Text style={styles.lightText}>{qrmess}</Text>
                <TouchableOpacity onPress={onPress}>
                <Image
                    source={require("../../assets/common_assets/google_play.png")}
                    style={styles.googlePlayImg}
                />
                </TouchableOpacity>
            </View>
            <Image
                source={qrImage}
                style={styles.qrStyle}
            />
        </View>
    );
};

//const styles = {
//    button: {
//        width: "10.9375vw",
//        height: "4.926vh",
//        marginRight: "auto",
//        marginLeft: "auto",
//        justifyContent: "center",
//        borderRadius: "1.875vw",
//        resizeMode: "contain",
//        marginTop: "1vh"
//    },
//    googlePlayImg: {
//        width: "10.9375vw",
//        height: "4.926vh",
//        marginRight: "auto",
//        marginLeft: "auto",
//        justifyContent: "center",
//        borderRadius: "1.875vw",
//        resizeMode: "contain",
//        marginTop: "1vh"
//    },
//    lightText: {
//        position: "static",
//        width: "18.594vw",
//        height: "4.240vh",
//        marginTop: "1vh",
//        fontFamily: 'Raleway_600SemiBold',
//        fontStyle: "normal",
//        fontWeight: "normal",
//        fontSize: "1.093vw",
//        lineHeight: "3.120vh",
//        color: "#2C2E2F",
//        /* Inside Auto Layout */
//        flex: "none",
//        order: 0,
//        flexGrow: 0,
//        marginLeft: "auto",
//        marginRight: "auto",
//        textAlign: "center"
//    },
//    qrStyle: {

//    }
//};

IconButton.propTypes = {
    onPress: PropTypes.func.isRequired,
    qrImage: PropTypes.any.isRequired,
    qrmess: PropTypes.string.isRequired,
    styles: PropTypes.object.isRequired
};

export default IconButton;
import * as React from 'react'
import {
    View,
    Text,
    Image,
    Linking,
    TouchableOpacity
} from 'react-native'
import {qrStyle} from './qrStyles'

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

const qrImg = require("../../../assets/common_assets/google_play.png");

const QrElement = (props) => {

    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    return (
        <View style={qrStyle.qrContainer}>
            <View>
                <Text style={qrStyle.qrTopText}>{props.qrMessage}</Text>
                <TouchableOpacity
                    onPress={() => Linking.openURL(props.playStoreLink)}
                    style={qrStyle.googlePlayImg}
                >
                    <Image 
                        source={require("../../../assets/common_assets/google_play.png")}
                        style={qrStyle.googlePlayImg}
                    />  
                </TouchableOpacity>                  
            </View>
           
            <Image 
                source={props.qrImage}
                style={qrStyle.qrImage}
            />
        </View>
    )
}

export default QrElement
import * as React from 'react'
import {View} from 'react-native'


import TopNavbar from '../components/commonComponents/TopNav/index'
import BottomNavbar from '../components/commonComponents/BottomNav/index'
import {contactUsLinks} from '../components/commonComponents/BottomNav/data'
import ContactUsHome from '../components/aboutUsComponents/aboutUsHome/index'
import OurMission from '../components/aboutUsComponents/ourMission/index'
import IshopJourney from '../components/aboutUsComponents/ishopJourney/index'
import SocialConnect from '../components/aboutUsComponents/socialConnect/index'
import Footer from "../components/commonComponents/Footer/index"
import { navigation } from '@react-navigation/native-stack';
import TheTeam from '../components/aboutUsComponents/TheTeam'
import Hiring from '../components/aboutUsComponents/Hiring'
const shoppingApp  = 'https://play.google.com/store/apps/details?id=com.ishopatoz.android.customer.release'
import GoToTop from '../components/commonComponents/GoToTop/index'


 const AboutUsPage = () => {
    return (
        <View>
            <TopNavbar/>
            <BottomNavbar links={contactUsLinks} />
            <ContactUsHome/>
            <GoToTop/>
            <OurMission/>
            {/* <IshopJourney/> */}
            {/*<TheTeam/>*/}
            <Hiring/>
            
            <SocialConnect/>
            
            <Footer
                contactMailLink="jobs@ishopatoz.com"
                contactMailText="jobs@ishopatoz.com"
                appUrl={shoppingApp}
                downloadText="Download the app now"
            />
            
        </View>
    )
}

export default AboutUsPage
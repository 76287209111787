import { Raleway_400Regular, Raleway_600SemiBold } from '@expo-google-fonts/raleway'
import {StyleSheet} from 'react-native'
//import {Raleway} from '@expo-google-fonts/raleway'

export const segmentStyle = StyleSheet.create({
    segmentContainer:{
        // width:"100vh",
        // padding: "6%",
        height:"100vh",
        width:"100%",
        marginTop:"6.568vh"
    },

    mobileContainer:{
        marginTop:"30px"
    },
    knowMore:{
        marginLeft:"1%",
        height:"30vh"
    },
    knowMoreMobile:{
        marginLeft:"1.5%",
        height:"30vh"
    },

    topContainer:{
        display:"flex",
        flexDirection:"row",
        width:"80vw",
        textAlign:"left",
        alignItems:"left",
        marginLeft:"auto",
        marginRight:"auto",
    },

    theSegmentText:{
        width: "22.484vw",
        height: "7.718vh",
        // left: "304.95px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "3.125vw",
        lineHeight: "7.718vh",
        color: "#2C2E2F",
    },
    centerText:{
        width: "35.3125vw",
        height: "7.553vh",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "1.25vw",
        lineHeight: "3.777vh",
        color: "#2C2E2F",
        textAlign:"left",
        marginLeft:"4vw"

    },

    navigatorContainer:{
        display:"flex",
        flexDirection:"row",
        float:"right",
        marginLeft:"inherit",
        // marginLeft:"17vw",
        alignItems:"center",
        justifyContent:"space-between",
        width:"6vw"
    },
    redArrowImage:{
        transform:"matrix(1,0,0,-1,0,0)",
        width:10,
        alignSelf:"center",
        alignItems:"center",
        height:15,
        resizeMode:"contain",
        // marginLeft:"30px",
        float:"right",
        // display:"none"
    },

    greyArrowImage:{
        transform:"matrix(1,0,0,-1,0,0)",
        width:15,
        alignSelf:"center",
        alignItems:"center",
        height:15,
        resizeMode:"contain",
        // marginLeft:"30px",
        float:"right",
    },

    cardContainer:{
        display:"flex",
        flexDirection:"row",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        objectFit:"contain",
        padding:"2%",
        justifyContent:"flex-start",
        width:"90%",
        marginTop:" 4.926vh"
        // alignItems:"center",
        // width:"80vw",
      
    },

    opacityElement:{
        width:16,
        height:16
    },  


    cardView:{
        height:"100%",
        marginLeft:"1%"
    },

    cardTitle:{
        // width: "91px",
        height: "4.598vh",
        // left: 418px;
        // top: 2060px;

        /* Large-Styles/Large-Semi Bold */
        textAlign:"center",

        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "1.25vw",
        lineHeight: "4.598vh",

        /* Charcoal */
        color: "#008489",
        marginTop:"2.956vh",
        //backgroundColor:"#AAAAAA",
        // marginLeft:"1.5625vw",
        whiteSpace:"nowrap"

    },

    cardText:{
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "1.093vw",
        lineHeight: "3.941vh",
        textAlign: "center",
        color: "#2C2E2F",
        marginTop:"1vh",
        //backgroundColor:"#CCCCCC",
        marginLeft:"1.5625vw",

    },

    cardTitleMobile:{
        // width: "91px",
        height: "28px",
        // left: 418px;
        // top: 2060px;

        /* Large-Styles/Large-Semi Bold */
        textAlign:"center",

        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "28px",

        /* Charcoal */
        color: "#008489",
        marginTop:"18px",
        marginLeft:"15px",
        width:"210px",
        whiteSpace:"nowrap"

    },

    cardTextMobile:{
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "13px",
        lineHeight: "24px",
        textAlign: "center",
        color: "#2C2E2F",
        marginTop:"3px",
        // marginLeft:"15px",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        width:"210px"
        

    },

    cardImage:{
        // width: "250.63px",
        // height: "300px",
        width:" 23.42vw",
        // height:"58.29vh",
        // width:"320px",
        // height:"62.890vh",
        height:"53.57vh",
        marginLeft:"5%",
        // marginLeft:"auto",
        // marginRight:"auto",
        textAlign:"left",
        borderRadius:"0.65vw",
        resizeMode:"stretch",
        // flex:1
    },
    cardImageMore:{
        // width: "250.63px",
        // height: "300px",
        width:" 23.42vw",
        // height:"58.29vh",
        // width:"320px",
        // height:"62.890vh",
        height:"53.57vh",
        marginLeft:"5%",
        // marginLeft:"auto",
        // marginRight:"auto",
        textAlign:"left",
        borderRadius:"0.65vw",
        resizeMode:"stretch",
        zIndex:4000
        // flex:1
    },
    cardImageMore:{
        zIndex:5000
    },

    circleImageContainer:{
        width:"160px",
        height:"300px",
        // margin:"2%",
        backgroundColor:"#008489",
        alignItems:"center",
        textAlign:"center",
        borderRadius:"8px"
    },

    circleImage:{
        background: "#008489",
    },


    ///Mobile responsive UI

    segmentTextMobile:{
        // position: absolute;
        /* XL-Styles/XL-Semi Bold */
        marginLeft:"30px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "32px",
        lineHeight: "180%",
        /* identical to box height, or 58px */
        /* Charcoal-Text */
        color: "#2C2E2F",
    },
    centerTextMobile:{
        // position: absolute;
        // left: 7.5%;
        // right: 11.67%;
        // top: "30.24%",
        // bottom: "68.83%",
        /* Medium */
        marginLeft:"30px",
        fontFamily:'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "180%",
        /* or 29px */
        /* Charcoal-Text */
        color: "#2C2E2F"

    },
    
    cardContainerMobile:{
        display:"flex",
        flexDirection:"row",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        objectFit:"contain",
        // padding:"2%",
        justifyContent:"flex-start",
        width:"99%",
        marginTop:"30px",
        // alignItems:"center",
        // width:"80vw",
        marginLeft:"-4px"
    },
    
    cardImageMobile:{
        // width: "250.63px",
        // height: "300px",
        // width:" 23.42vw",
        // height:"58.29vh",
        // width:"320px",
        // height:"383px",
        width:"217px",
        height:"250px",
        marginLeft:"5%",
        // marginLeft:"auto",
        // marginRight:"auto",
        textAlign:"left",
        borderRadius:"8px",
        resizeMode:"stretch",
        // flex:1
    }

    


})
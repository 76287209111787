import { Raleway_400Regular, Raleway_600SemiBold } from '@expo-google-fonts/raleway'
import {StyleSheet} from 'react-native'
//import {Raleway} from '@expo-google-fonts/raleway'


export const moreStyle = StyleSheet.create({
    moreContainer:{
        width:"100%",
        marginLeft:"auto",
        textAlign:"center",
        alignItems:"center",
        marginTop:"10vh"
    },

    topTextContainer:{
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"left",
        alignItems:"center",
        display:"flex",
        flexDirection:"row"
    },

    blockSpace:{
        borderBottomColor: 'black',
        borderBottomWidth: 1,
        width:"5.703vw",
        height:"0.328vh",
        left:"2vw",
        alignItems: 'center',
        backgroundColor:"#2C2E2F"
    },

    blockSpaceMobile:{
        borderBottomColor: 'black',
        borderBottomWidth: 1,
        width:"100px",
        height:"2px",
        left:"2vw",
        alignItems: 'center',
        backgroundColor:"#2C2E2F"
    },

    blockSpace:{
        borderBottomColor: 'black',
        borderBottomWidth: 1,
        width:"100px",
        height:"1.8px",
        left:"1vw",
        alignItems: 'center',
        backgroundColor:"#2C2E2F"
    },

    whyIshopText:{
        width: "14.719vw",
        // width:"10.54vw",
        height: "3.777vh",
        left: "58.281vw",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "1.875vw",
        lineHeight: "3.777vh",
        color: "#2C2E2F"
    },

    moreText:{
        width: "53.125vw",
        // width:"48.31vw",
        height: "6.718vh",
        marginRight:"auto",
        marginLeft:"auto",
        textAlign:"center",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "2.5vw",
        lineHeight: "6.718vh",
        textAlign: "center",
        color: "#2C2E2F",
        marginTop:"5vh"

    },

    allCardContainer:{
        marginTop:"-20vh",
    },

    boldText:{
        width:"100%",
        height: "31px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "26px",
        lineHeight: "31px",
        /* identical to box height */
        color: "#2C2E2F",
        textAlign:"left"
    },

    lightText:{
        // width: "584px",
        width:"42.75vw",
        height: "23px",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "20px",
        lineHeight: "23px",
        color: "#2C2E2F",
        textAlign:"left"

    },

    cardView:{
        height: "120px",
        // width:"60vw"
        display:"none",
        flexDirection:"row",
        width:"100%",
        margin:"2%",
        boxShadow: "0 0.4px #888"
    },

    iconContainer:{
        // width: "96px",
        // height: "96px",
        width:"7.02vw",
        height:"14.62vh",
        backgroundColor: "#008489",
        borderRadius: "24px",
        alignItems:"center",
    },

    cardIcon:{
        resizeMode:"contain",
        height:50,
        width:50,
        alignItems:"center",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"3vh"
    },

    cardTextContainer:{
        alignItems:"center",
        margin:"4%"
    },

    colorArrowStyle:{
        width: "1.406vw",
        height: "2.956vh",
        resizeMode:"contain"
    },

    moreContainerMobile:{
        width:"100%",
        marginLeft:"auto",
        textAlign:"center",
        alignItems:"center",
        marginBottom:"10px",
        marginTop:"20px"
    },
    topTextContainerMobile:{
        width:"250px",
        marginLeft:"30px",
        marginRight:"auto",
        textAlign:"left",
        alignItems:"center",
        display:"flex",
        flexDirection:"row",
        //backgroundColor:"#CCCCCC"
    },

    whyIshopTextMobile:{
        width: "130px",
        // width:"10.54vw",
        height: "23px",
        left: "100px",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "23px",
        color: "#2C2E2F",
        //backgroundColor:"#CCCCCC"
    },

    moreTextMobile:{
        width: "100%",
        // width:"48.31vw",
        height: "25px",
        marginRight:"auto",
        marginLeft:"auto",
        textAlign:"center",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "25px",
        textAlign: "center",
        color: "#2C2E2F",
        marginTop:"25px",
        //backgroundColor:"#CCCCCC"

    },

    /*
    cardViewMobile:{
        height: "80px",
        // width:"60vw"
        display:"flex",
        flexDirection:"column",
        width:"100%",
        margin:"2%",
        //boxShadow: "0 0.4px #888",
        marginBottom:"30px"
    },
    */

    cardViewMobile:{
        height: "100px",
        // width:"60vw"
        display:"flex",
        flexDirection:"row",
        width:"100%",
        margin:"2%",
        //boxShadow: "0 0.4px #888"
    },

    iconContainerMobile:{
        width: "60px",
        height: "60px",
        //width:"14.62vw",
        //height:"10.62vh",
        backgroundColor: "#008489",
        borderRadius: "50px",
        alignItems:"center",
        marginRight:"auto",
        marginLeft:"auto",
        textAlign:"center",
        marginTop:"25px"
    },

    cardIconMobile:{
        resizeMode:"contain",
        height:30,
        width:30,
        alignItems:"center",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"3vh"
    },

    /*
    cardTextContainerMobile:{
        alignItems:"center",
        margin:"4%",
        marginRight:"auto",
        marginLeft:"auto",
        textAlign:"center",
    },
    */

    cardTextContainerMobile:{
        alignItems:"center",
        margin:"4%",
        width:"300px",
        height:"60px"
    },

    boldTextMobile:{
        width:"100%",
        height: "31px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "15px",
        lineHeight: "31px",
        /* identical to box height */
        color: "#2C2E2F",
        textAlign:"left"
    },
    
    lightTextMobile:{
        // width: "584px",
        width:"300px",
        height: "23px",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "19px",
        lineHeight: "23px",
        color: "#2C2E2F",
        textAlign:"left"

    },
    
    allCardContainerMobile:{
        marginTop:"10px",
        // height:"300px"
        width:"100%"
    },

    colorArrowStyleMobile:{
        width: "18px",
        height: "18px",
        resizeMode:"contain",
        transform:"rotate(90deg)",
    },

})
import * as React from 'react'
import { useState,useRef } from 'react'
import { useMediaQuery } from 'react-responsive'

import {
    View,
    Text,
    Image,
    ScrollView,
    TouchableOpacity,
    Dimensions,
    Button,
    Linking
} from 'react-native'
import {segmentStyle} from './segmentStyle'

import {
    useFonts,
    Raleway_100Thin,
    Raleway_200ExtraLight,
    Raleway_300Light,
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_600SemiBold,
    Raleway_700Bold,
    Raleway_800ExtraBold,
    Raleway_900Black,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light_Italic,
    Raleway_400Regular_Italic,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black_Italic,
} from '@expo-google-fonts/raleway';

let backwardArrow = require("../../../assets/common_assets/back_arrow.png")
let forwardArrow = require("../../../assets/common_assets/forward_arrow.png")

const customerAppPlayStore = "https://play.google.com/store/apps/details?id=com.ishopatoz.android.customer.release"


const cardImages = [
                    {
                        id:1,
                        img:require("../../../assets/buyers/grocery.png"),
                        title:"Grocery",
                        text:"Fruits & Vegetables, Dairy, Breads,\nBeverages, Chocolates, etc.",
                        textMobile:"Fruits & Vegetables, Dairy, Breads, Beverages, Chocolates, etc."

                    },
                    {
                        id:2,
                        img:require("../../../assets/buyers/food.png"),
                        title:"Food & Restaurant",
                        text:"Breakfast Food, Burgers, Meat &\nSeafood, etc.",
                        textMobile:"Breakfast Food, Burgers, Meat & Seafood, etc."
                    },
                    {
                        id:3,
                        img:require("../../../assets/buyers/beauty.png"),
                        title:"Beauty & Salons",
                        text:"Hair-cutting, Colouring,  Styling, Facials,\nMassages, etc.",
                        textMobile:"Hair-cutting, Colouring,  Styling, Facials, Massages, etc."
                       
                    },
                    {
                        id:4,
                        img:require("../../../assets/buyers/health.png"),
                        title:"Health",
                        text:"Medicine, Dentistry, Eye Care, Diagnostic \n& Test Labs, etc.",
                        textMobile:"Medicine, Dentistry, Eye Care, Diagnostic & Test Labs, etc."
                    },
                    {
                        id:5,
                        img:require("../../../assets/buyers/fitness.png"),
                        title:"Fitness & Gym",
                        text:"Physical Fitness, Exercise Equipment,\nRacquet Sports, etc.",
                        textMobile:"Physical Fitness, Exercise Equipment, Racquet Sports, etc."
                    },
                    {
                        id:6,
                        img:require("../../../assets/buyers/electronics.png"),
                        title:"Electronics",
                        text:"Mobiles & Computers, Cameras, TV,\nWashing machine, etc.",
                        textMobile:"Mobiles & Computers, Cameras, TV, Washing machine, etc."
                    },
                    {
                        id:7,
                        img:require("../../../assets/buyers/kitchen.png"),
                        title:"Home & Kitchen Appliances",
                        text:"Microwave, Pressure cooker, Mixer\nGrinder, etc.",
                        textMobile:"Microwave, Pressure cooker, Mixer Grinder, etc."
                    }
]

const Categories = () => {
    
    let [fontsLoaded] = useFonts({
        Raleway_100Thin,
        Raleway_200ExtraLight,
        Raleway_300Light,
        Raleway_400Regular,
        Raleway_500Medium,
        Raleway_600SemiBold,
        Raleway_700Bold,
        Raleway_800ExtraBold,
        Raleway_900Black,
        Raleway_100Thin_Italic,
        Raleway_200ExtraLight_Italic,
        Raleway_300Light_Italic,
        Raleway_400Regular_Italic,
        Raleway_500Medium_Italic,
        Raleway_600SemiBold_Italic,
        Raleway_700Bold_Italic,
        Raleway_800ExtraBold_Italic,
        Raleway_900Black_Italic,
    });

    const scrollViewRef = useRef(null);
    const width = Dimensions.get("window")
    
    const [cardState,setCardState] = useState({currentCard:0})

    const {currentCard:pageIndex} = cardState

    const toNextView = () =>{
            scrollViewRef.current.scrollTo({
                // screenX:width*(pageIndex-4)*-1,
                x:1600,
                animated:true,
            })
    }

    const toBackView = () => {
        if(scrollViewRef.current !== null){
            scrollViewRef.current.scrollTo({
                x:width*(pageIndex+1),
                animated:true,
            })
        }
    }


    const isMobileDisplay = useMediaQuery({
        maxDeviceWidth:450
    })

    const [currentArrow,setArrow]  = useState({
        left:require("../../../assets/common_assets/backward.png"),
        right:require("../../../assets/common_assets/forward.png")})

    if(isMobileDisplay){
        return(
            <View style={segmentStyle.mobileContainer}>
                <Text style={segmentStyle.segmentTextMobile}>The Categories</Text>
                <Text style={segmentStyle.centerTextMobile}>Most trusted online shopping platform.{"\n"}Built for 1.2+ billion Indians.</Text>


                <View style={segmentStyle.cardContainerMobile}>
                    <ScrollView
                            horizontal={true}
                            showsHorizontalScrollIndicator={false}
                            ref={scrollViewRef}
                    >
                        {cardImages.map(card=>{
                            return<View style={segmentStyle.cardView}> 
                            
                            <TouchableOpacity 
                                style={segmentStyle.cardImageMobile} 
                                onPress={() =>{Linking.openURL(customerAppPlayStore)}}
                                >
                                <Image
                                source={card.img}
                                style={segmentStyle.cardImageMobile}
                                />
                            </TouchableOpacity>

                            {/*  
                            <Image
                            source={card.img}
                            style={segmentStyle.cardImageMobile}
                            />
                            */}
                                <Text style={segmentStyle.cardTitleMobile}>{card.title}</Text>
                                <Text nativeID='serviceableCities'style={segmentStyle.cardTextMobile}>{card.textMobile}</Text>
                            </View>
                            
                        })}
                         <TouchableOpacity style={segmentStyle.knowMoreMobile} onPress={()=>Linking.openURL(customerAppPlayStore)}>
                            <Image style={[segmentStyle.cardImageMobile,segmentStyle.cardImageMore]} source={require("../../../assets/common_assets/dottedCard.png")}/>
                         </TouchableOpacity>

                    </ScrollView>
                </View>

            </View>
        )
    }

    return (
        <View style={segmentStyle.segmentContainer}>
           <View style={segmentStyle.topContainer}>
                <Text style={segmentStyle.theSegmentText}>The Categories</Text>
                <View>
                    <Text style={segmentStyle.centerText}>Most trusted online shopping platform.{"\n"}Built for 1.2+ billion Indians.</Text>
                </View>
                <View style={segmentStyle.navigatorContainer}>
                    <TouchableOpacity
                        onPress={()=>toBackView()}
                        style={segmentStyle.opacityElement}
                    >
                        <Image 
                            source={currentArrow.left}
                            style={segmentStyle.greyArrowImage}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={()=>toNextView()}
                        style={segmentStyle.opacityElement}
                    >
                        <Image
                            source={currentArrow.right}
                            onPress={() => source={backwardArrow}}
                            style={segmentStyle.redArrowImage}
                            // onProgress={()=>{this.scrollview.scrollToEnd({x:screenWidth})}}
                        />
                     </TouchableOpacity>
                </View>
           </View>
           <View style={segmentStyle.cardContainer}>
               <ScrollView
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    ref={scrollViewRef}
               >
                {cardImages.map(card=>{
                    return<View style={segmentStyle.cardView}>
                    <TouchableOpacity onPress={()=>Linking.openURL(customerAppPlayStore)}>
                        <Image
                        source={card.img}
                        style={segmentStyle.cardImage}
                        />
                    </TouchableOpacity>
                        <Text style={segmentStyle.cardTitle}>{card.title}</Text>
                        <Text style={segmentStyle.cardText}>{card.text}</Text>
                    </View>
                     
                })}
                    <TouchableOpacity style={segmentStyle.knowMore} onPress={()=>Linking.openURL(customerAppPlayStore)}>
                        <Image style={segmentStyle.cardImage} source={require("../../../assets/common_assets/dottedCard.png")}/>
                    </TouchableOpacity>
                </ScrollView>
           </View>
           <View nativeID='testimonials'/>
        </View>
    )
}

export default Categories
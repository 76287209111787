export const  sellerQuestion = [
  {
    id:1,
    question:"Does iShopAtoZ charge any commission for the transactions done over the platform?",
    answer:"No. iShopAtoZ doesn’t charge any commission/fee on any transactions made through the iShopAtoZ platform."
  },
  {
    id:2,
    question:"Who decides the price of a product listed on the iShopAtoZ platform?",
    answer:"The price of any product listed on the iShopAtoZ platform is solely decided by the seller. The price of any product mentioned at the time of the order shall be the price charged at the time of delivery.All the products listed on the iShopAtoZ platform will be sold at Indian Rupees either Maximum Retail Price (MRP) (inclusive of all taxes) or a discounted price unless otherwise specified. The prices of the products may be modified from time to time by the seller."
  },
  {
    id:3,
    question:"Who is eligible to sell on iShopAtoZ?",
    answer:"The platform is built for all types of MSMEs or individuals. So any type of seller or service provider is eligible to register his/her business on the iShopAtoZ platform. It can be small individual sellers, retailers, wholesalers, distributors, brands, companies and so on."

  },
  {
    id:4,
    question:"What payment methods do you accept?",
    answer:`Users can make the payment using different payment methods listed below. Users can add money to the iPay wallet for easy checkout and  to avoid any transaction fee.
    · Cash on Delivery (COD)
    · Credit Card
    · Debit Card
    · Net banking
    · UPI
    - iPay (iShopAtoZPay) wallet`
  },
  {
    id:5,
    question:"Where does iShopAtoZ transfer the payment settlement amount?",
    answer:`The payment settlement amount will be transferred to the secured iShopAtoZ wallet. From the wallet the seller may transfer the entire wallet amount to the bank account number registered in the iShopAtoZ platform.`
  },
  {
    id:6,
    question:"Can I register a bank account with iShopAtoZ and transfer the wallet amount to that?",
    answer:`Yes. You can register a bank account in the iShopAtoZ platform. The app also has a special optional safety feature to lock the bank account details after registration. Once the account details are locked no one can update it unless the seller raises a request and iShopAtoZ unlocks this after verification. This ensures the safety of your money in the wallet, even when the mobile is stolen or lost.`
  },
  {
    id:7,
    question:"Are there any products that are banned on this platform?",
    answer:`Seller has to ensure that the products listed on the iShopAtoZ platform are in compliance with all the applicable laws and regulations. Moreover, all the products added by the seller are verified by iShopAtoZ before they go live on the platform.`

  },
  {
    id:8,
    question:"Whom should I contact if there are customer grievances, complaints, or concerns?",
    answer:"You can reach out to us at seller@ishopatoz.com"
  }
]

export const deliveryQuestion = [
  {
    id:1,
    question:"Who can become a delivery partner at iShopAtoZ?",
    answer:"Any person above 18 years of age is eligible to register on the iShopAtoZ platform to begin his/her journey as our delivery partner."
  },
  {
    id:2,
    question:"For which locations are delivery partners required?",
    answer:`We are expanding at an aggressive pace pan-India and you can complete your registration on our app. Once we launch our service in your preferred locality, our team will connect with you for further process.You can download the iShopAtoZ delivery app and complete your registration.`
  },
  {
    id:3,
    question:"How can I earn more money apart from making deliveries?",
    answer:"As a delivery partner, you can use the unique reference code & refer the stores in your locality to onboard their digital shops on the iShopAtoZ platform. Every time a seller onboards their digital shop on iShopAtoZ using your reference code, referral amount will be credited into your iShopAtoZ wallet."
  },
  {
    id:4,
    question:"Can I opt my preferred time for delivering the orders in the iShopAtoZ platform?",
    answer:`Yes. You can indicate your availability for handling delivery requests on the iShopAtoZ platform. The platform sends the delivery request to you only if you have marked you as available (or “online”) in the platform.`
  },
  {
    id:5,
    question:"What are the benefits of joining iShopAtoZ as a delivery partner?",
    answer:`As a delivery partner of iShopAtoZ, you are free to pick any location of your choice to work.The delivery partner can decide when they want to work. We provide flexible working hours to pick up and drop the orders.You can make easy money partnering with us.Your earnings depend on the number of orders delivered. The more orders you deliver, the more money you earn.`
  },
  {
    id:6,
    question:"How much security deposit  is required and are there any unexpected costs I should account for?",
    answer:"iShopAtoZ does not charge you any additional fee or asks for deposits to become a delivery partner. There are no hidden costs."
  },
  {
    id:7,
    question:"How will I get paid?",
    answer:"Your earnings will be credited in your iShopAtoZ wallet. From the wallet, you may transfer the money directly to your bank account  registered on the iShopAtoZ app."

  },
  {
    id:7,
    question:"If I face any issue, whom should I contact?",
    answer:"You can contact us from the ‘help section’ within the app or send a mail to delivery@ishopatoz.com"
  }
]

export const buyerQuestion = [
  {
    id:1,
    question:"Is there a minimum order amount?",
    answer:"There is no minimum order value for placing an order on our platform."
  },
  {
    id:2,
    question:"Whom should I contact if there are any customer grievances, complaints or concerns?",
    answer:"You may reach out to our Customer Care team at contact@ishopatoz.com."
  },
  {
    id:3,
    question:"What payment methods do you accept? What currency can I use to pay?",
    answer:"All payments made against the purchases/services on the iShopAtoZ Platform have to be in INR. Users can make the payment using different payment methods listed below. Users can add money to the iShopAtoZ - iPay wallet for easy checkout and  to avoid any transaction fee during the payment. iPay (iShopAtoZPay) wallet, Cash on Delivery (CoD), Credit Card, Debit Card, Net banking, UPI etc."
  },
  {
    id:5,
    question:"What happens when the retailer cancels the order?",
    answer:"In case the order or any items are canceled, the customer will not be charged any cancellation fee and if any payment is already made, it will be refunded to the customer’s wallet after verifying it internally with the retailer."
  },
  {
    id:6,
    question:"How to raise a query for refunds?",
    answer:"In case of any refund related query drop a mail to contact@ishopatoz.com"
  },
  {
    id:4,
    question:"What is iPay?",
    answer:"iPay is iShopAtoZ wallet where users can add money and buy products on the iShopAtoZ platform. It is a very secure wallet that can be used for easy checkout and  to avoid any additional transaction fee during the payment."
  },
]
import {StyleSheet} from 'react-native'
import {Raleway, Raleway_400Regular, Raleway_500Medium, Raleway_600SemiBold, Raleway_800ExtraBold} from '@expo-google-fonts/raleway'

export const footerStyle = StyleSheet.create({
    footerContainer:{
        display:"flex",
        flexDirection:"row",
        marginLeft:"auto",
        textAlign:"center",
        float:"left",
        padding: "4%",
        marginRight:"30px"

    },

    allFooterContainer:{
        marginTop:"0.821vh",
        width:""
    },

    lowerFooterContainer:{
        marginTop:"0.821vh",
        display:"flex",
        flexDirection:"row",
        marginLeft:"7.8125vw",
        marginBottom:"4.926vh",
    },

    boldText:{
        width: "100%",
        height: "4.598vh",
        top: "0px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "1.25vw",
        lineHeight: "4.598vh",
        color: "#2C2E2F",
        flex: "none",
        order: 0,
        flexGrow: 0,
        margin: "1.25vw 0vw",
        textAlign:"left",
    },

    lightTextContainer:{
        display:"block",
        textAlign:"left"
    },

    lightText:{
        position: "static",
        width: "16.406vw",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "1.094vw",
        lineHeight: "3.120vh",

        color: "#2C2E2F",
        /* Inside Auto Layout */
        flex: "none",
        order: "1",
        flexGrow: "0",
        textAlign:"left",
    },

    lightTextCity01:{
        width: "16.406vw",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "1.094vw",
        lineHeight: "3.120vh",

        color: "#2C2E2F",
        /* Inside Auto Layout */
        flex: "none",
        textAlign:"left",
        marginTop:"2.299vh",
        marginBottom:"2.299vh"
    },

    lightTextCity02:{
        width: "16.406vw",
        // height: "57px",
        // left: "28px",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "0.938vw",
        lineHeight: "3.120vh",

        color: "#2C2E2F",
        /* Inside Auto Layout */
        flex: "none",
        // margin: "0px 12px",
        textAlign:"left",
        marginTop: "0.821vh",
        marginBottom:"0.821vh"
    },

    lightTextUnderline:{
        position: "static",
        width: "16.406vw",
        height: "3.120vh",
        // left: "28px",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "1.094vw",
        lineHeight: "3.120vh",

        color: "#2C2E2F",
        /* Inside Auto Layout */
        flex: "none",
        order: "1",
        flexGrow: "0",
        // margin: "0px 12px",
        textAlign:"left",
        textDecorationLine: 'underline',
    },

    rootContactContainer:{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "0px",
        width: "19vw",
        height: " 37.274vh",
        // left: "-204px",

        marginLeft:"auto",
        //marginRight:"10vw",
        textAlign:"left",
        //backgroundColor:"#CCCCCC"
    },

    contactContainer:{
        display:"flex",
        width:"20vw",
        flexDirection:"row",
        marginTop:"2vh"
    },

    contactContainerMobile:{
        display:"flex",
        width:"20vw",
        flexDirection:"row",
        marginTop:"2vh",
        marginLeft:"30px"
    },

    socialLogoContainer:{
        display:"flex",
        flexDirection:"row",
        textAlign:"center",
        margin:"4%",
        width:"30vw",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        marginTop:"4vh"
    },

    contactUsLogo:{
        width:20,
        height:20,
        resizeMode:"contain",
        alignItems:"center",
        marginRight:"8%",
        textAlign:"center",
        justifyContent:"space-between"

    },

    legalTextContainer:{
        display:"flex",
        width:"100%",
        flexDirection:"row",
        justifyContent:"space-between"
    },

    opacityContainer:{
        //marginTop:"2.627vh"
    },

    legalText:{
        width: "9.375vw",
        height: "2.299vh",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "0.9375vw",
        lineHeight: "2.299vh",
        /* identical to box height */
        color: "#2C2E2F",
        marginLeft:"2%",
        textAlign:"left",
        textDecorationLine: 'underline',
        // marginTop:"15%",
        //backgroundColor:"#CCCCCC"
    },

    verticalContainer01:{
        width:"18.5vw",
        //display:"flex",
        //flexWrap:"wrap",
        textAlign:"left",
        //backgroundColor:"#AAAAAA"
    },

    verticalContainer02_01:{
        width:"12vw",
        display:"flex",
        flexWrap:"wrap",
        textAlign:"left",
        //backgroundColor:"#AAAAAA"
    },

    verticalContainer02_02:{
        width:"18.5vw",
        display:"flex",
        flexWrap:"wrap",
        textAlign:"left",
        //backgroundColor:"#AAAAAA"
    },

    verticalItem:{
        display:"flex",
        width:"30vw",
        flexDirection:"row",
        flexWrap:"wrap",
        justifyContent:"space-between",
    },

    verticalsText:{
        position: "static",
        width: "15.625vw",
        height: "3.120vh",
        left: "0px",
        top: "18.719vh",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "1.094vw",
        lineHeight: "3.120vh",
        /* identical to box height */
        color: "#2C2E2F",
        /* Inside Auto Layout */
        flex: "none",
        order: "2",
        flexGrow: 0,
        margin: "1.25vw 0vh",
        // marginLeft: "12%",
        marginTop:"2vh",
        textAlign:"left"
    },

    // verticalsText:{
        

    // },

    ishopText:{
        // position: "absolute",
        width: "16.172vw",
        height: "6.240vh",
        left: "0vw",
        // top: "44px",
        marginTop:"2vh",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "1.094vw",
        lineHeight: "3.120vh",
        color: "#2C2E2F",
        textAlign:"left",

    },

    stayUpdated:{
        textAlign:"left",
        width: "12.266vw",
        height: "4.598vh",
        left: "0vw",
        marginTop: "6vh",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "1.875vw",
        lineHeight: "4.598vh",
        color: "#2C2E2F",
        /* Inside Auto Layout */
        flex: "none",
        order: "0",
        flexGrow: "0",
        margin: "1.25vw 0vw",
        display:"none"
    },

    inputContainer:{
        display:"flex",
        flexDirection:"row",
        width:"100%",
        display:"none"
    },

    userInput:{
        // position: absolute;
        width: "18.906vw",
        height: "7.389vh",
        left: "0vw",
        // top: "44px",
        background: "#2C2E2F",
        marginTop:"2vh",
        opacity:"0.25",
        // opacity: "0.05"
    },
    sendIconContainer:{
        position: "absolute",
        width: "3.594vw",
        height: "7.389vh",
        left: "19.219vw",
        // top: "44px",
        marginTop:"2vh",
        backgroundColor: "#FF002A",
    },

    sendIcon:{
        // position: "absolute",
        // left: "89.05%",
        // right: "4.8%",
        // top: "64.04%",
        // bottom: "15.73%",
        width:"1.5625vw",
        height:"3.284vh",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center",
        alignItems:"center",
        // border: "2px solid #FFFFFF",
        boxSizing: "border-box",
        resizeMode:"contain",
        marginTop:"2.299vh"
    },

    /*
    cyear:{
        // width: "130px",
        height: " 2.627vh",
        float:"right",
        fontFamily: Raleway,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "0.9375vw",
        lineHeight: "2.627vh",
        color: "#2C2E2F",
        // textAlign:"right",
        marginTop:" 21.346vh",
        // marginLeft:"13vw",
        float:"right",
        textAlign:"left",

    },
    */

    cyear:{
        width: "60.9375vw",
        height: "2.299vh",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "0.9375vw",
        lineHeight: "2.299vh",
        /* identical to box height */
        color: "#2C2E2F",
        marginLeft:"2%",
        textAlign:"right",
        // textDecorationLine: 'underline',
        // marginTop:"15%",
        //backgroundColor:"#CCCCCC"
    },


    downloadText:{
        // width: 176px;
        // height: 29px;
        height:"4.41vh",
        width:"13.88vw",
        // left: 0px;
        // marginTop: "159px"
        marginTop:"10vh",
        /* Medium-Styles/Medium-Semi Bold */
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "1.25vw",
        // fontSize:"16px",
        lineHeight: "100%",
        /* identical to box height, or 29px */
        /* Cyan-Secondary */
        color: "#008489",
        whiteSpace: "nowrap"

    },
   
    googlePlayImg:{
        width:"12.29vw",
        height:"6.40vh",
        borderRadius:"24px",
        resizeMode:"contain",
        marginTop:"2vh"
    },

   



    ///Mobile responsive view

    footerContainerMobile:{
        width:"100%",
        //marginTop:"3vh",
        marginTop:"10px",
        marginBottom:"2vh"
    },

    updatedText:{
        position: "static",
        width: "155px",
        height: "43px",
        left: "0px",
        top: "0px",
        marginLeft:"8vw",
        // marginLeft:"30px",
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "180%",
        color: "#2C2E2F",
        flex: "none",
        order: 0,
        flexGrow: 0,
        margin: "8px 0px",
        display:"none",
    },

    userInputMobile:{
        /* White */

        height: "45px",
        width: "242px",
        marginLeft: "30px",
        top: "0px",
        borderRadius: "8px",
        fontFamily: 'Raleway_400Regular',
        textAlign:"center",
        border:"1px solid #000000",
        boxSizing:"border-box",
        opacity:"0.25"

    },
    
    inputContainerMobile:{
        display:"none",
        flexDirection:"row",
        width:"100%",
        marginTop:"7%"
    },
    sendIconContainerMobile:{
        position: "absolute",
        width: "46px",
        height: "44px",
        left: "278px",
        // top: "44px",
        // marginTop:"2vh",
        backgroundColor: "#000000",
        borderRadius:"8px",

    },

    sendIconMobile:{
        resizeMode:"contain",
        width:"18px",
        height:"18px",
        top:"13px",
        alignItems:"center",
        marginLeft:"auto",
        marginRight:"auto",
        textAlign:"center"
    },
    titleContainer:{
        display:"flex",
        flexDirection:"row",
        width:"80%",
        alignItems:"center",
        marginLeft:"30px",
        justifyContent:"flex-start",
        textAlign:"center",
        //height:"6vh"
        marginBottom:"15px"
    },
    stateIcon:{
        width:"24px",
        height:"24px",
        marginLeft:"6px",
        float:"right",
        resizeMode:"contain",
    },

    titleStyle:{
        left: "0%",
        // right: "76.6%",
        top: "26.04%",
        bottom: "69.43%",
        /* Small-Styles/Small-Medium */
        fontFamily: 'Raleway_500Medium',
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "180%",
        /* or 25px */
        textAlign: "left",
        color: "#000000",
        marginLeft:"50px",

    },

    lineShadow:{
        left: "0%",
        right: "0%",
        top: "56.79%",
        bottom: "43.21%",
        opacity: "0.1",
        /* Black */
        border: "1px solid #000000"
    },

    listContainer:{
        width:"100%",
        marginTop:"30px"
    },

    ishopTextMobile:{
        marginLeft:"30px",
        right: "74.68%",
        // top: "91.2%",
        marginTop:"2vh",
        bottom: "3.55%",
        /* Medium-Styles/Medium-Semi Bold */
        fontFamily: 'Raleway_600SemiBold',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "180%",
        /* identical to box height, or 29px */
        /* Charcoal-Text */
        color: "#2C2E2F",
    },
    endToEndText:{
        marginLeft:"30px",
        right: "0%",
        marginTop: "2vh",
        bottom: "-0.98%",

        /* Small */
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "180%",
        /* or 25px */
        /* Charcoal-Text */
        color: "#2C2E2F"

    },

    copyrightText:{
        marginLeft: "30px",
        right: "24.68%",
        // top: "103.88%",
        marginTop:"2vh",
        bottom: "-7.86%",
        /* XS */
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "180%",
        /* identical to box height, or 22px */
        /* Charcoal-Text */
        color: "#2C2E2F"

    },

    expandView:{
        width:"100%",
        maxHeight:"100%",
        marginLeft:"50px"
    },

    collapseView:{
        display:"none"
    },

    socialLogoContainerMobile:{
        display:"flex",
        flexDirection:"row",
        textAlign:"center",
        marginLeft:"30px",
        width:"40vw",
        justifyContent:"space-between",
        textAlign:"center",
        alignItems:"center",
        // marginTop:"4vh"
        height:"10vh",
        marginLeft:"60px"
        // marginLeft:"80px"
    },

    listText:{
        marginLeft:"60px",
        right: "76.6%",
        top: "26.04%",
        bottom: "69.43%",
        /* Small-Styles/Small-Medium */
        fontFamily: 'Raleway_500Medium',
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "180%",
        /* or 25px */
        textAlign: "left",
        color: "#000000"
    },

    addressContainer:{
        marginLeft:"30px",
        marginBottom:"20px"
    },

    lightTextMobile:{
        position: "static",
        width: "210px",
        // height: "57px",
        // left: "28px",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "16px",
        color:"#FF002A",
        // color: "#2C2E2F",
        /* Inside Auto Layout */
        flex: "none",
        order: "1",
        flexGrow: "0",
        // margin: "0px 12px",
        textAlign:"left",
    },
    lightAddressTextMobile:{
        position: "static",
        width: "210px",
        // height: "57px",
        // left: "28px",
        fontFamily: 'Raleway_400Regular',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "16px",
        // color:"#FF002A",
        color: "#2C2E2F",
        /* Inside Auto Layout */
        flex: "none",
        order: "1",
        flexGrow: "0",
        // margin: "0px 12px",
        textAlign:"left",
    },


    linkText:{
        marginLeft:"60px",
        right: "76.6%",
        top: "26.04%",
        bottom: "69.43%",
        /* Small-Styles/Small-Medium */
        fontFamily: 'Raleway_500Medium',
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "180%",
        /* or 25px */
        textAlign: "left",
        color: "#000000",
        // textDecorationLine: 'underline',
    },
    contactUsLogoMobile:{
        width:16,
        height:16,
        resizeMode:"contain",
        alignItems:"center",
        marginRight:"8%",
        textAlign:"center",
        justifyContent:"space-between"

    },
    
    blockSpace:{
        borderBottomColor:"#AAAAAA",
        borderBottomWidth: 0.5,
        width:"82.031vw",
        height:"0.164vh",
        marginLeft:"7.031vw",
        alignItems: 'center',
        backgroundColor:"#AAAAAA",
        opacity:"0.6"
    },
    downloadTextMobile:{
        /* Medium-Styles/Medium-Extra Bold */
        fontFamily: 'Raleway_800ExtraBold',
        fontStyle: "normal",
        fontWeight: 800,
        fontSize: "16px",
        lineHeight: "180%",
        /* identical to box height, or 29px */
        /* Cyan-Secondary */
        color: "#008489",
        marginLeft:"30px"

    },

    mobilePlayContainer:{
        width:"168px"
    },
    googlePlayImgMobile:{
        marginLeft:"30px",
        resizeMode:"contain",
        height:"42px",
        width:"168px",
        marginTop:"16px"
    }

   

})